// This manages the choices for the Edit Content page
// Gives the dropdown for risk area, business area, and content type

import {
  Checkbox,
  Collapse,
  FormControl,
  FormGroup,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import React, { useState } from "react";

import DescFields from "../data/descForm";
import WorkFields from "../data/donts";
import QAFields from "../data/qaForm";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 20,
    display: "flex",
    flexWrap: "wrap",
    wordWrap: true
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  menu: {
    width: 400,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  fab: {
    margin: theme.spacing(2),
    color: "#FFCC00",
    background: "black"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const languages = [
  { value: "zh", label: "Chinese (Simplified)" },
  { value: "nl", label: "Dutch" },
  { value: "en", label: "English" },
  { value: "fr", label: "French" },
  { value: "de", label: "German" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "pt", label: "Portuguese" },
  { value: "ru", label: "Russian" },
  { value: "es", label: "Spanish" }
];

const riskAreas = [
  {
    value: "tradeCompliance",
    label: "Trade Compliance"
  },
  {
    value: "antiBribery",
    label: "Anti-bribery"
  },
  {
    value: "dataPrivacy",
    label: "Data Privacy"
  },
  {
    value: "environmentHealthSafety",
    label: "Environment, Healthy, and Safety"
  },
  {
    value: "productReg",
    label: "Product Regulatory"
  },
  {
    value: "govContracts",
    label: "Governmental Contracts"
  },
  {
    value: "conflictsOfInterests",
    label: "Conflicts of Interest"
  },
  {
    value: "generalCompliance",
    label: "General Compliance"
  }
];

const businessActivities = [
  "Dealers and Distribution",
  "Engaging with the media/Social Media",
  "Entertaining Customers",
  "Gifts and Giveaways",
  "Interacting with Competitors",
  "Serving Customers",
  "Shipping Goods",
  "Trade Associations and Events",
  "Travel for Work",
  "Working with Suppliers"
];

const contentTypes = [
  {
    value: "description",
    label: "Description"
  },
  {
    value: "faqs",
    label: "FAQs"
  },
  {
    value: "work",
    label: "Work"
  }
];

function ContentForm() {
  const [toDisplay, setToDisplay] = useState("");
  const classes = useStyles();

  const [values, setValues] = React.useState({
    topic: [],
    riskArea: "",
    businessActivity: "",
    contentType: "",
    lanugage: ""
  });

  const MenuProps = {
    PaperProps: {
      style: {
        width: 400
      }
    }
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handlePublish = event => {
    // alert("WTRW content updated");
  };

  const handleContentChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    setToDisplay(event.target.value);
  };

  return (
    <div>
      <Typography variant="h6" noWrap>
        Upload Content
      </Typography>
      <FormGroup className={classes.container} noValidate autoComplete="off">
        <TextField
          id="select-language"
          select
          label="Language"
          className={classes.textField}
          value={values.languages}
          onChange={handleChange("language")}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="normal"
        >
          {languages.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="standard-select-riskArea"
          select
          label="Risk Area"
          className={classes.textField}
          value={values.riskArea}
          onChange={handleChange("riskArea")}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="normal"
        >
          {riskAreas.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <FormControl className={classes.formControl}>
          <InputLabel>Business Area</InputLabel>
          <Select
            multiple
            value={values.topic}
            onChange={handleChange}
            className={classes.textField}
            input={<Input id="select-multiple" />}
            renderValue={selected => selected.join(", ")}
            MenuProp={MenuProps}
          >
            {businessActivities.map(value => (
              <MenuItem key={value} value={value} style={{ className: classes.menu }}>
                <Checkbox />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          id="standard-select-content"
          select
          label="Content Type"
          className={classes.textField}
          value={values.contentType}
          onChange={handleContentChange("contentType")}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          helperText="Please choose Content Type"
          margin="normal"
        >
          {contentTypes.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FormGroup>
      <Fab variant="publish" aria-label="publish" className={classes.fab} onClick={handlePublish}>
        <AddToHomeScreenIcon className={classes.extendedIcon} />
        Publish
      </Fab>
      <Collapse in={true}>
        <FormGroup>
          {toDisplay === "description" && <DescFields />}
          {toDisplay === "faqs" && <QAFields />}
          {toDisplay === "work" && <WorkFields />}
        </FormGroup>
      </Collapse>
    </div>
  );
}

export default ContentForm;
