export const DIVISION_CODES_ENDPOINT = "division/codes";
export const PLANETS_ENDPOINT = "planets/";
export const SPOTLIGHT_ENDPOINT = "/content/spotlight";
export const WORK_ENDPOINT = "/content/work";
export const RISK_AREA_ENDPOINT = "/content";
export const ASK_ENDPOINT = "/content/questions";
export const USER_ENDPOINT = "/user";
export const TRAINING_ENDPOINT = "/trainingCredit";
export const CTOT_ENDPOINT = "/ctot";
export const TGE_ENDPOINT = "/tge";
export const TPI_ENDPOINT = "/tpi";
export const ASKQUESTION_ENDPOINT = "/question";
export const CONTACTS_ENDPOINT = "/division/contacts";
export const COUNTRY_ENDPOINT = "/selections/ArcherCountries";
export const RISKAREA_ENDPOINT = "/selections/ArcherRiskAreas";
export const DIVISION_ENDPOINT = "/selections/ArcherDivisions";
export const FEEDBACK_ENDPOINT = "/user/feedback";
export const LEARNING_MODULES_ENDPOINT =
  "/content?contentDomain=contentType&contentDomainKey=Modules";
export const RISK_AREAS_ENDPOINT = '/content?contentDomain=contentType&contentDomainKey=Description';
export const DO_DONOT_ENDPOINT = "/content?contentDomain=contentType&contentDomainKey=DoDoNot";
export const COMMON_QA_ENDPOINT = "/content?contentDomain=contentType&contentDomainKey=CommonQA";
export const DECISION_MATRIX_ENDPOINT = "/content?contentDomain=contentType&contentDomainKey=DecisionMatrix";
export const appVersion = '1.2.7';