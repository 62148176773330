class SectionDAO {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this.title = title;
  }

  setIconKey(iconKey) {
    this.iconKey = iconKey;
  }

  getIconKey() {
    return this.iconKey;
  }

  setText(text) {
    this.text = text;
  }

  getText() {
    return this.text;
  }

  setOrder(order) {
    this.order = order;
  }

  getOrder() {
    return this.order;
  }

  toObject(input) {
    if(input){
    this.setTitle(input.title);
    this.setIconKey(input.iconKey);
    this.setText(input.text);
    this.setOrder(input.order);
    }
    return this;
  }
}

export default SectionDAO;
