class UserModuleProgressDAO{
    setModuleId(moduleId){
        this.moduleId = moduleId;
    }

    getModuleId(){
        return this.moduleId;
    }

    setModuleComplete(complete){
        this.moduleComplete = complete;
    }

    getModuleComplete(){
        return this.moduleComplete;
    }

    toObject(input){
        this.setModuleId(input.moduleId);
        this.setModuleComplete(input.moduleComplete);
        return this;
    }
}

export default UserModuleProgressDAO;