// This file manages the FAQs page of the content manager
// Allows the web portal user to change the frequenty asked questions
// based on risk area and business activity

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  IconButton,
  makeStyles,
  Slide,
  Snackbar,
  TextField,
  Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 20,
    width: "80%"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  button: {
    position: "absolute",
    right: theme.spacing(6),
    top: theme.spacing(0)
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(11),
    top: theme.spacing(0)
  },
  close: {
    padding: theme.spacing(0.5)
  }
}));

let qas = [
  {
    id: 1,
    question: "Example Question 1",
    answer: "Example Answer 1"
  },
  {
    id: 2,
    question: "Example Question 2",
    answer: "Example Answer 2"
  }
];

// all about those snacks yo
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function FAQs() {
  const newClasses = useStyles();
  const [count, setCount] = React.useState(3);
  const [currentID, setCurrentID] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDelOpen] = React.useState(false);
  const [delQ, setDelQ] = React.useState("");
  const [editOpen, setEditOpen] = React.useState(false);
  // for snackbar
  const [snackOpen, setSnackOpen] = React.useState(false);

  const [values, setValues] = React.useState({
    newQ: "",
    newA: ""
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setValues({ newQ: "", newA: "" });
    setEditOpen(false);
  };

  const handleDelClose = () => {
    setDelOpen(false);
  };

  const addNew = () => {
    setCount(count + 1);
    qas = [...qas, { id: count, question: values.newQ, answer: values.newA }];
    setOpen(false);
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleOpenEdit = current => event => {
    event.stopPropagation();
    setCurrentID(current.id);
    setValues({ newQ: current.question, newA: current.answer });
    setEditOpen(true);
  };

  const handleEditChange = event => {
    const index = qas.findIndex(i => i.id === currentID);
    qas[index].question = values.newQ;
    qas[index].answer = values.newA;
    setEditOpen(false);
  };

  const handleDelete = () => {
    qas = qas.filter(current => current.question !== delQ);
    setDelOpen(false);
    setSnackOpen(true);
  };

  const handleOpenDelete = currentQ => event => {
    event.stopPropagation();
    setDelQ(currentQ);
    setDelOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  return (
    <div className={newClasses.root}>
      <Typography variant="h6" noWrap>
        Frequently Asked Questions
      </Typography>
      <br />
      {qas.map(option => (
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Panel1a-content"
            id="panel1a-header"
          >
            <Typography className={newClasses.heading}>{option.question}</Typography>
            <IconButton
              className={newClasses.editButton}
              aria-label="editThis"
              onClick={handleOpenEdit(option)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              className={newClasses.button}
              aria-label="delete"
              onClick={handleOpenDelete(option.question)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>{option.answer}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}

      <Fab color="primary" aria-label="add" className={newClasses.fab} onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter Question and Answer</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the question and answer below.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="question"
            label="Question"
            fullWidth
            onChange={handleChange("newQ")}
            required
          />
          <TextField
            margin="dense"
            id="answer"
            label="Answer"
            fullWidth
            onChange={handleChange("newA")}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addNew}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="edit-dialog-title">Edit FAQ Question and Answer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="editQ"
            label="Question"
            fullWidth
            value={values.newQ}
            onChange={handleChange("newQ")}
            required
          />
          <TextField
            margin="dense"
            id="editA"
            label="Answer"
            fullWidth
            value={values.newA}
            onChange={handleChange("newA")}
            required
          />
          <DialogActions>
            <Button onClick={handleEditClose}>Cancel</Button>
            <Button onClick={handleEditChange}>Submit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog open={deleteOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="del-dialog-title">Delete this Question and Answer?</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={handleDelClose}>Cancel</Button>
            <Button onClick={handleDelete}>Submit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={snackOpen}
        autoHideDuration={3500}
        onClose={handleSnackClose}
        TransitionComponent={SlideTransition}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Item Deleted</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="primary"
            className={newClasses.close}
            onClick={handleSnackClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}

export default FAQs;
