import React from "react";

// Business Area Icons
import { ReactComponent as Competitors } from "../assets/businessAreaIcons/Competitors.svg";
import { ReactComponent as Customers } from "../assets/businessAreaIcons/Customers.svg";
import { ReactComponent as Dealers } from "../assets/businessAreaIcons/Dealers.svg";
import { ReactComponent as EmploymentDecisions } from "../assets/businessAreaIcons/EmploymentDecisions.svg";
import { ReactComponent as Gifts } from "../assets/businessAreaIcons/Gifts.svg";
import { ReactComponent as ServingCustomers } from "../assets/businessAreaIcons/ServingCustomers.svg";
import { ReactComponent as Shipping } from "../assets/businessAreaIcons/Shipping.svg";
import { ReactComponent as SocialMedia } from "../assets/businessAreaIcons/SocialMedia.svg";
import { ReactComponent as Suppliers } from "../assets/businessAreaIcons/Suppliers.svg";
import { ReactComponent as Trade } from "../assets/businessAreaIcons/Trade.svg";
import { ReactComponent as Travel } from "../assets/businessAreaIcons/Travel.svg";
import { ReactComponent as AccessAndIndividualsRights } from "../assets/icons/AccessAndIndividualsRights.svg";
import { ReactComponent as AccidentalBreach } from "../assets/icons/AccidentalBreach.svg";
import { ReactComponent as AntiBoycottCompliance } from "../assets/icons/AntiBoycottCompliance.svg";
import { ReactComponent as AntiBribery } from "../assets/icons/AntiBribery.svg";
import { ReactComponent as AntiBriberyIcon } from "../assets/icons/AntiBriberyIcon.svg";
import { ReactComponent as BestPractices } from "../assets/icons/BestPractice.svg";
import { ReactComponent as BreachResponse } from "../assets/icons/BreachResponse.svg";
import { ReactComponent as BriberyBusinessCourtesies } from "../assets/icons/BriberyBusinessCourtesies.svg";
import { ReactComponent as CompetitiveInformation } from "../assets/icons/CompetitiveInformation.svg";
import { ReactComponent as CompetitorsRaisePrices } from "../assets/icons/CompetitorsRaisePrices.svg";
import { ReactComponent as CompetitorSupplier } from "../assets/icons/CompetitorSupplier.svg";
import { ReactComponent as ConfirmationPersonalInformation } from "../assets/icons/ConfirmationPersonalInformation.svg";
import { ReactComponent as ConfirmOfferLegal } from "../assets/icons/ConfirmOfferLegal.svg";
import { ReactComponent as ConflictofInterest } from "../assets/icons/ConflictofInterest.svg";
import { ReactComponent as ConflictofInterestIcon } from "../assets/icons/ConflictofInterestIcon.svg";
import { ReactComponent as ConsiderWebsiteInformationPrivacy } from "../assets/icons/ConsiderWebsiteInformationPrivacy.svg";
import { ReactComponent as ConsultManagerBeforeSale } from "../assets/icons/ConsultManagerBeforeSale.svg";
import { ReactComponent as ContactDivisionTPI } from "../assets/icons/ContactDivisionTPI.svg";
import { ReactComponent as ContactDivisionTPIQuestionnaire } from "../assets/icons/ContactDivisionTPIQuestionnaire.svg";
import { ReactComponent as CreateTheLetter } from "../assets/icons/CreateTheLetter.svg";
import { ReactComponent as Customs } from "../assets/icons/Customs.svg";
import { ReactComponent as DataPrivacy } from '../assets/icons/DataPrivacy.svg';
import { ReactComponent as DataPrivacyIcon } from "../assets/icons/DataPrivacyIcon.svg";
import { ReactComponent as DataPrivacyPersonalInformation } from "../assets/icons/DataPrivacyPersonalInformation.svg";
import { ReactComponent as DataQuality } from "../assets/icons/DataQuality.svg";
import { ReactComponent as DataTransferPrivacy } from "../assets/icons/DataTransferPrivacy.svg";
import { ReactComponent as DataTransfewProcessedFairly } from "../assets/icons/DataTransfewProcessedFairly.svg";
import { ReactComponent as DealerPriceProduct } from "../assets/icons/DealerPriceProduct.svg";
import { ReactComponent as DealersConsideredCompetitors } from "../assets/icons/DealersConsideredCompetitors.svg";
import { ReactComponent as DeclareItems } from "../assets/icons/DeclareItems.svg";
import { ReactComponent as Diversion } from "../assets/icons/Diversion.svg";
import { ReactComponent as DocumentBusinessJustification } from "../assets/icons/DocumentBusinessJustification.svg";
import { ReactComponent as DoNotExchangeSensitiveData } from "../assets/icons/DoNotExchangeSensitiveData.svg";
import { ReactComponent as DoNotMakeAgreements } from "../assets/icons/DoNotMakeAgreements.svg";
import { ReactComponent as DueDiligenceScreening } from "../assets/icons/DueDiligenceScreening.svg";
import { ReactComponent as EngineersDiscussCost } from "../assets/icons/EngineersDiscussCost.svg";
import { ReactComponent as ExerciseGoodJudgement } from "../assets/icons/ExerciseGoodJudgement.svg";
import { ReactComponent as ExpenseBooks } from "../assets/icons/ExpenseBooks.svg";
import { ReactComponent as ExportControls } from "../assets/icons/ExportControls.svg";
import { ReactComponent as FairCompetition } from '../assets/icons/FairCompetition.svg';
import { ReactComponent as NewInsiderTrading } from '../assets/businessAreaIcons/InsiderTrading.svg';//bug fix:33805
import { ReactComponent as NewConflictOfInterest } from '../assets/businessAreaIcons/ConflictofInterest.svg';//bug fix:33805
// Icons
import { ReactComponent as FairCompetitionIcon } from "../assets/icons/FairCompetitionIcon.svg";
import { ReactComponent as FollowWrittenAgenda } from "../assets/icons/FollowWrittenAgenda.svg";
import { ReactComponent as GatherInformation } from "../assets/icons/GatherInformation.svg";
import { ReactComponent as GatherInformationv2 } from "../assets/icons/GatherInformationv2.svg";
import { ReactComponent as Generic02 } from "../assets/icons/Generic02.svg";
import { ReactComponent as Generic07 } from "../assets/icons/Generic07.svg";
import { ReactComponent as GetApproval } from "../assets/icons/GetApproval.svg";
import { ReactComponent as GetPreApproval } from "../assets/icons/GetPreApproval.svg";
import { ReactComponent as HandCarry } from "../assets/icons/HandCarry.svg";
import { ReactComponent as IllegalPriceFixing } from "../assets/icons/IllegalPriceFixing.svg";
import { ReactComponent as TrophyIcon } from "../assets/icons/img_trophy.svg";
import { ReactComponent as InformationSecurityReport } from "../assets/icons/InformationSecurityReport.svg";
import { ReactComponent as InsiderTrading } from "../assets/icons/InsiderTrading.svg";
import { ReactComponent as InsiderTradingIcon } from "../assets/icons/InsiderTradingIcon.svg";
import { ReactComponent as LegalServicePreApproval } from "../assets/icons/LegalServicePreApproval.svg";
import { ReactComponent as LegalServicesReview } from "../assets/icons/LegalServicesReview.svg";
import { ReactComponent as Licensing } from "../assets/icons/Licensing.svg";
import { ReactComponent as LimitedPurposeAndUse } from "../assets/icons/LimitedPurposeAndUse.svg";
import { ReactComponent as LocalCharities } from "../assets/icons/LocalCharities.svg";
import { ReactComponent as Mod1Scr1_ConflictofInterestIcon } from "../assets/icons/Mod1Scr1_ConflictofInterestIcon.svg";
import { ReactComponent as Mod1Scr1_Insider } from "../assets/icons/Mod1Scr1_Insider.svg";
import { ReactComponent as Mod1Scr2_Loyalty } from "../assets/icons/Mod1Scr2_Loyalty.svg";
import { ReactComponent as Mod1Scr2_Prohibited } from "../assets/icons/Mod1Scr2_Prohibited.svg";
import { ReactComponent as Mod1Scr3_AvoidConflict } from "../assets/icons/Mod1Scr3_AvoidConflict.svg";
import { ReactComponent as Mod1Scr3_Legal } from "../assets/icons/Mod1Scr3_Legal.svg";
import { ReactComponent as Mod1Scr4_Policy } from "../assets/icons/Mod1Scr4_Policy.svg";
import { ReactComponent as Mod1Scr4_SeeSay } from "../assets/icons/Mod1Scr4_SeeSay.svg";
import { ReactComponent as Mod1Scr5_Contact } from "../assets/icons/Mod1Scr5_Contact.svg";
import { ReactComponent as Mod2Scr1_Conflict } from "../assets/icons/Mod2Scr1_Conflict.svg";
import { ReactComponent as Mod2Scr1_MNPI } from "../assets/icons/Mod2Scr1_MNPI.svg";
import { ReactComponent as Mod2Scr2_Circumstance } from "../assets/icons/Mod2Scr2_Circumstance.svg";
import { ReactComponent as Mod2Scr2_Money } from "../assets/icons/Mod2Scr2_Money.svg";
import { ReactComponent as Mod2Scr3_Material } from "../assets/icons/Mod2Scr3_Material.svg";
import { ReactComponent as Mod2Scr3_Purchase } from "../assets/icons/Mod2Scr3_Purchase.svg";
import { ReactComponent as Mod2Scr4_Approvals } from "../assets/icons/Mod2Scr4_Approvals.svg";
import { ReactComponent as Mod2Scr4_Increase } from "../assets/icons/Mod2Scr4_Increase.svg";
import { ReactComponent as Mod2Scr5_Gifts } from "../assets/icons/Mod2Scr5_Gifts.svg";
import { ReactComponent as Mod2Scr5_Public } from "../assets/icons/Mod2Scr5_Public.svg";
import { ReactComponent as Mod2Scr6_TwoJobs } from "../assets/icons/Mod2Scr6_TwoJobs.svg";
import { ReactComponent as Mod2Scr6A_PressRelease } from "../assets/icons/Mod2Scr6A_PressRelease.svg";
import { ReactComponent as Mod2Scr6B_Asses } from "../assets/icons/Mod2Scr6B_Asses.svg";
import { ReactComponent as Mod2Scr6C_Filings } from "../assets/icons/Mod2Scr6C_Filings.svg";
import { ReactComponent as Mod2Scr7_Hire } from "../assets/icons/Mod2Scr7_Hire.svg";
import { ReactComponent as Mod2Scr7_MNPI } from "../assets/icons/Mod2Scr7_MNPI.svg";
import { ReactComponent as Mod2Scr8_Interest } from "../assets/icons/Mod2Scr8_Interest.svg";
import { ReactComponent as Mod2Scr9_Interest2 } from "../assets/icons/Mod2Scr9_Interest2.svg";
import { ReactComponent as Mod2Scr10_Gain } from "../assets/icons/Mod2Scr10_Gain.svg";
import { ReactComponent as Mod2Scr11_Romance } from "../assets/icons/Mod2Scr11_Romance.svg";
import { ReactComponent as Mod2Scr12_SeeSay } from "../assets/icons/Mod2Scr12_SeeSay.svg";
import { ReactComponent as Mod3Scr1_Nepotism } from "../assets/icons/Mod3Scr1_Nepotism.svg";
import { ReactComponent as Mod3Scr1_Policy } from "../assets/icons/Mod3Scr1_Policy.svg";
import { ReactComponent as Mod3Scr2_AllLevels } from "../assets/icons/Mod3Scr2_AllLevels.svg";
import { ReactComponent as Mod3Scr2_People } from "../assets/icons/Mod3Scr2_People.svg";
import { ReactComponent as Mod3Scr3_Family } from "../assets/icons/Mod3Scr3_Family.svg";
import { ReactComponent as Mod3Scr3_Merit } from "../assets/icons/Mod3Scr3_Merit.svg";
import { ReactComponent as Mod3Scr4_External } from "../assets/icons/Mod3Scr4_External.svg";
import { ReactComponent as Mod3Scr4_Leader } from "../assets/icons/Mod3Scr4_Leader.svg";
import { ReactComponent as Mod3Scr5_Request } from "../assets/icons/Mod3Scr5_Request.svg";
import { ReactComponent as Mod3Scr5_Trust } from "../assets/icons/Mod3Scr5_Trust.svg";
import { ReactComponent as Mod3Scr6_Securities } from "../assets/icons/Mod3Scr6_Securities.svg";
import { ReactComponent as Mod3Scr6_SeeSay } from "../assets/icons/Mod3Scr6_SeeSay.svg";
import { ReactComponent as Mod3Scr7_Policy } from "../assets/icons/Mod3Scr7_Policy.svg";
import { ReactComponent as Mod3Scr8_Prohibits } from "../assets/icons/Mod3Scr8_Prohibits.svg";
import { ReactComponent as Mod3Scr9_Policy } from "../assets/icons/Mod3Scr9_Policy.svg";
import { ReactComponent as Mod3Scr10_Contact } from "../assets/icons/Mod3Scr10_Contact.svg";
import { ReactComponent as Mod4Scr1_Insiders } from "../assets/icons/Mod4Scr1_Insiders.svg";
import { ReactComponent as Mod4Scr1_TwoJobs } from "../assets/icons/Mod4Scr1_TwoJobs.svg";
import { ReactComponent as Mod4Scr2_Industry } from "../assets/icons/Mod4Scr2_Industry.svg";
import { ReactComponent as Mod4Scr2_Open } from "../assets/icons/Mod4Scr2_Open.svg";
import { ReactComponent as Mod4Scr3_NonProfit } from "../assets/icons/Mod4Scr3_NonProfit.svg";
import { ReactComponent as Mod4Scr3_NoTrade } from "../assets/icons/Mod4Scr3_NoTrade.svg";
import { ReactComponent as Mod4Scr4_Compete } from "../assets/icons/Mod4Scr4_Compete.svg";
import { ReactComponent as Mod4Scr4_Exceptions } from "../assets/icons/Mod4Scr4_Exceptions.svg";
import { ReactComponent as Mod4Scr5_Contact } from "../assets/icons/Mod4Scr5_Contact.svg";
import { ReactComponent as Mod4Scr5_Suppliers } from "../assets/icons/Mod4Scr5_Suppliers.svg";
import { ReactComponent as Mod4Scr6_Consult } from "../assets/icons/Mod4Scr6_Consult.svg";
import { ReactComponent as Mod4Scr7_Continue } from "../assets/icons/Mod4Scr7_Continue.svg";
import { ReactComponent as Mod4Scr8_SeeSay } from "../assets/icons/Mod4Scr8_SeeSay.svg";
import { ReactComponent as Mod5Scr1_Gift } from "../assets/icons/Mod5Scr1_Gift.svg";
import { ReactComponent as Mod5Scr2_Question } from "../assets/icons/Mod5Scr2_Question.svg";
import { ReactComponent as Mod5Scr3_Common } from "../assets/icons/Mod5Scr3_Common.svg";
import { ReactComponent as Mod5Scr4_Negative } from "../assets/icons/Mod5Scr4_Negative.svg";
import { ReactComponent as Mod5Scr5_NoAccept } from "../assets/icons/Mod5Scr5_NoAccept.svg";
import { ReactComponent as Mod5Scr6_SeeSay } from "../assets/icons/Mod5Scr6_SeeSay.svg";
import { ReactComponent as NeverExchangeInformation } from "../assets/icons/NeverExchangeInformation.svg";
import { ReactComponent as NoFacilitationPayments } from "../assets/icons/NoFacilitationPayments.svg";
import { ReactComponent as NotifyDivisionComplianceManager } from "../assets/icons/NotifyDivisionComplianceManager.svg";
import { ReactComponent as PersonalInformation } from "../assets/icons/PersonalInformation.svg";
import { ReactComponent as PreApprovalLegalServices } from "../assets/icons/PreApprovalLegalServices.svg";
import { ReactComponent as PrepareCoverLetter } from "../assets/icons/PrepareCoverLetter.svg";
import { ReactComponent as PrepareItenerary } from "../assets/icons/PrepareItenerary.svg";
import { ReactComponent as ProvidePrivacyNoticeStatement } from "../assets/icons/ProvidePrivacyNoticeStatement.svg";
import { ReactComponent as ProvidingBusinessCourtesies } from "../assets/icons/ProvidingBusinessCourtesies.svg";
import { ReactComponent as PublicWebsiteInformationAvailable } from "../assets/icons/PublicWebsiteInformationAvailable.svg";
import { ReactComponent as PurchasingInformation } from "../assets/icons/PurchasingInformation.svg";
import { ReactComponent as RedFlagsRaiseHand } from "../assets/icons/RedFlagsRaiseHand.svg";
import { ReactComponent as ReminderIcon } from "../assets/icons/Reminder.svg";
import { ReactComponent as ReportAnyBreaches } from "../assets/icons/ReportAnyBreaches.svg";
import { ReactComponent as ReportSecurityBreach } from "../assets/icons/ReportSecurityBreach.svg";
import { ReactComponent as RestrictedScreening } from "../assets/icons/RestrictedScreening.svg";
import { ReactComponent as SalesAgent } from "../assets/icons/SalesAgent.svg";
import { ReactComponent as SalesAgentRedFlag } from "../assets/icons/SalesAgentRedFlag.svg";
import { ReactComponent as SalesRepApprovalofDiscount } from "../assets/icons/SalesRepApprovalofDiscount.svg";
import { ReactComponent as Scen1Scr1_Friend } from "../assets/icons/Scen1Scr1_Friend.svg";
import { ReactComponent as Scen1Scr1_Overhear } from "../assets/icons/Scen1Scr1_Overhear.svg";
import { ReactComponent as Scen1Scr2_Business } from "../assets/icons/Scen1Scr2_Business.svg";
import { ReactComponent as Scen1Scr2_Research } from "../assets/icons/Scen1Scr2_Research.svg";
import { ReactComponent as Scen1Scr3_Buy } from "../assets/icons/Scen1Scr3_Buy.svg";
import { ReactComponent as Scen1Scr3_Refuse } from "../assets/icons/Scen1Scr3_Refuse.svg";
import { ReactComponent as Scen1Scr4_Excuse } from "../assets/icons/Scen1Scr4_Excuse.svg";
import { ReactComponent as Scen1Scr4_MNPI } from "../assets/icons/Scen1Scr4_MNPI.svg";
import { ReactComponent as Scen1Scr5_SeeSay } from "../assets/icons/Scen1Scr5_SeeSay.svg";
import { ReactComponent as Scen2Scr1_Lunch } from "../assets/icons/Scen2Scr1_Lunch.svg";
import { ReactComponent as Scen2Scr1_Supplier } from "../assets/icons/Scen2Scr1_Supplier.svg";
import { ReactComponent as Scen2Scr2_Discuss } from "../assets/icons/Scen2Scr2_Discuss.svg";
import { ReactComponent as Scen2Scr2_Reasonable } from "../assets/icons/Scen2Scr2_Reasonable.svg";
import { ReactComponent as Scen2Scr3_Allowed } from "../assets/icons/Scen2Scr3_Allowed.svg";
import { ReactComponent as Scen2Scr3_Values } from "../assets/icons/Scen2Scr3_Values.svg";
import { ReactComponent as Scen2Scr4_Appearance } from "../assets/icons/Scen2Scr4_Appearance.svg";
import { ReactComponent as Scen2Scr4_MNPI } from "../assets/icons/Scen2Scr4_MNPI.svg";
import { ReactComponent as Scen2Scr5_Fancy } from "../assets/icons/Scen2Scr5_Fancy.svg";
import { ReactComponent as Scen3Scr1_Advice } from "../assets/icons/Scen3Scr1_Advice.svg";
import { ReactComponent as Scen3Scr1_Friend } from "../assets/icons/Scen3Scr1_Friend.svg";
import { ReactComponent as Scen3Scr2_Call } from "../assets/icons/Scen3Scr2_Call.svg";
import { ReactComponent as Scen3Scr2_Okay } from "../assets/icons/Scen3Scr2_Okay.svg";
import { ReactComponent as Scen3Scr3_Influence } from "../assets/icons/Scen3Scr3_Influence.svg";
import { ReactComponent as Scen3Scr3_Tip } from "../assets/icons/Scen3Scr3_Tip.svg";
import { ReactComponent as Scen3Scr4_MNPI } from "../assets/icons/Scen3Scr4_MNPI.svg";
import { ReactComponent as Scen4Scr1_FSI } from "../assets/icons/Scen4Scr1_FSI.svg";
import { ReactComponent as Scen4Scr1_Supplier } from "../assets/icons/Scen4Scr1_Supplier.svg";
import { ReactComponent as Scen4Scr2_Sharing } from "../assets/icons/Scen4Scr2_Sharing.svg";
import { ReactComponent as Scen4Scr2 } from "../assets/icons/Scen4Scr2.svg";
import { ReactComponent as Scen4Scr3_Tipping } from "../assets/icons/Scen4Scr3_Tipping.svg";
import { ReactComponent as Scen4Scr3 } from "../assets/icons/Scen4Scr3.svg";
import { ReactComponent as Scen4Scr4 } from "../assets/icons/Scen4Scr4.svg";
import { ReactComponent as Scen4Scr5 } from "../assets/icons/Scen4Scr5.svg";
import { ReactComponent as Scen5Scr1_Advice } from "../assets/icons/Scen5Scr1_Advice.svg";
import { ReactComponent as Scen5Scr2_Merit } from "../assets/icons/Scen5Scr2_Merit.svg";
import { ReactComponent as Scen5Scr3_Friend } from "../assets/icons/Scen5Scr3_Friend.svg";
import { ReactComponent as Scen5Scr4_Descisions } from "../assets/icons/Scen5Scr4_Descisions.svg";
import { ReactComponent as Scen5Scr5_Financial } from "../assets/icons/Scen5Scr5_Financial.svg";
import { ReactComponent as Scen5Scr6_Consult } from "../assets/icons/Scen5Scr6_Consult.svg";
import { ReactComponent as ScreeningThirdPartyIntermediaries } from "../assets/icons/ScreeningThirdPartyIntermediaries.svg";
import { ReactComponent as SecurityAndConfidentiality } from "../assets/icons/SecurityAndConfidentiality.svg";
import { ReactComponent as SeekPreApproval } from "../assets/icons/SeekPreApproval.svg";
import { ReactComponent as StatisticalExchangeProgramPermission } from "../assets/icons/StatisticalExchangeProgramPermission.svg";
import { ReactComponent as StatisticalInformationExchange } from "../assets/icons/StatisticalInformationExchange.svg";
import { ReactComponent as StayAlert } from "../assets/icons/StayAlert.svg";
import { ReactComponent as StayAlertWarningSigns } from "../assets/icons/StayAlertWarningSigns.svg";
import { ReactComponent as SubDealerHighMargins } from "../assets/icons/SubDealerHighMargins.svg";
import { ReactComponent as SubDealerRedFlag } from "../assets/icons/SubDealerRedFlag.svg";
import { ReactComponent as SubDealers } from "../assets/icons/SubDealers.svg";
import { ReactComponent as SubDealerSalesPrice } from "../assets/icons/SubDealerSalesPrice.svg";
import { ReactComponent as SubDealerVagueRole } from "../assets/icons/SubDealerVagueRole.svg";
import { ReactComponent as ThirdParties } from "../assets/icons/ThirdParties.svg";
import { ReactComponent as ThirdPartyIntermediaries } from "../assets/icons/ThirdPartyIntermediaries.svg";
import { ReactComponent as ThreeStepInformation } from "../assets/icons/ThreeStepInformation.svg";
import { ReactComponent as TipIcon } from "../assets/icons/Tip.svg";
import { ReactComponent as Tours } from "../assets/icons/Tours.svg";
import { ReactComponent as TradeAssociations } from "../assets/icons/TradeAssociations.svg";
import { ReactComponent as TradeCompliance } from '../assets/icons/TradeCompliance.svg';
import { ReactComponent as TradeComplianceRedFlags } from "../assets/icons/TradeComplianceRedFlags.svg";
import { ReactComponent as TradeShows } from "../assets/icons/TradeShows.svg";
import { ReactComponent as TrainingWithCompetitors } from "../assets/icons/TrainingWithCompetitors.svg";
import { ReactComponent as Transfers } from "../assets/icons/Transfers.svg";
import { ReactComponent as TransparencyAndFairness } from "../assets/icons/TransparencyAndFairness.svg";
import { ReactComponent as UtilizeEffectiveCommunication } from "../assets/icons/UtilizeEffectiveCommunication.svg";
import { ReactComponent as VisaInvitationLetter } from "../assets/icons/VisaInvitationLetter.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/WarningSigns.svg";
import { ReactComponent as Website } from "../assets/icons/Website.svg";
import { ReactComponent as WebsiteInformationIncluded } from "../assets/icons/WebsiteInformationIncluded.svg";
// Module Images and Header Images
import antiBriberyBriberyBusinessCourtesies from "../assets/moduleImages/AntiBribery_BriberyBusinessCourtesies.jpg";
import AntiBriberyHeader from "../assets/moduleImages/AntiBribery_Header.jpg";
import antiBriberyHighSalesPrice from "../assets/moduleImages/AntiBribery_HighSalesPrice.jpg";
import antiBriberyIntroToAntiBribery from "../assets/moduleImages/AntiBribery_IntroToAntiBribery.jpg";
import antiBriberyLocalCharities from "../assets/moduleImages/AntiBribery_LocalCharities.jpg";
import antiBriberySalesAgent from "../assets/moduleImages/AntiBribery_SalesAgent.jpg";
import antiBriberySubDealers from "../assets/moduleImages/AntiBribery_SubDealers.jpg";
import antiBriberyThirdPartyIntermediaries from "../assets/moduleImages/AntiBribery_ThirdPartyIntermediaries.jpg";
import antiBriberyVisaInvitationLetters from "../assets/moduleImages/AntiBribery_VisaInvitationLetters.jpg";
import conflictofInterestPhoto from "../assets/moduleImages/ConflictofInterestPhoto.jpg";
import dataPrivacyAccidentalBreach from "../assets/moduleImages/DataPrivacy_AccidentalBreach.jpg";
import dataPrivacyBreachResponse from "../assets/moduleImages/DataPrivacy_BreachResponse.jpg";
import DataPrivacyHeader from "../assets/moduleImages/DataPrivacy_Header.jpg";
import dataPrivacyPersonalInformation from "../assets/moduleImages/DataPrivacy_PersonalInformation.jpg";
import dataPrivacyPrivacyByDesign from "../assets/moduleImages/DataPrivacy_PrivacyByDesign.jpg";
import dataPrivacyThirdParties from "../assets/moduleImages/DataPrivacy_ThirdParties.jpg";
import dataPrivacyTradeShows from "../assets/moduleImages/DataPrivacy_TradeShows.jpg";
import dataPrivacyWebsites from "../assets/moduleImages/DataPrivacy_Websites.jpg";
import fairCompetitionCompetitiveInformation1 from "../assets/moduleImages/FairCompetition_CompetitiveInformation1.jpg";
import fairCompetitionCompetitorSupplier from "../assets/moduleImages/FairCompetition_Competitor.jpg";
import FairCompetitionHeader from "../assets/moduleImages/FairCompetition_Header_New.jpg";
import fairCompetitionStatisticalInformationExchanges from "../assets/moduleImages/FairCompetition_StatisticalInformationExchanges.jpg";
import fairCompetitionTradeAssociations from "../assets/moduleImages/FairCompetition_TradeAssociations.jpg";
import fairCompetitionTradeShows from "../assets/moduleImages/FairCompetition_TradeShows.jpg";
import fairCompetitionTrainingWithCompetitors from "../assets/moduleImages/FairCompetition_TrainingWithCompetitors.jpg";
import insiderTradingPhoto from "../assets/moduleImages/InsiderTradingPhoto.jpg";
import conflictofInterestExamples from "../assets/moduleImages/Mod2Image_ConflictExamples.jpg";
import insiderTradingMNPI from "../assets/moduleImages/Mod2Image_MNPI.jpg";
import conflictofInterestNepotism from "../assets/moduleImages/Mod3Image_Nepotism.jpg";
import insiderTradingPolicy from "../assets/moduleImages/Mod3Image_Policy.jpg";
import insiderTradingOpen from "../assets/moduleImages/Mod4Image_Open.jpg";
import conflictofInterestOutsideOrganization from "../assets/moduleImages/Mod4Image_OutsideOrganization.jpg";
import conflictofInterestGifts from "../assets/moduleImages/Mod5Image_Gifts.jpg";
import insiderTradingOverhear from "../assets/moduleImages/Scen1Image_Overhear.jpg";
import conflictofInterestSports from "../assets/moduleImages/Scen1Image_Sports.jpg";
import conflictofInterestLunch from "../assets/moduleImages/Scen2Image_Lunch.jpg";
import insiderTradingSupplier from "../assets/moduleImages/Scen2Image_Supplier.jpg";
import conflictofInterestAdvise from "../assets/moduleImages/Scen3Image_Advise.jpg";
import insiderTradingFriend from "../assets/moduleImages/Scen3Image_Friend.jpg";
import conflictofInterestSupplier from "../assets/moduleImages/Scen4Image_Supplier.jpg";
import insiderTradingTipping from "../assets/moduleImages/Scen4Image_Tipping.jpg";
import conflictofInterestPersonalGain from "../assets/moduleImages/Scen5Image_PersonalGain.jpg";
import TradeComplianceHeader from "../assets/moduleImages/TradeCompliance_AntiBoycottCompliance.jpg";
import tradeComplianceCustoms from "../assets/moduleImages/TradeCompliance_Customs.jpg";
import tradeComplianceDiversion from "../assets/moduleImages/TradeCompliance_Diversion.jpg";
import tradeComplianceExportControls from "../assets/moduleImages/TradeCompliance_ExportControls.jpg";
import tradeComplianceHandCarry from "../assets/moduleImages/TradeCompliance_HandCarry.jpg";
import tradeComplianceLicensing from "../assets/moduleImages/TradeCompliance_Licensing.jpg";
import tradeComplianceRedFlags from "../assets/moduleImages/TradeCompliance_RedFlags.jpg";
import tradeComplianceTours from "../assets/moduleImages/TradeCompliance_Tours.jpg";
const resource = {
  // Business Area Icons
  dealersIcon: <Dealers width={40} height={40} />,
  socialMediaIcon: <SocialMedia width={40} height={40} />,
  entertainingIcon: <Customers width={40} height={40} />,
  giftsIcon: <Gifts width={40} height={40} />,
  competitorsIcon: <Competitors width={40} height={40} />,
  customersIcon: <ServingCustomers width={40} height={40} />,
  shippingIcon: <Shipping width={40} height={40} />,
  tradeIcon: <Trade width={40} height={40} />,
  travelIcon: <Travel width={40} height={40} />,
  suppliersIcon: <Suppliers width={40} height={40} />,
  employeeDecisionIcon: <EmploymentDecisions width={40} height={40} />,
  insiderTradingWorkIcon: <NewInsiderTrading width={40} height={40} />,//bug fix:33805
  conflictOfInterestWorkIcon: <NewConflictOfInterest width={40} height={40} />,//bug fix:33805
  // Risk Area Icons
  antiBriberyIcon: AntiBribery,
  dataPrivacyIcon: DataPrivacy,
  fairCompetitionIcon: FairCompetition,
  tradeComplianceIcon: TradeCompliance,
  conflictOfInterestIcon: ConflictofInterest,
  insiderTradingIcon: InsiderTrading,
  // Icons
  trophyIcon: TrophyIcon,
  reminderIcon: ReminderIcon,
  tipIcon: TipIcon,
  warningIcon: WarningIcon,
  bestPracticesIcon: BestPractices,
  seekIcon: SeekPreApproval,
  conlfictIcon: ConflictofInterestIcon,
  insiderIcon: InsiderTradingIcon,
  antiIcon: AntiBriberyIcon,
  redFlagsIcon: RedFlagsRaiseHand,
  dueIcon: DueDiligenceScreening,
  expenseIcon: ExpenseBooks,
  businessCourtesiesIcon: ProvidingBusinessCourtesies,
  paymentsIcon: NoFacilitationPayments,
  contactDivisionTPIIcon: ContactDivisionTPI,
  contactDivisionTPIQuestionnaireIcon: ContactDivisionTPIQuestionnaire,
  documentBusinessJustificationIcon: DocumentBusinessJustification,
  legalServicesReviewIcon: LegalServicesReview,
  screeningThirdPartyIntermediariesIcon: ScreeningThirdPartyIntermediaries,
  thirdPartyIntermediariesIcon: ThirdPartyIntermediaries,
  briberyBusinessCourtesiesIcon: BriberyBusinessCourtesies,
  confirmOfferLegalIcon: ConfirmOfferLegal,
  exerciseGoodJudgementIcon: ExerciseGoodJudgement,
  preApprovalLegalServicesIcon: PreApprovalLegalServices,
  visaInvitationLetterIcon: VisaInvitationLetter,
  createTheLetterIcon: CreateTheLetter,
  gatherInformationIcon: GatherInformation,
  getPreApprovalIcon: GetPreApproval,
  notifyDivisionComplianceManagerIcon: NotifyDivisionComplianceManager,
  prepareCoverLetterIcon: PrepareCoverLetter,
  prepareIteneraryIcon: PrepareItenerary,
  localCharitiesIcon: LocalCharities,
  legalServicePreApprovalIcon: LegalServicePreApproval,
  salesAgentIcon: SalesAgent,
  salesAgentRedFlagIcon: SalesAgentRedFlag,
  stayAlertWarningSignsIcon: StayAlertWarningSigns,
  subDealersIcon: SubDealers,
  subDealerSalesPriceIcon: SubDealerSalesPrice,
  subDealerRedFlagIcon: SubDealerRedFlag,
  subDealerHighMarginsIcon: SubDealerHighMargins,
  subDealerVagueRoleIcon: SubDealerVagueRole,
  breachResponseIcon: BreachResponse,
  reportSecurityBreachIcon: ReportSecurityBreach,
  informationSecurityReportIcon: InformationSecurityReport,
  dataPrivacyModuleIcon: DataPrivacyIcon,
  personalInformationIcon: PersonalInformation,
  transparencyAndFairnessIcon: TransparencyAndFairness,
  limitedPurposeAndUseIcon: LimitedPurposeAndUse,
  dataQualityIcon: DataQuality,
  securityAndConfidentialityIcon: SecurityAndConfidentiality,
  accessAndIndividualsRightsIcon: AccessAndIndividualsRights,
  transfersIcon: Transfers,
  threeStepInformationIcon: ThreeStepInformation,
  confirmationPersonalInformationIcon: ConfirmationPersonalInformation,
  dataPrivacyPersonalInformationIcon: DataPrivacyPersonalInformation,
  websiteIcon: Website,
  websitePrivacyIcon: ConsiderWebsiteInformationPrivacy,
  providePrivacyStatementIcon: ProvidePrivacyNoticeStatement,
  websiteInformationIcon: WebsiteInformationIncluded,
  dataTransfewProcessedFairlyIcon: DataTransfewProcessedFairly,
  dataTransferPrivacyIcon: DataTransferPrivacy,
  thirdPartiesIcon: ThirdParties,
  tradeShowsIcon: TradeShows,
  competitorsRaisePricesIcon: CompetitorsRaisePrices,
  illegalPriceFixingIcon: IllegalPriceFixing,
  accidentalBreachIcon: AccidentalBreach,
  reportAnyBreachesIcon: ReportAnyBreaches,
  fairCompIcon: FairCompetitionIcon,
  dealerPriceProductIcon: DealerPriceProduct,
  doNotMakeAgreementsIcon: DoNotMakeAgreements,
  followWrittenAgendaIcon: FollowWrittenAgenda,
  getApprovalIcon: GetApproval,
  neverExchangeInformationIcon: NeverExchangeInformation,
  stayAlertIcon: StayAlert,
  utilizeEffectiveCommunicationIcon: UtilizeEffectiveCommunication,
  competitorSupplierIcon: CompetitorSupplier,
  purchasingInformationIcon: PurchasingInformation,
  competitiveInformationIcon: CompetitiveInformation,
  publicWebsiteInformationAvailableIcon: PublicWebsiteInformationAvailable,
  dealersConsideredCompetitorsIcon: DealersConsideredCompetitors,
  doNotExchangeSensitiveDataIcon: DoNotExchangeSensitiveData,
  engineersDiscussCostIcon: EngineersDiscussCost,
  trainingWithCompetitorsIcon: TrainingWithCompetitors,
  tradeAssociationsIcon: TradeAssociations,
  gatherInformationv2Icon: GatherInformationv2,
  statisticalExchangeProgramPermissionIcon: StatisticalExchangeProgramPermission,
  statisticalInformationExchangeIcon: StatisticalInformationExchange,
  exportControlsIcon: ExportControls,
  customsIcon: Customs,
  declareItemsicon: DeclareItems,
  antiBoycottComplianceIcon: AntiBoycottCompliance,
  generic2Icon: Generic02,
  generic7Icon: Generic07,
  tradeComplianceRedFlagsIcon: TradeComplianceRedFlags,
  consultManagerBeforeSaleIcon: ConsultManagerBeforeSale,
  diversionIcon: Diversion,
  salesRepApprovalofDiscountIcon: SalesRepApprovalofDiscount,
  licensingIcon: Licensing,
  handCarryIcon: HandCarry,
  restrictedScreeningIcon: RestrictedScreening,
  toursIcon: Tours,
  mod1Scr1_ConflictofInterestIcon: Mod1Scr1_ConflictofInterestIcon,
  mod1Scr2_LoyaltyIcon: Mod1Scr2_Loyalty,
  mod1Scr3_AvoidConflictIcon: Mod1Scr3_AvoidConflict,
  mod1Scr4_SeeSayIcon: Mod1Scr4_SeeSay,
  mod2Scr1_ConflictIcon: Mod2Scr1_Conflict,
  mod2Scr2_MoneyIcon: Mod2Scr2_Money,
  mod2Scr3_PurchaseIcon: Mod2Scr3_Purchase,
  mod2Scr4_ApprovalsIcon: Mod2Scr4_Approvals,
  mod2Scr5_GiftsIcon: Mod2Scr5_Gifts,
  mod2Scr6_TwoJobsIcon: Mod2Scr6_TwoJobs,
  mod2Scr7_HireIcon: Mod2Scr7_Hire,
  mod2Scr8_InterestIcon: Mod2Scr8_Interest,
  mod2Scr9_Interest2Icon: Mod2Scr9_Interest2,
  mod2Scr10_GainIcon: Mod2Scr10_Gain,
  mod2Scr11_RomanceIcon: Mod2Scr11_Romance,
  mod2Scr12_SeeSayIcon: Mod2Scr12_SeeSay,
  mod3Scr1_NepotismIcon: Mod3Scr1_Nepotism,
  mod3Scr2_AllLevelsIcon: Mod3Scr2_AllLevels,
  mod3Scr3_MeritIcon: Mod3Scr3_Merit,
  mod3Scr4_LeaderIcon: Mod3Scr4_Leader,
  mod3Scr5_RequestIcon: Mod3Scr5_Request,
  mod3Scr6_SeeSayIcon: Mod3Scr6_SeeSay,
  mod4Scr1_TwoJobsIcon: Mod4Scr1_TwoJobs,
  mod4Scr2_IndustryIcon: Mod4Scr2_Industry,
  mod4Scr3_NonProfitIcon: Mod4Scr3_NonProfit,
  mod4Scr4_CompeteIcon: Mod4Scr4_Compete,
  mod4Scr5_SuppliersIcon: Mod4Scr5_Suppliers,
  mod4Scr6_ConsultIcon: Mod4Scr6_Consult,
  mod4Scr7_ContinueIcon: Mod4Scr7_Continue,
  mod4Scr8_SeeSayIcon: Mod4Scr8_SeeSay,
  mod5Scr1_GiftIcon: Mod5Scr1_Gift,
  mod5Scr2_QuestionIcon: Mod5Scr2_Question,
  mod5Scr3_CommonIcon: Mod5Scr3_Common,
  mod5Scr4_NegativeIcon: Mod5Scr4_Negative,
  mod5Scr5_NoAcceptIcon: Mod5Scr5_NoAccept,
  mod5Scr6_SeeSayIcon: Mod5Scr6_SeeSay,
  scen1Scr1_FriendIcon: Scen1Scr1_Friend,
  scen1Scr2_BusinessIcon: Scen1Scr2_Business,
  scen1Scr3_RefuseIcon: Scen1Scr3_Refuse,
  scen1Scr4_ExcuseIcon: Scen1Scr4_Excuse,
  scen1Scr5_SeeSayIcon: Scen1Scr5_SeeSay,
  scen2Scr1_LunchIcon: Scen2Scr1_Lunch,
  scen2Scr2_ReasonableIcon: Scen2Scr2_Reasonable,
  scen2Scr3_ValuesIcon: Scen2Scr3_Values,
  scen2Scr4_AppearanceIcon: Scen2Scr4_Appearance,
  scen2Scr5_FancyIcon: Scen2Scr5_Fancy,
  scen3Scr1_AdviceIcon: Scen3Scr1_Advice,
  scen3Scr2_OkayIcon: Scen3Scr2_Okay,
  scen3Scr3_InfluenceIcon: Scen3Scr3_Influence,
  scen4Scr1_SupplierIcon: Scen4Scr1_Supplier,
  scen4Scr2Icon: Scen4Scr2,
  scen4Scr3Icon: Scen4Scr3,
  scen4Scr4Icon: Scen4Scr4,
  scen4Scr5Icon: Scen4Scr5,
  scen5Scr1_AdviceIcon: Scen5Scr1_Advice,
  scen5Scr2_MeritIcon: Scen5Scr2_Merit,
  scen5Scr3_FriendIcon: Scen5Scr3_Friend,
  scen5Scr4_DescisionsIcon: Scen5Scr4_Descisions,
  scen5Scr5_FinancialIcon: Scen5Scr5_Financial,
  scen5Scr6_ConsultIcon: Scen5Scr6_Consult,
  mod1Scr1_InsiderIcon: Mod1Scr1_Insider,
  mod1Scr2_ProhibitedIcon: Mod1Scr2_Prohibited,
  mod1Scr3_LegalIcon: Mod1Scr3_Legal,
  mod1Scr4_PolicyIcon: Mod1Scr4_Policy, 
  mod1Scr5_ContactIcon: Mod1Scr5_Contact,
  mod2Scr1_MNPIIcon: Mod2Scr1_MNPI,
  mod2Scr2_CircumstanceIcon: Mod2Scr2_Circumstance,
  mod2Scr3_MaterialIcon: Mod2Scr3_Material,
  mod2Scr4_IncreaseIcon: Mod2Scr4_Increase,
  mod2Scr5_PublicIcon: Mod2Scr5_Public,
  mod2Scr6A_PressReleaseIcon: Mod2Scr6A_PressRelease,
  mod2Scr6B_AssesIcon: Mod2Scr6B_Asses,
  mod2Scr6C_FilingsIcon: Mod2Scr6C_Filings,
  mod2Scr7_MNPIIcon: Mod2Scr7_MNPI,
  mod3Scr1_PolicyIcon: Mod3Scr1_Policy,
  mod3Scr2_PeopleIcon: Mod3Scr2_People,
  mod3Scr3_FamilyIcon: Mod3Scr3_Family,
  mod3Scr4_ExternalIcon: Mod3Scr4_External,
  mod3Scr5_TrustIcon: Mod3Scr5_Trust,
  mod3Scr6_SecuritiesIcon: Mod3Scr6_Securities,
  mod3Scr7_PolicyIcon: Mod3Scr7_Policy,
  mod3Scr8_ProhibitsIcon: Mod3Scr8_Prohibits,
  mod3Scr9_PolicyIcon: Mod3Scr9_Policy,
  mod3Scr10_ContactIcon: Mod3Scr10_Contact,
  mod4Scr1_InsidersIcon: Mod4Scr1_Insiders,
  mod4Scr2_OpenIcon: Mod4Scr2_Open,
  mod4Scr3_NoTradeIcon: Mod4Scr3_NoTrade,
  mod4Scr4_ExceptionsIcon: Mod4Scr4_Exceptions,
  mod4Scr5_ContactIcon: Mod4Scr5_Contact,
  scen1Scr1_OverhearIcon: Scen1Scr1_Overhear,
  scen1Scr2_ResearchIcon: Scen1Scr2_Research,
  scen1Scr3_BuyIcon: Scen1Scr3_Buy,
  scen1Scr4_MNPIIcon: Scen1Scr4_MNPI,
  scen2Scr1_SupplierIcon: Scen2Scr1_Supplier,
  scen2Scr2_DiscussIcon: Scen2Scr2_Discuss,
  scen2Scr3_AllowedIcon: Scen2Scr3_Allowed,
  scen2Scr4_MNPIIcon: Scen2Scr4_MNPI,
  scen3Scr1_FriendIcon: Scen3Scr1_Friend,
  scen3Scr2_CallIcon: Scen3Scr2_Call,
  scen3Scr3_TipIcon: Scen3Scr3_Tip,
  scen3Scr4_MNPIIcon: Scen3Scr4_MNPI,
  scen4Scr1_FSIIcon: Scen4Scr1_FSI,
  scen4Scr2_SharingIcon: Scen4Scr2_Sharing,
  scen4Scr3_TippingIcon: Scen4Scr3_Tipping,

  // Module Images
  TestIconKey: AntiBriberyHeader,
  cardImage: AntiBriberyHeader,
  AntiBribery_BriberyBusinessCourtesies: antiBriberyBriberyBusinessCourtesies,
  AntiBribery_HighSalesPrice: antiBriberyHighSalesPrice,
  AntiBribery_IntroToAntiBribery: antiBriberyIntroToAntiBribery,
  AntiBribery_LocalCharities: antiBriberyLocalCharities,
  AntiBribery_SalesAgent: antiBriberySalesAgent,
  AntiBribery_SubDealers: antiBriberySubDealers,
  AntiBribery_ThirdPartyIntermediaries: antiBriberyThirdPartyIntermediaries,
  AntiBribery_VisaInvitationLetters: antiBriberyVisaInvitationLetters,

  DataPrivacy_AccidentalBreach: dataPrivacyAccidentalBreach,
  DataPrivacy_BreachResponse: dataPrivacyBreachResponse,
  DataPrivacy_Header: DataPrivacyHeader,
  DataPrivacy_PersonalInformation: dataPrivacyPersonalInformation,
  DataPrivacy_PrivacyByDesign: dataPrivacyPrivacyByDesign,
  DataPrivacy_ThirdParties: dataPrivacyThirdParties,
  DataPrivacy_TradeShows: dataPrivacyTradeShows,
  DataPrivacy_Websites: dataPrivacyWebsites,

  FairCompetition_CompetitiveInformation1: fairCompetitionCompetitiveInformation1,
  FairCompetition_CompetitorSupplier: fairCompetitionCompetitorSupplier,
  FairCompetition_Header: FairCompetitionHeader,
  FairCompetition_StatisticalInformationExchanges: fairCompetitionStatisticalInformationExchanges,
  FairCompetition_TradeAssociations: fairCompetitionTradeAssociations,
  FairCompetition_TradeShows: fairCompetitionTradeShows,
  FairCompetition_TrainingWithCompetitors: fairCompetitionTrainingWithCompetitors,

  TradeCompliance_AntiBoycottCompliance: TradeComplianceHeader,
  TradeCompliance_Customs: tradeComplianceCustoms,
  TradeCompliance_Diversion: tradeComplianceDiversion,
  TradeCompliance_ExportControls: tradeComplianceExportControls,
  TradeCompliance_HandCarry: tradeComplianceHandCarry,
  TradeCompliance_Licensing: tradeComplianceLicensing,
  TradeCompliance_RedFlags: tradeComplianceRedFlags,
  TradeCompliance_Tours: tradeComplianceTours,

  ConflictofInterestPhoto: conflictofInterestPhoto,

  ConflictofInterestExamples: conflictofInterestExamples,
  ConflictofInterestNepotism: conflictofInterestNepotism,
  ConflictofInterestOutsideOrganization: conflictofInterestOutsideOrganization,
  ConflictofInterestGifts: conflictofInterestGifts,
  ConflictofInterestSports: conflictofInterestSports,
  ConflictofInterestLunch: conflictofInterestLunch,
  ConflictofInterestAdvise: conflictofInterestAdvise,
  ConflictofInterestSupplier: conflictofInterestSupplier,
  ConflictofInterestPersonalGain: conflictofInterestPersonalGain,

  InsiderTradingPhoto: insiderTradingPhoto,

  InsiderTradingMNPI: insiderTradingMNPI,
  InsiderTradingPolicy: insiderTradingPolicy,
  InsiderTradingOpen: insiderTradingOpen,
  InsiderTradingOverhear: insiderTradingOverhear,
  InsiderTradingSupplier: insiderTradingSupplier,
  InsiderTradingFriend: insiderTradingFriend,
  InsiderTradingTipping: insiderTradingTipping,
};

export default class ImageResource {
  constructor() {
    this.get = this.get.bind(this);
  }

  static getIconComponent(key) {
    const IconComponent = resource[key];
    const RenderIcon = props => <IconComponent {...props} />;
    return RenderIcon;
  }

  static get(key) {
    return resource[key];
  }
}
