// Start here after login.
// Secondly, this is the file that controls navigation and kicks off everything
// Manages the sidebar navigation to move between pages
import { AppBar, CssBaseline, Divider, Drawer, Paper, Container} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer  from '../components/footer'

import UserService from "../data/api/userService";
// imports for pages
import FileUpload from "../data/inputForm";
import Modules from "../data/modulesCustom";
import CommonQA from "../views/commonQA";
import Contacts from "../views/contacts";
import Home from "../views/home";
import LearningModules from "../views/learningModules";
import Questions from "../views/questions";
import Users from "../views/users";
import Work from "../views/work";
import DM from "../views/dm";

const drawerWidth = 200;

const userStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: '100vh',
    position: 'absolute',
    width: '85%',
    right: 0,
    flexDirection: 'column'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "black",
    color: "#FFCC00",
    fontFamily: "Soleil"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#373A3D",
    color: "#FFDF68",
    fontFamily: "Soleil"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#F6F6FA"
  },
  toolbar: theme.mixins.toolbar
}));

function ClippedDrawer() {
  const classes = userStyles();
  const changeAccess = useStoreActions(actions => actions.accessLevel.changeAccessLevel);
  const [currentUserAccess, setCurrentUserAccess] = React.useState(
    useStoreState(state => state.accessLevel.accessLevel)
  );
   const updateErrorState = useStoreActions(actions => actions.isError.updateIsError);
    // eslint-disable-next-line
  const [currentErrorState, setErrorState] = React.useState(
    useStoreState(state => state.isError.isError)
  );
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    // set timeout in place until we can fix the authentication issues in app.js and no token being available yet.
    const time= setInterval(() => {
      if(localStorage.getItem("accessToken")){
      getUser();
        clearInterval(time);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = () => {
    UserService.getUser()
      .then(user => {
        const userData = user.getUserData();
        changeAccess(userData.getPermissionLevel());
        setCurrentUserAccess(userData.getPermissionLevel());
      })
      .catch(error => {
        changeAccess("Basic");
        if(error.response.status === 403 ){
          updateErrorState(true);
          setErrorState(true);
        }
      });
  };

  const routes = [
    {
      path: "/",
      exact: true,
      main: () => <Home />
    },
    {
      path: "/documents",
      main: () => <FileUpload />
    },
    {
      path: "/questions",
      main: () => <Questions />
    },
    {
      path: "/users",
      main: () => <Users />
    },
    {
      path: "/modules",
      main: () => <Modules />
    },
    {
      path: "/contactsTable",
      main: () => <Contacts />
    },
    {
      path: "/work",
      main: () => <Work />
    },
    {
      path: "/commonQA",
      main: () => <CommonQA />
    },
    {
      path: "/learningmodules",
      main: () => <LearningModules />
    },
    {
      path: "/decisionmatrix",
      main: () => <DM />
    }
  ];

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  let listItems;

  if (currentUserAccess === "FullAdmin") {
    listItems = (
      <div>
        <ListItem
          button
          selected={selectedIndex === 1}
          onClick={event => handleListItemClick(event, 1)}
          component={Link}
          to="/questions"
        >
          <ListItemText primary="Asked Questions" />
        </ListItem>
        <Divider />
        <ListItem
          button
          selected={selectedIndex === 2}
          onClick={event => handleListItemClick(event, 2)}
          component={Link}
          to="/users"
        >
          <ListItemText primary="Manage Users" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 5}
          onClick={event => handleListItemClick(event, 5)}
          component={Link}
          to="/contactsTable"
        >
          <ListItemText primary="Contacts" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 6}
          onClick={event => handleListItemClick(event, 6)}
          component={Link}
          to="/work"
        >
          <ListItemText primary="Work Content" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 7}
          onClick={event => handleListItemClick(event, 7)}
          component={Link}
          to="/commonQA"
        >
          <ListItemText primary="FAQ Content" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 8}
          onClick={event => handleListItemClick(event, 8)}
          component={Link}
          to="/learningModules"
        >
          <ListItemText primary="Learning Modules" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 9}
          onClick={event => handleListItemClick(event, 9)}
          component={Link}
          to="/decisionmatrix"
        >
          <ListItemText primary="Decision Matrix" />
        </ListItem>

      </div>
    );
  } else if (currentUserAccess === "QuestionAdmin") {
    listItems = (
      <div>
        <ListItem
          button
          selected={selectedIndex === 1}
          onClick={event => handleListItemClick(event, 1)}
          component={Link}
          to="/questions"
        >
          <ListItemText primary="Asked Questions" />
        </ListItem>
        <Divider />
        <ListItem
          button
          selected={selectedIndex === 2}
          onClick={event => handleListItemClick(event, 2)}
          component={Link}
          to="/users"
        >
          <ListItemText primary="Manage Users" />
        </ListItem>
      </div>
    );
  } else if (currentUserAccess === "Advisor") {
    listItems = (
      <ListItem
        button
        selected={selectedIndex === 1}
        onClick={event => handleListItemClick(event, 1)}
        component={Link}
        to="/questions"
      >
        <ListItemText primary="Asked Questions" />
      </ListItem>
    );
  } else if (currentUserAccess === "ContentAdmin") {
    listItems = (
      <div>
        <ListItem
          button
          selected={selectedIndex === 1}
          onClick={event => handleListItemClick(event, 1)}
          component={Link}
          to="/questions"
        >
          <ListItemText primary="Asked Questions" />
        </ListItem>
        <Divider />
        <ListItem
          button
          selected={selectedIndex === 5}
          onClick={event => handleListItemClick(event, 5)}
          component={Link}
          to="/contactsTable"
        >
          <ListItemText primary="Contacts" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 6}
          onClick={event => handleListItemClick(event, 6)}
          component={Link}
          to="/work"
        >
          <ListItemText primary="Work Content" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 7}
          onClick={event => handleListItemClick(event, 7)}
          component={Link}
          to="/commonQA"
        >
          <ListItemText primary="FAQ Content" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 8}
          onClick={event => handleListItemClick(event, 8)}
          component={Link}
          to="/learningModules"
        >
          <ListItemText primary="Learning Modules" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 9}
          onClick={event => handleListItemClick(event, 9)}
          component={Link}
          to="/decisionmatrix"
        >
          <ListItemText primary="DecisionMatrix" />
        </ListItem>

      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Router>
        <Redirect to="/" />
      </Router>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" noWrap>
            {" "}
            Manager Portal
          </Typography>
        </Toolbar>
      </AppBar>

      <Router>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          <List aria-label="main">
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={event => handleListItemClick(event, 0)}
              component={Link}
              to="/"
            >
              <ListItemText primary="Home" />
            </ListItem>
            {listItems}
          </List>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact} component={route.sidebar} />
          ))}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {routes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact} component={route.main} />
          ))}
        </main>
      </Router>

      <Footer />
    </div>
  );
}

export default ClippedDrawer;
