import React from 'react';

// Manages the sidebar navigation to move between pages
import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import { appVersion } from '../data/api/wtrw.api';

const useStyles = makeStyles(theme => ({
    appFooterBar: {
        zIndex: theme.zIndex.drawer + 2,
        backgroundColor: "transparent",
        color: "black",
        border: 0,
        position: 'relative',
        right: 0,
        width: '100%',
        textAlign: 'center',
        fontFamily: "Soleil"
    }
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.appFooterBar} component="footer" square variant="outlined">
            <Container maxWidth="lg">
                <Typography variant="caption" color="initial" >
                    Version {appVersion} Copyright &#169;2024 Caterpillar. All Rights Reserved.
                </Typography>
            </Container>
        </Paper>
    )
};

export default Footer;