// @flow
import ContentTypes from "../../constants/ContentTypes";
import CEOMessageDAO from "./CEOMessageDAO";
import CommonQADAO from "./CommonQADAO";
import DescriptionModel from "./DescriptionDAO";
import DoDoNotDAO from "./DoDoNotDAO";
import KeywordActionDAO from "./KeywordActionDAO";
import ModuleDAO from "./ModuleDAO";
import ResourceDAO from "./ResourceDAO";
import DecisionMatrixDAO from "./DecisionMatrixDAO";

class ContentDAO {
  riskArea: string;
  businessArea: string;
  languageCode: string;
  identifier: string;
  riskAreaIconKey: string;
  businessAreaIconKey: string;
  contentData: {};
  contentType: string;
  keywordActions: Object[];

  constructor(obj: Object) {
    obj && Object.assign(this, obj);
  }

  getRiskArea() {
    return this.riskArea;
  }

  setRiskArea(val: string) {
    this.riskArea = val;
  }

  getBusinessArea() {
    return this.businessArea;
  }

  setBusinessArea(val: string) {
    this.businessArea = val;
  }

  getContentType() {
    return this.contentType;
  }

  setContentType(val: string) {
    this.contentType = val;
  }

  getContentData() {
    return this.contentData;
  }

  setContentData(val: Object) {
    this.contentData = val;
  }

  setLanguageCode(languageCode: string) {
    this.languageCode = languageCode;
  }

  getLanguageCode() {
    return this.languageCode;
  }

  setIdentifier(identifier: string) {
    this.identifier = identifier;
  }

  getIdentifier() {
    return this.identifier;
  }

  setRiskAreaIconKey(riskAreaIconKey: string) {
    this.riskAreaIconKey = riskAreaIconKey;
  }

  getRiskAreaIconKey() {
    return this.riskAreaIconKey;
  }

  setBusinessAreaIconKey(businessAreaIconKey: string) {
    this.businessAreaIconKey = businessAreaIconKey;
  }

  getBusinessAreaIconKey() {
    return this.businessAreaIconKey;
  }

  setKeywordActions(keywordActions: Object[]) {
    this.keywordActions = keywordActions;
  }

  getKeywordActions() {
    return this.keywordActions;
  }

  toObject(input: Object) {
    this.setRiskArea(input.riskArea);
    this.setContentType(input.contentType);
    this.setBusinessArea(input.businessArea);
    this.setLanguageCode(input.languageCode);
    this.setIdentifier(input.identifier);
    this.setBusinessAreaIconKey(input.businessAreaIconKey);
    this.setRiskAreaIconKey(input.riskAreaIconKey);

    const keywordActions = [];
    if (input.keywordActions) {
      input.keywordActions.forEach(keywordAction => {
        keywordActions.push(new KeywordActionDAO().toObject(keywordAction));
      });
    }
    this.setKeywordActions(keywordActions);

    switch (this.contentType) {
      case ContentTypes.DESCRIPTION: {
        this.setContentData(new DescriptionModel().toObject(input.contentData));
        break;
      }
      case ContentTypes.CEOMESSAGE: {
        this.setContentData(new CEOMessageDAO().toObject(input.contentData));
        break;
      }
      case ContentTypes.MODULE: {
        this.setContentData(new ModuleDAO().toObject(input.contentData));
        break;
      }
      case ContentTypes.SPOTLIGHT: {
        this.setContentData(new ModuleDAO().toObject(input.contentData));
        break;
      }
      case ContentTypes.RESOURCE: {
        this.setContentData(new ResourceDAO().toObject(input.contentData));
        break;
      }
      case ContentTypes.DODONOT: {
        this.setContentData(new DoDoNotDAO().toObject(input.contentData));
        break;
      }
      case ContentTypes.COMMONQA: {
        this.setContentData(new CommonQADAO().toObject(input.contentData));
        break;
      }
      case ContentTypes.DECISIONMATRIX:{
        this.setContentData(new DecisionMatrixDAO().toObject(input.contentData));
        break;
      }
      default: {
        throw new Error("Unsupported Content Type: " + this.contentType);
      }
    }

    return this;
  }
}

export default ContentDAO;
