import React from "react";
import {
  Typography,
  Grid,
  IconButton
} from "@material-ui/core";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";;;


  export  default function CustomPaginationComponent(props){
  const {
    page,
    rowsPerPage,
    count,
    from,
    to,
    onChangePage,
    rowsPerPageOptions,
    onChangeRowsPerPage
  } = props;


  const stylingPagination={
    float:"right"
  }

  const disabling="disabled";
  const action="action";

  return (
    <td style={stylingPagination} >
      <Grid container alignItems="center" style={{ paddingTop: 8 }}>
        <Grid item>
          <FormControl>
            <Select
              disableUnderline={true} 
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rowsPerPage}
              onChange={onChangeRowsPerPage}
            >
              {rowsPerPageOptions.map((x, i) => (
                <MenuItem  value={x} key={i}>
                  {x} rows
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <IconButton
            disabled={page === 1}
            onClick={(e) => onChangePage(e, 1)}
          >
            <FirstPage
              fontSize="small"
              color={page === 1 ? disabling : action}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            disabled={page === 1}
            onClick={(e) => onChangePage(e, page - 1)}
          >
            <ChevronLeft
              fontSize="small"
              color={page === 1 ? disabling : action}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="caption" style={{ color: "black" }}>
            {from}-{to} of {count}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            disabled={to >= count}
            onClick={(e) => onChangePage(e, count-to<rowsPerPage?Math.ceil(count/rowsPerPage):page + 1)}
          >
            <ChevronRight
              fontSize="small"
              color={to < count ? action : disabling}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            disabled={to >= count}
            onClick={(e) => onChangePage(e,Math.ceil(count/rowsPerPage))}
          >
            <LastPage
              fontSize="small"
              color={to >= count ? disabling : action}
            />
          </IconButton>
        </Grid>
      </Grid>
    </td>
  );
};
