import ContentDAO from "../models/content/ContentDAO";
import apiService from "./apiService";
import * as WTRWEndpoints from "./wtrw.api";
const { COMMON_QA_ENDPOINT, RISK_AREA_ENDPOINT } = WTRWEndpoints;

class CommonQAService {
  static WorkDTO(contents) {
    return contents.map(content => new ContentDAO().toObject(content));
  }

  static async getCommonQAs(languageCode) {
    const response = await apiService.get(`${COMMON_QA_ENDPOINT}&languageCode=${languageCode}`);
    const { data } = response;
    return this.WorkDTO(data);
  }

  static async updateCommonQA(content) {
    const response = await apiService.post(COMMON_QA_ENDPOINT, content);
    return response;
  }

  static async deleteCommonQA({ riskArea, contentType, identifier, languageCode }) {
    const endpointSuffix = `/${riskArea}/${contentType}/${identifier}/${languageCode}`;
    const response = await apiService.delete(RISK_AREA_ENDPOINT + endpointSuffix);
    return response;
  }
}

export default CommonQAService;
