import  MatrixSectionDAO from './MatrixSectionDAO';
import MatrixDAO from './MatrixDAO';

class DecisionMatrixDAO{

    constructor(obj) {
        obj && Object.assign(this, obj);
      }

      getRiskArea() {
        return this.riskArea;
      }
    
      setRiskArea(val: string) {
        this.riskArea = val;
      }

      getBusinessArea() {
        return this.businessArea;
      }
    
      setBusinessArea(val: string) {
        this.businessArea = val;
      }    


    setIconKey(iconKey){
        this.iconKey = iconKey;
    }

    getIconKey(){
        return this.iconKey;
    }

    setRiskAreaIconKey(riskAreaIconKey: string) {
        this.riskAreaIconKey = riskAreaIconKey;
      }
    
      getRiskAreaIconKey() {
        return this.riskAreaIconKey;
      }

    setTitle(title){
        this.title = title;
    }

    getTitle(){
        return this.title;
    }

    setSections(sections){
        this.sections = sections;
    }

    getSections(){
        return this.sections;
    }

    getContentType() {
        return this.contentType;
      }

      getContentData() {
        return this.contentData;
      }
    
      setContentData(val: Object) {
        this.contentData = val;
      }

    setMatrix(matrix){
        this.matrix = matrix;
    }

    getMatrix(){
        return this.matrix;
    }

    setLevel(level){
        this.level = level;
    }

    getLevel(){
        return this.level;
    }

    setLevelOrder(levelOrder){
        this.levelOrder = levelOrder;
    }

    getLevelOrder(){
        return this.levelOrder;
    }

    toObject(input){
        this.setIconKey(input.iconKey);
        this.setTitle(input.title);
        let sections = [];
        let matrix =[];
        input.matrix=[];
        if(input.sections){
            input.sections.forEach((section) =>{
                sections.push(new MatrixSectionDAO().toObject(section));
                 if(section.order===0){
                  input.matrix.push({"no":0,"yes":1,"section":section.order});
                }
                else if(section.order===1){
                  input.matrix.push({"no":section.order+2,"yes":2,"section":section.order});
                }
                else{
                  input.matrix.push({"no":section.order+1,"yes":2,"section":section.order});
                }
            })
        }
        this.setSections(sections);
        if(input.matrix){
            input.matrix.forEach(item =>{
                matrix.push(new MatrixDAO().toObject(item));
            })        
        }
        this.setMatrix(matrix);
        this.setLevel(input.level);
        this.setLevelOrder(input.levelOrder);
        return this;
    }

}

export default DecisionMatrixDAO;