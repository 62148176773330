import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table-jspdf-fix";
import React, { forwardRef, useEffect, useState, useRef } from "react";
import UserService from "../data/api/userService";
import UserDAO from "../data/models/user/UserDAO";
import CustomPagination from "./CustomPagination";
import CustomSearchBar from "./customSearchBar";
import Swal from "sweetalert2";
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server'
import errorNotify from "./responseNotifyHelper";
const StyledHeader = styled.header`
height: 40px;
width: 99%;

text-align: center;
padding: 5px 8px;

font-family: "Soleil";
font-size: 0.9em;
`;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function UsersTable() {
  const [users, setUsers] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageno, Setpageno] = useState(1);
  const [ipp, Setipp] = useState(5);
  const [search, setSearch] = useState("");
  const [from, setfrom] = useState(1);
  const [to, setto] = useState(ipp);
  const [SearchTableDisplay, setSearchTableDisplay] = useState(false);
  const CustomTitle = () => <StyledHeader>Invalid Search Input</StyledHeader>;
  const [opt, setOpt] = useState({
    grouping: true,
    search: false,
    pageSize: 5

  })

  // eslint-disable-next-line
  const [searchingCss, setSearchingData] = useState({
    grouping: true,
    search: false,
    pageSize: 5

  })
  const tableRef = useRef(null);
  const tableRef2 = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUsers(pageno, ipp);
    tableRef.current.dataManager.changePageSize(ipp);
  }, [pageno, ipp]);

  const getUsers = (pageno, ipp) => {
    try {
      UserService.getAllUsers(pageno, ipp).then(users => {
        setUsers([...users.u]);
        if (users.u.length > 0) {
          setIsLoading(false);
        }
        setTotalItems(users.totalItems);
      });
    } catch (error) {
      errorNotify(error);
    }
    setIsLoading(false);

  }

  const rowUpdate = (newData, oldData) => {
    const tempUser = new UserDAO(newData);
    const oldUser = users.find(o => o.userId === tempUser.getUserId());
    oldUser.userData.setPermissionLevel(tempUser.userData.permissionLevel);
    delete newData.userData.fullname;
    UserService.updateUser(newData).then(result => {
      if (search.length < 3) {
        getUsers(pageno, ipp);
      } else {
        searchHandler(search);
      }
    })
      .catch((err) => {
        errorNotify(err);
      });
  };

  const onChangePage = (event, page) => {
    if (pageno < page) {
      if (page === Math.ceil(totalItems / ipp)) {
        //last right arrow clcked
        Setpageno(page);
        const cto = to > ipp ? ipp : to;
        setfrom((page - 1) * cto + 1);
        setto((page - 1) * cto + ipp);
      } else {
        //clicked right aerrow
        Setpageno(page);
        setfrom(to + 1);
        setto(to + ipp);
      }
    } else {
      if (page === 1) {
        Setpageno(page);
        setfrom(1);
        setto(ipp);
      } else {
        //clicked left aerrow
        Setpageno(page);
        setfrom(from - ipp);
        setto(from - 1);
      }
    }
  };

  const onChangeRowsPerPage = (event, value) => {
    Setipp(value.props.value);
    if (ipp > value.props.value) {
      if (to < totalItems) {
        Setpageno(pageno);
        setto(pageno * value.props.value);
        setfrom(pageno * value.props.value - value.props.value + 1);
      } else {
        Setpageno(Math.ceil(totalItems / value.props.value));
        setfrom(
          value.props.value * Math.ceil(totalItems / value.props.value) - value.props.value + 1
        );
        setto(value.props.value * Math.ceil(totalItems / value.props.value));
      }
    } else {
      if (to + value.props.value - ipp < totalItems) {
        setto(pageno * value.props.value);
        setfrom(pageno * value.props.value - value.props.value + 1);
      } else {
        Setpageno(Math.ceil(totalItems / value.props.value));
        setfrom(
          value.props.value * Math.ceil(totalItems / value.props.value) - value.props.value + 1
        );
        setto(value.props.value * Math.ceil(totalItems / value.props.value));
      }
    }
    setOpt({
      grouping: true,
      search: false,
      pageSize: value.props.value
    });
  };

  const cancelHandler = () => {
    try {
      UserService.getAllUsers(pageno, ipp).then(users => {
        setUsers([...users.u]);
        setTotalItems(users.totalItems);
      });
      setSearch("");
      setSearchTableDisplay(false);
    } catch (error) {
      errorNotify(error);
    }
  }

  const Changehandler = (newval) => {
    setSearch(newval);
    if (newval.length === 0) {
      cancelHandler();
    }
    else {
      searchHandler(newval);
    }
  }

  const getSearch = (val) => {
    if (!isValidSearchInput(val)) {
      Swal.fire({
        title: '<div>' + ReactDOMServer.renderToString(<CustomTitle />) + '</div>',
        text: "Please do not use special characters in search",
        confirmButtonColor: "black",
        confirmButtonText: "<span style='color:#FFCC00;'>OK<span>",
        allowOutsideClick: false,
      }).then(() => {
        setSearch("");
        setSearchTableDisplay(false);
      })
    }
    else {
      UserService.getAllUsers(undefined, undefined, val).then(users => {
        setUsers(users.u);
      });
    }
  }
  const isValidSearchInput = val => {
    return /^[a-zA-Z0-9]{3}[^\s]*$/.test(val);
  };
  const searchHandler = (val) => {
    if (val.length >= 3) {
      setSearchTableDisplay(true);
      getSearch(val);
    }
  }

  const searchStyling = {
    float: 'right',
    height: '30px',
    position: 'relative',
    zIndex: '1',
    borderBottom: '1px solid black',
    boxShadow: 'none',
    marginTop: '12px',
    borderRadius: '0px',
    marginRight: '15px'
  }

  return (
    <>
      <CustomSearchBar
        style={searchStyling}
        value={search}
        changehandler={(newval) => Changehandler(newval)}
        cancelHandler={() => cancelHandler()}
      />
      <>
      </>
      {!SearchTableDisplay ? (
        <MaterialTable
          title="Manage User Access"
          icons={tableIcons}
          isLoading={isLoading}
          tableRef={tableRef}
          columns={[
            { title: "CatRecID", field: "userData.catrecid", editable: "never" },
            { title: "Name", field: "userData.fullname", editable: "never" },
            { title: "Email", field: "userData.catbusinessmail", editable: "never" },
            { title: "Division", field: "userData.division", editable: "never" },
            {
              title: "Access Level",
              field: "userData.permissionLevel",
              editComponent: props => (
                <Select value={props.value} onChange={e => props.onChange(e.target.value)}>
                  <MenuItem value={"Basic"}>Basic</MenuItem>
                  <MenuItem value={"Advisor"}>Advisor</MenuItem>
                  <MenuItem value={"QuestionAdmin"}>QuestionAdmin</MenuItem>
                  <MenuItem value={"ContentAdmin"}>ContentAdmin</MenuItem>
                  <MenuItem value={"FullAdmin"}>FullAdmin</MenuItem>
                </Select>
              )
            }
          ]}
          data={users}
          options={opt}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  rowUpdate(newData, oldData);
                  resolve();
                }, 600);
              })
          }}
          components={{
            Pagination: (props) =>
              <CustomPagination
                from={from}
                to={to}
                setFrom={setfrom}
                setto={setto}
                page={pageno}
                rowsPerPage={ipp}
                count={totalItems}
                onChangePage={(event, page) => onChangePage(event, page)}
                rowsPerPageOptions={[5, 10, 15]}
                onChangeRowsPerPage={(event, page) => onChangeRowsPerPage(event, page)}
              />
          }}
        />) : (
        <>
          <MaterialTable
            title="Manage User Access"
            icons={tableIcons}
            isLoading={isLoading}
            tableRef={tableRef2}
            columns={[
              { title: "CatRecID", field: "userData.catrecid", editable: "never" },
              { title: "Name", field: "userData.fullname", editable: "never" },
              { title: "Email", field: "userData.catbusinessmail", editable: "never" },
              { title: "Division", field: "userData.division", editable: "never" },
              {
                title: "Access Level",
                field: "userData.permissionLevel",
                editComponent: props => (
                  <Select value={props.value} onChange={e => props.onChange(e.target.value)}>
                    <MenuItem value={"Basic"}>Basic</MenuItem>
                    <MenuItem value={"Advisor"}>Advisor</MenuItem>
                    <MenuItem value={"QuestionAdmin"}>QuestionAdmin</MenuItem>
                    <MenuItem value={"ContentAdmin"}>ContentAdmin</MenuItem>
                    <MenuItem value={"FullAdmin"}>FullAdmin</MenuItem>
                  </Select>
                )
              }
            ]}

            data={users}
            options={searchingCss}

            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    rowUpdate(newData, oldData);
                    resolve();
                  }, 600);
                })
            }}

          />
        </>
      )
      }
    </>
  );
}

export default UsersTable;