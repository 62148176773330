import ContentDAO from "../models/content/ContentDAO";
import apiService from "./apiService";
import * as WTRWEndpoints from "./wtrw.api";

class DecisionMatrixModuleService {
  static DMModuleDTO(DMModules) {
    return DMModules.map(module => new ContentDAO().toObject(module));
  }

  static async getDMModules(languageCode) {
    const response = await apiService.get(`${WTRWEndpoints.DECISION_MATRIX_ENDPOINT}&languageCode=${languageCode}`);
    const { data } = response;
    return this.DMModuleDTO(data);
  }

  static async getRiskAreas(languageCode) {
    const response = await apiService.get(`${WTRWEndpoints.RISK_AREAS_ENDPOINT}&languageCode=${languageCode}`);
    const { data } = response;
    return this.DMModuleDTO(data);
  }

  static async updateDMModule(module) {
    const response = await apiService.post(WTRWEndpoints.DECISION_MATRIX_ENDPOINT, module);
    return response;
  }

  static async deleteDMModule({ riskArea, contentType, identifier, languageCode }) {
    const endpointSuffix = `/${riskArea}/${contentType}/${identifier}/${languageCode}`;
    const response = await apiService.delete(WTRWEndpoints.RISK_AREA_ENDPOINT + endpointSuffix);
    return response;
  }

  static async deleteRiskArea({ riskArea, contentType, identifier, languageCode }) {
    const endpointSuffix = `/${riskArea}/${contentType}/${identifier}/${languageCode}`;
    const response = await apiService.delete(WTRWEndpoints.RISK_AREA_ENDPOINT + endpointSuffix);
    return response;
  } 
}

export default DecisionMatrixModuleService;
