import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import * as Excel from "exceljs/dist/exceljs";
import { saveAs } from "file-saver";
import MaterialTable from "material-table-jspdf-fix";
import React, { forwardRef, Fragment, useCallback, useEffect, useState } from "react";

import ContactService from "../data/api/contactService";
import DivisionDAO from "../data/models/division/DivisionDAO";
import errorNotify from "./responseNotifyHelper";
import { jsPDF } from "jspdf";

const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5;

function autoSize(sheet, fromRow) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return;
  }

  const maxColumnLengths = [];
  sheet.eachRow((row, rowNum) => {
    if (rowNum < fromRow) {
      return;
    }

    row.eachCell((cell, num) => {
      if (typeof cell.value === "string") {
        if (maxColumnLengths[num] === undefined) {
          maxColumnLengths[num] = 0;
        }

        const fontSize = cell.font && cell.font.size ? cell.font.size : 11;
        ctx.font = `${fontSize}pt Arial`;
        const metrics = ctx.measureText(cell.value);
        const cellWidth = metrics.width;

        maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth);
      }
    });
  });

  for (let i = 1; i <= sheet.columnCount; i++) {
    const col = sheet.getColumn(i);
    const width = maxColumnLengths[i];
    if (width) {
      col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1;
    }
  }
}

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const groupContactDetails = contacts => {
  return contacts
    .map(response => {
      const { divisionValue, divisionCode, dcmContacts, tpiContacts, leadCounsels } = response;
      const division = divisionValue;
      const divCode = divisionCode;
      const _dcmContacts = dcmContacts.map(contact => ({
        ...contact,
        division,
        divCode,
        name: contact.fullName,
        type: "DCM Contact"
      }));
      const _tpiContacts = tpiContacts.map(contact => ({
        ...contact,
        division,
        divCode,
        name: contact.fullName,
        type: "TPI Contact"
      }));
      const _leadCounsels = leadCounsels.map(contact => ({
        ...contact,
        division,
        divCode,
        name: contact.fullName,
        type: "Lead Counsel"
      }));
      return [..._dcmContacts, ..._tpiContacts, ..._leadCounsels];
    })
    .flat();
};

const ungroupContactDetails = data => {
  const ungroupObject = {};
  data.forEach(contact => {
    const contactClone = { ...contact };
    const { divCode, division, type, name } = contactClone;
    contactClone.fullName = name;
    const current = ungroupObject[divCode] || {
      dcmContacts: [],
      tpiContacts: [],
      leadCounsels: []
    };
    if (!current.divisionValue) current.divisionValue = division;
    if (!current.divisionCode) current.divisionCode = divCode;
    delete contactClone.tableData;
    delete contactClone.type;
    delete contactClone.division;
    delete contactClone.divCode;
    delete contactClone.name;
    if (type === "DCM Contact") current.dcmContacts.push(contactClone);
    if (type === "TPI Contact") current.tpiContacts.push(contactClone);
    if (type === "Lead Counsel") current.leadCounsels.push(contactClone);
    ungroupObject[divCode] = current;
  });
  const divisionCodes = [];
  for (const key in ungroupObject) {
    divisionCodes.push(ungroupObject[key]);
  }
  return divisionCodes;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    wordWrap: true
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  menu: {
    width: 400,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  fab: {
    margin: theme.spacing(2),
    color: "#FFCC00",
    background: "black"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: 200
  },
  root: {
    maxWidth: 345
  },
  keywordContainer: {
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  },
  content: {
    flex: "1 0 auto"
  },
  textFieldOutlined: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250
  },
  contactTypeTitle: {
    margin: theme.spacing(4, 0, 2)
  },
  addContactFab: {
    margin: 5,
    paddingRight: 15,
    color: "#FFCC00",
    background: "#373A3D"
  }
}));

function Contacts() {
  const classes = useStyles();
  const [rawContacts, setRawContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [divisionCodesList, setDivisionCodesList] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [currentContact, setCurrentContact] = useState(new DivisionDAO().toObject({}));
  const [currentContactIndex, setCurrentContactIndex] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [divisionModalVisible, setDivisionsModalVisible] = useState(false);
  const [submitEnable, setSubmitEnable] = useState(false);
  const [nameValue, setNameValue] = useState(false);
  const [emailValue, setEmailValue] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [secondNameValue, setSecondNameValue] = useState(false);
  const [SecondEmailValue, setSecondEmailValue] = useState(false);
  const [secondFullNameError, setSecondFullNameError] = useState("");
  const [secondEmailError, setSecondEmailError] = useState("");
  const [thirdNameValue, setThirdNameValue] = useState(false);
  const [thirdEmailValue, setThirdEmailValue] = useState(false);
  const [thirdFullNameError, setThirdFullNameError] = useState("");
  const [thirdEmailError, setThirdEmailError] = useState("");
  const [isDivisionCodeValid, setIsDivisionCodeValid] = useState(true);
  const [isInputTouched, setIsInputTouched] = useState(false);
  
  const getContacts = useCallback(() => {
    try {
      ContactService.getContactDetails()
        .then(contacts => {
          setRawContacts(contacts);
          setDivisionCodesList(
            contacts.map(({ divisionCode, divisionValue }, index) => ({
              value: divisionCode,
              divisionName: divisionValue,
              index
            }))
          );
          setContacts(groupContactDetails(contacts));
          setIsLoading(false);
        })
        .catch(error => {
          errorNotify(error);
        });
      setIsLoading(false);
    } catch (error) {
      errorNotify(error);
    }
    setIsLoading(false);
  }, []);
  const resetCurrentContact = useCallback(() => {
    const initObject = {
      divisionCode: "",
      divisionValue: ""
    };
    const initContactDAO = new DivisionDAO().toObject(initObject);
    setCurrentContact(initContactDAO);
    setCurrentContactIndex(null);
  }, []);

  const materialTableConfig = {
    columns: [
      { title: "Contact Type", field: "type", defaultGroupOrder: 1 },
      { title: "Division", field: "division", defaultGroupOrder: 0 },
      { title: "Division Code", field: "divCode" },
      { title: "Name", field: "name" },
      { title: "Email", field: "email" },
      { title: "Phone", field: "phone" }
    ],
    options: {
      grouping: true,
      exportButton: true,
      exportAllData: true,
      exportCsv: async () => {
        setIsLoading(true);
        const wb = new Excel.Workbook();
        const ws = wb.addWorksheet();
        const row = ws.addRow([
          "Contact Type",
          "Division",
          "Division Code",
          "Name",
          "Email",
          "Phone"
        ]);
        row.font = { bold: true };

        contacts.forEach(contact => {
          const { type, division, divCode, fullName, email, phone } = contact;
          ws.addRow([type, division, divCode, fullName, email, phone]);
        });

        autoSize(ws, 0);

        const buf = await wb.xlsx.writeBuffer();
        setIsLoading(false);
        saveAs(new Blob([buf]), "contacts.xlsx");
      },
      exportPdf: async () => {
        setIsLoading(true);
        const doc = new jsPDF();
        const headers = [["Contact Type", "Division", "Division Code", "Name", "Email", "Phone"]];
        const data = contacts.map(({ type, division, divCode, fullName: name, email, phone }) => [
          type,
          division,
          divCode,
          name,
          email,
          phone
        ]);
        doc.autoTable({
          head: headers,
          body: data
        });
        setIsLoading(false);
        doc.save("contacts.pdf");
      }
    }
  };

  useEffect(() => {
    getContacts();
    resetCurrentContact();
  }, [getContacts, resetCurrentContact]);

  const handleAddContact = useCallback(() => {
    resetCurrentContact();
    setModalVisible(true);
  }, [resetCurrentContact]);

  useEffect(() => {
    setIsInputTouched(currentContact.getDivisionCode() && currentContact.getDivisionValue());
  }, [currentContact]);

  const handleOpenDivisions = useCallback(() => {
    resetCurrentContact();
    setDivisionsModalVisible(true);
  }, [resetCurrentContact]);

  const handleSubmitContact = async () => {
    try {
      const rawContactsClone = [...rawContacts];
      if (modalVisible) setModalVisible(false);
      if (divisionModalVisible) setDivisionsModalVisible(false);
      setIsLoading(true);
      if (currentContactIndex === null) {
        rawContactsClone.push(currentContact);
        await ContactService.updateContactDetails(rawContactsClone);
      } else {
        rawContactsClone[currentContactIndex] = currentContact;
        await ContactService.updateContactDetails(rawContactsClone);
      }
      setRawContacts(rawContactsClone);
      setContacts(groupContactDetails(rawContactsClone));
      setDivisionCodesList(
        rawContactsClone.map(({ divisionCode, divisionValue }, index) => ({
          value: divisionCode,
          divisionName: divisionValue,
          index
        }))
      );
      setIsLoading(false);
    } catch (error) {
      errorNotify(error);
    }
    setIsLoading(false);
  };

  const handleDeleteDivision = async ({ value, index }) => {
    try {
      const rawContactsClone = [...rawContacts];
      rawContactsClone.splice(index, 1);
      setRawContacts(rawContactsClone);
      setContacts(groupContactDetails(rawContactsClone));
      setDivisionCodesList(
        rawContactsClone.map(({ divisionCode, divisionValue }, index) => ({
          value: divisionCode,
          divisionName: divisionValue,
          index
        }))
      );
      await ContactService.deleteDivision(value);
    } catch (error) {
      errorNotify(error);
    }
    setIsLoading(false);
  };

  const updateCurrentContact = () => {
    const updatedCurrentContact = new DivisionDAO().toObject(currentContact);
    setCurrentContact(updatedCurrentContact);
  };

  const validateEmail = email => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/.test(
      email
    );
  };

  const validateString = str => {
    return str.match(/^[a-z A-Z]+$/);
  };

  function SubmitButton() {
    if (
      emailValue &&
      nameValue &&
      secondNameValue &&
      SecondEmailValue &&
      thirdNameValue &&
      thirdEmailValue &&
      submitEnable
    ) {
      return (
        <Button onClick={handleSubmitContact} color="primary">
          Submit
        </Button>
      );
    } else if (
      !emailValue &&
      !nameValue &&
      !secondNameValue &&
      !SecondEmailValue &&
      !thirdNameValue &&
      !thirdEmailValue &&
      !submitEnable
    ) {
      if (validateDivisionArr()) {
        setEmailValue(true);
        setNameValue(true);
        setSecondNameValue(true);
        setSecondEmailValue(true);
        setThirdNameValue(true);
        setThirdEmailValue(true);
        setSubmitEnable(true);
        return (
          <Button onClick={handleSubmitContact} color="primary">
            Submit
          </Button>
        );
      } else {
        return (
          <Button onClick={handleSubmitContact} color="primary" disabled>
            Submit
          </Button>
        );
      }
    } else {
      return (
        <Button onClick={handleSubmitContact} color="primary" disabled>
          Submit
        </Button>
      );
    }
  }

  function validateDivisionArr() {
    if (currentContact.getDivisionCode() !== "") {
      return true;
    } else {
      return false;
    }
  }

  const handleFullNameKeyDown = event => {
    if (event.key === "Backspace") {
      event.preventDefault();
      const currentFullName = event.target.value;
      const updateFullName = currentFullName.slice(0, -1);
      if (event.target.id === "outlined-dcm-contact-fullname") {
        currentContact.dcmContacts[0].setFullName(updateFullName);
        if (!updateFullName || (updateFullName && updateFullName.length <= 2)) {
          setFullNameError("Enter Valid Full name");
          setSubmitEnable(false);
          setNameValue(false);
        } else {
          setFullNameError("");
          setNameValue(true);
          setSubmitEnable(true);
        }
        updateCurrentContact();
      } else if (event.target.id === "outlined-tpi-contact-fullname") {
        currentContact.tpiContacts[0].setFullName(updateFullName);
        if (!updateFullName || (updateFullName && updateFullName.length <= 2)) {
          setSecondFullNameError("Enter Valid Full name");
          setSubmitEnable(false);
          setSecondNameValue(false);
        } else {
          setFullNameError("");
          setSecondNameValue(true);
          setSubmitEnable(true);
        }
        updateCurrentContact();
      } else if (event.target.id === "outlined-leadcounsels-contact-fullname") {
        currentContact.leadCounsels[0].setFullName(updateFullName);
        if (!updateFullName || (updateFullName && updateFullName.length <= 2)) {
          setThirdFullNameError("Enter Valid Full name");
          setSubmitEnable(false);
          setThirdNameValue(false);
        } else {
          setThirdFullNameError("");
          setThirdNameValue(true);
          setSubmitEnable(true);
        }
        updateCurrentContact();
      }
    }
  };

  const handleEmailKeyDown = event => {
    if (event.key === "Backspace") {
      event.preventDefault();
      const currentEmail = event.target.value;
      const updateEmail = currentEmail.slice(0, -1);
      if (event.target.id === "outlined-dcm-contact-email") {
        currentContact.dcmContacts[0].setEmail(updateEmail);
        if (!updateEmail || (updateEmail && !validateEmail(updateEmail))) {
          setEmailError("Enter Valid Email ID");
          setSubmitEnable(false);
          setEmailValue(false);
        } else {
          setEmailError("");
          setEmailValue(true);
          setSubmitEnable(true);
        }
        updateCurrentContact();
      } else if (event.target.id === "outlined-tpi-contact-email") {
        currentContact.tpiContacts[0].setEmail(updateEmail);
        if (!updateEmail || (updateEmail && !validateEmail(updateEmail))) {          
          setSecondEmailError("Enter Valid Email ID");
          setSubmitEnable(false);
          setSecondEmailValue(false);
        } else {
          setSecondEmailError("");
          setSecondEmailValue(true);
          setSubmitEnable(true);
        }
        updateCurrentContact();
      } else if (event.target.id === "outlined-leadcounsels-contact-email") {
        currentContact.leadCounsels[0].setEmail(updateEmail);
        if (!updateEmail || (updateEmail && !validateEmail(updateEmail))) {          
          setThirdEmailError("Enter Valid Email ID");
          setSubmitEnable(false);
          setThirdEmailValue(false);
        } else {
          setThirdEmailError("");
          setThirdEmailValue(true);
          setSubmitEnable(true);
        }
        updateCurrentContact();
      }
    }
  };

  const handleExistingContactControl = divisionCode => {
    rawContacts.some((rawContact, index) => {
      if (rawContact.getDivisionCode() === divisionCode) {
        const rawContactClone = new DivisionDAO().toObject(rawContact);
        setCurrentContact(rawContactClone);
        setCurrentContactIndex(index);
        return true;
      }
      if (index === rawContacts.length - 1) {
        const resetContactClone = new DivisionDAO().toObject({ divisionCode });
        setCurrentContact(resetContactClone);
        setCurrentContactIndex(null);
        return true;
      }
    });
  };

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>
        <Fab
          variant="extended"
          aria-label="publish"
          className={classes.addContactFab}
          disabled={isLoading}
          onClick={handleOpenDivisions}
        >
          Divisions
        </Fab>
        <Fab
          variant="extended"
          aria-label="publish"
          className={classes.addContactFab}
          disabled={isLoading}
          onClick={handleAddContact}
        >
          Add Contact
        </Fab>
      </div>
      <MaterialTable
        title="Manage Contacts"
        icons={tableIcons}
        columns={materialTableConfig.columns}
        data={contacts}
        isLoading={isLoading}
        options={materialTableConfig.options}
        editable={{
          onRowUpdate: (newData, oldData) => {
            const contactsClone = [...contacts];
            contactsClone[contacts.indexOf(oldData)] = newData;
            setContacts(contactsClone);
            const ungroupedContacts = ungroupContactDetails(contactsClone);
            return ContactService.updateContactDetails(ungroupedContacts);
          },
          onRowDelete: oldData => {
            const contactsClone = [...contacts];
            const deleteIndex = contacts.indexOf(oldData);
            contactsClone.splice(deleteIndex, 1);
            setContacts(contactsClone);
            const ungroupedContacts = ungroupContactDetails(contactsClone);
            return ContactService.updateContactDetails(ungroupedContacts);
          }
        }}
      />
      <Dialog
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">Add Contact</DialogTitle>
        <DialogContent>
          <FormGroup className={classes.container} noValidate autoComplete="off">
            <TextField
              key="newContactDivisionCode"
              id="newContactDivisionCode-Contact"
              label="Division Code"
              select
              className={classes.textField}
              value={currentContact.getDivisionCode()}
              onChange={event => {
                const { value } = event.target;
                currentContact.setDivisionCode(value);
                updateCurrentContact();
                handleExistingContactControl(currentContact.getDivisionCode());
              }}
              margin="normal"
            >
              {divisionCodesList.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              key="newContactDivisionValue"
              id="newContactDivisionValue-Contact"
              label="Division Value"
              className={classes.textField}
              value={currentContact.getDivisionValue()}
              disabled
              onChange={event => {
                const { value } = event.target;
                currentContact.setDivisionValue(value);
                updateCurrentContact();
              }}
              margin="normal"
            ></TextField>
          </FormGroup>
          <Typography variant="subtitle1" className={classes.contactTypeTitle}>
            DCM Contact
          </Typography>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Full Name"
            id="outlined-dcm-contact-fullname"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.dcmContacts[0].getFullName()}
            // First DCM FULL NAME Start
            onChange={event => {
              const { value } = event.target;
              if (!value.trim()) {
                setFullNameError("Full name is required");
              } else if (!validateString(value) || (value && value.length <= 2)) {
                setFullNameError("Please Enter valid Full name");
                currentContact.dcmContacts[0].setFullName(value);
                setSubmitEnable(false);
                updateCurrentContact();
              } else {
                setFullNameError("");
                currentContact.dcmContacts[0].setFullName(value);
                setNameValue(true);
                setSubmitEnable(true);
                updateCurrentContact();
              }
            }}
            // First DCM FULL NAME BACK Space logic Start
            onKeyDown={event => handleFullNameKeyDown(event)}
            helperText={fullNameError}
            error={Boolean(fullNameError)}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Email"
            id="outlined-dcm-contact-email"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.dcmContacts[0].getEmail()}
            onChange={event => {
              const { value } = event.target;
              if (!value.trim()) {
                setEmailError("Email is required");
              } else if (!validateEmail(value)) {
                setEmailError("Please Enter valid email address");
                currentContact.dcmContacts[0].setEmail(value);
                setSubmitEnable(false);
                updateCurrentContact();
              } else {
                setEmailError("");
                currentContact.dcmContacts[0].setEmail(value);
                if (validateEmail(value)) {
                  setEmailValue(true);
                  setSubmitEnable(true);
                } else {
                  setEmailValue(false);
                  setSubmitEnable(false);
                }
                updateCurrentContact();
              }
            }}
            onKeyDown={event => handleEmailKeyDown(event)}
            helperText={emailError}
            error={Boolean(emailError)}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Phone"
            id="outlined-dcm-contact-phone"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.dcmContacts[0].getPhone()}
            onChange={event => {
              const { value } = event.target;
              currentContact.dcmContacts[0].setPhone(value);
              updateCurrentContact();
            }}
          />
          <Typography variant="subtitle1" className={classes.contactTypeTitle}>
            TPI Contact
          </Typography>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Full Name"
            id="outlined-tpi-contact-fullname"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.tpiContacts[0].getFullName()}
            onChange={event => {
              // First tpiContacts FULL NAME Start
              const { value } = event.target;
              if (!value.trim()) {
                setSecondFullNameError("Full name is required");
              } else if (!validateString(value) || (value && value.length <= 2)) {
                setSecondFullNameError("Please Enter valid Full name");
                currentContact.tpiContacts[0].setFullName(value);
                setSubmitEnable(false);
                updateCurrentContact();
              } else {
                setSecondFullNameError("");
                currentContact.tpiContacts[0].setFullName(value);
                setSecondNameValue(true);
                setSubmitEnable(true);
                updateCurrentContact();
              }
            }}
            // First tpiContacts FULL NAME BACK Space logic Start
            onKeyDown={event => handleFullNameKeyDown(event)}
            helperText={secondFullNameError}
            error={Boolean(secondFullNameError)}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Email"
            id="outlined-tpi-contact-email"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.tpiContacts[0].getEmail()}
            onChange={event => {
              const { value } = event.target;
              if (!value.trim()) {
                setSecondEmailError("Email is required");
              } else if (!validateEmail(value)) {
                setSecondEmailError("Please Enter valid email address");
                currentContact.tpiContacts[0].setEmail(value);
                setSubmitEnable(false);
                updateCurrentContact();
              } else {
                setSecondEmailError("");
                currentContact.tpiContacts[0].setEmail(value);
                if (validateEmail(value)) {
                  setSecondEmailValue(true);
                  setSubmitEnable(true);
                } else {
                  setSecondEmailValue(false);
                  setSubmitEnable(false);
                }
                updateCurrentContact();
              }
            }}
            onKeyDown={event => handleEmailKeyDown(event)}
            helperText={secondEmailError}
            error={Boolean(secondEmailError)}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Phone"
            id="outlined-tpi-contact-phone"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.tpiContacts[0].getPhone()}
            onChange={event => {
              const { value } = event.target;
              currentContact.tpiContacts[0].setPhone(value);
              updateCurrentContact();
            }}
          />
          <Typography variant="subtitle1" className={classes.contactTypeTitle}>
            Lead Counsel Contact
          </Typography>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Full Name"
            id="outlined-leadcounsels-contact-fullname"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.leadCounsels[0].getFullName()}
            onChange={event => {
              const { value } = event.target;
              if (!value.trim()) {
                setThirdFullNameError("Full name is required");
              } else if (!validateString(value) || (value && value.length <= 2)) {
                setThirdFullNameError("Please Enter valid Full name");
                currentContact.leadCounsels[0].setFullName(value);
                setSubmitEnable(false);
                updateCurrentContact();
              } else {
                setThirdFullNameError("");
                currentContact.leadCounsels[0].setFullName(value);
                setThirdNameValue(true);
                setSubmitEnable(true);
                updateCurrentContact();
              }
            }}
            onKeyDown={event => handleFullNameKeyDown(event)}
            helperText={thirdFullNameError}
            error={Boolean(thirdFullNameError)}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Email"
            id="outlined-leadcounsels-contact-email"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.leadCounsels[0].getEmail()}
            onChange={event => {
              const { value } = event.target;
              if (!value.trim()) {
                setThirdEmailError("Email is required");
              } else if (!validateEmail(value)) {
                setThirdEmailError("Please Enter valid email address");
                currentContact.leadCounsels[0].setEmail(value);
                setSubmitEnable(false);
                updateCurrentContact();
              } else {
                setThirdEmailError("");
                currentContact.leadCounsels[0].setEmail(value);
                if (validateEmail(value)) {
                  setThirdEmailValue(true);
                  setSubmitEnable(true);
                } else {
                  setThirdEmailValue(false);
                  setSubmitEnable(false);
                }
                updateCurrentContact();
              }
            }}
            onKeyDown={event => handleEmailKeyDown(event)}
            helperText={thirdEmailError}
            error={Boolean(thirdEmailError)}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Phone"
            id="outlined-leadcounsels-contact-phone"
            className={classes.textFieldOutlined}
            margin="normal"
            variant="outlined"
            value={currentContact.leadCounsels[0].getPhone()}
            onChange={event => {
              const { value } = event.target;
              currentContact.leadCounsels[0].setPhone(value);
              updateCurrentContact();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalVisible(false)} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleSubmitContact} color="primary" disabled={fullNameError!==''||emailError!==''?true:false}>
            Submit
          </Button> */}
          <SubmitButton />
        </DialogActions>
      </Dialog>
      <Dialog
        open={divisionModalVisible}
        onClose={() => setDivisionsModalVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">Divisions</DialogTitle>
        <DialogContent>
          <FormGroup className={classes.container} noValidate autoComplete="off">
            <TextField
              key="newContactDivisionCode"
              id="newContactDivisionCode-Contact"
              label="Division Code"
              className={classes.textField}
              value={currentContact.getDivisionCode()}
              onChange={event => {
                  const isNumber = event.target.value || "";
                  setIsDivisionCodeValid(!isNaN(isNumber));
                  currentContact.setDivisionCode(isNumber);
                  updateCurrentContact();
                  setIsInputTouched(
                    currentContact.getDivisionCode() && currentContact.getDivisionValue()
                  );              
              }}
              margin="normal"
              error={!isDivisionCodeValid}
              helperText={!isDivisionCodeValid ? "Division code should be a number" : ""}
            ></TextField>
            <TextField
              key="newContactDivisionValue"
              id="newContactDivisionValue-Contact"
              label="Division Value"
              className={classes.textField}
              value={currentContact.getDivisionValue()}
              onChange={event => {
                const { value } = event.target;
                currentContact.setDivisionValue(value);
                updateCurrentContact();
                setIsInputTouched(
                  currentContact.getDivisionCode() && currentContact.getDivisionValue()
                );
              }}
              margin="normal"
            ></TextField>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>
              <Fab
                variant="extended"
                aria-label="publish"
                disabled={!isDivisionCodeValid || !currentContact.getDivisionCode() || !isInputTouched}
                className={classes.addContactFab}
                onClick={handleSubmitContact}
              >
                {currentContactIndex !== null ? "Update Division" : "Add Division"}
              </Fab>
            </div>
          </FormGroup>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
              Divisions
            </Typography>
            <div className={classes.demo}>
              <List dense={true}>
                {divisionCodesList.map(({ value, divisionName, index }) => {
                  return (
                    <ListItem key={value}>
                      <ListItemText primary={divisionName} secondary={value} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="update"
                          onClick={() => handleExistingContactControl(value)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteDivision({ value, index })}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
        <Button
            onClick={() => {
              setDivisionsModalVisible(false),
                setIsInputTouched(false),
                setIsDivisionCodeValid(true);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default Contacts;
