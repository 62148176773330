// This is for the Manage Content - Description page
// It allows the web portal user to manage the "Code of Conduct"

import { makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 30,
    width: "80%"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    wordWrap: true
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 700
  },
  menu: {
    width: 400
  }
}));

function DescFields() {
  const newClasses = useStyles();

  return (
    <div classes={newClasses.root}>
      <br />
      <Typography variant="h6" noWrap>
        Code of Conduct Description
      </Typography>
      <TextField
        id="standard-multiline-desc"
        label="Code of Conduct"
        className={newClasses.textField}
        multiline
        margin="normal"
      />
    </div>
  );
}

export default DescFields;
