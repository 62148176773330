function define(name, value) {
  Object.defineProperty(exports, name, {
    value: value,
    enumerable: true
  });
}

define("DESCRIPTION", "Description");
define("MODULE", "Modules");
define("CEOMESSAGE", "CEOMessage");
define("SPOTLIGHT", "Spotlight");
define("RESOURCE", "Resource");
define("COMMONQA", "CommonQA");
define("DODONOT", "DoDoNot");
define("DECISIONMATRIX","DecisionMatrix");