import { action, createStore, StoreProvider } from "easy-peasy";
import React, { useEffect, useState } from "react";

import LocalStorageWrapper from "./modules/localStorage";
import OAuthService from "./modules/oauth";
import WebHome from "./routes/navigation";
import modulesStore from "./stores/modulesStore";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Download from '../src/views/download';

function App() {
  const [store, setStore] = useState();
  const [hasToken, setHasToken] = useState(false);
  const [checkedToken, setCheckedToken] = useState(false);

  const oauthClient = new OAuthService({
    grantType: "authorization_code",
    url: process.env.REACT_APP_OAUTH,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    scope: "openid profile",
    authEndpoint: "/as/authorization.oauth2",
    tokenEndpoint: "/as/token.oauth2",
    revocationEndpoint: "/as/revoke_token.oauth2",
    userInfoEndpoint: "/idp/userinfo.openid",
    adapter: "OAuthAdapterCCDS"
  });

  const getToken = () => {
    const cwsAuthCode = getUrlParam("code", "Empty");
    const urlState = getUrlParam("state", "Emtpy");
    setCheckedToken(true);
    if (cwsAuthCode !== "Empty" && cwsAuthCode !== undefined && urlState === "State") {
      setHasToken(true);
      oauthClient.exchangeAuthCode(cwsAuthCode).then(tokens => {
        LocalStorageWrapper.saveToken(tokens);
      });
    }
    return cwsAuthCode;
  };

  const getLoginPage = () => {
    window.location = oauthClient.getURL("State", oauthClient.getOAuthSettings().scope);
    return true;
  };

  const getHomePage = () => {
    return (
      <div>
        <WebHome />
      </div>
    );
  };

  useEffect(() => {
    if (getToken() !== "Empty") {
      const store = {
        accessLevel: {
          accessLevel: "Basic",
          changeAccessLevel: action((state, payload) => {
            state.accessLevel = payload;
          })
        },
        language: {
          language: "en",
          changeLanguage: action((state, payload) => {
            state.language = payload;
          })
        },
        isError: {
          isError: false,
          updateIsError: action((state, payload) => {
            state.isError = payload;
          })
        },
        oauth: oauthClient,
        modules: modulesStore
      };

      const s = createStore(store, {
        name: "Store"
      });

      setStore(s);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PrivateRoute=({token,children})=>{
    if(!token){
      return getLoginPage();
    }
    return children;
    }

  if (checkedToken) {
    return <StoreProvider store={store}>
      <Router> 
      <Switch>
      <Route path="/download"><Download /></Route>
      <Route path="/"><PrivateRoute token={hasToken}>{getHomePage()}</PrivateRoute></Route>
      </Switch>
      </Router>
      </StoreProvider>;
  }

  return <div style={{ backgroundColor: "#ffffff" }} />;
}

function getUrlVars() {
  const vars = {};
  const queryParams = new URLSearchParams(window.location.search);
  for (const queryParam of queryParams) {
    vars[queryParam[0]] = queryParam[1];
  }
  return vars;
}

function getUrlParam(parameter, defaultvalue) {
  let urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}

export default App;
