import ContactDAO from "./ContactDAO";

class DivisionDAO {

  setDivisionCode(divisionCode) {
    this.divisionCode = divisionCode;
  }

  getDivisionCode() {
    return this.divisionCode;
  }

  setDivisionValue(divisionValue) {
    this.divisionValue = divisionValue;
  }

  getDivisionValue() {
    return this.divisionValue;
  }

  setDCMContacts(dcmContacts) {
    this.dcmContacts = dcmContacts;
  }

  getDCMContacts() {
    return this.dcmContacts;
  }

  setTPIContacts(tpiContacts) {
    this.tpiContacts = tpiContacts;
  }

  getTPIContacts() {
    return this.tpiContacts;
  }

  setLeadCounsels(leadCounsels) {
    this.leadCounsels = leadCounsels;
  }

  getLeadCounsels() {
    return this.leadCounsels;
  }

  toObject(input) {
    this.setDivisionCode(input.divisionCode);
    this.setDivisionValue(input.divisionValue || "");

    this.dcmContacts = [];
    if (input.dcmContacts && Array.isArray(input.dcmContacts) && input.dcmContacts.length > 0) {
      input.dcmContacts.forEach(dcmContact => {
        this.dcmContacts.push(new ContactDAO().toObject(dcmContact));
      });
    } else {
      this.dcmContacts.push(new ContactDAO());
    }

    this.tpiContacts = [];
    if (input.tpiContacts && Array.isArray(input.tpiContacts) && input.tpiContacts.length > 0) {
      input.tpiContacts.forEach(tpiContact => {
        this.tpiContacts.push(new ContactDAO().toObject(tpiContact));
      });
    } else {
      this.tpiContacts = [new ContactDAO()];
    }

    this.leadCounsels = [];
    if (input.leadCounsels && Array.isArray(input.leadCounsels) && input.leadCounsels.length > 0) {
      input.leadCounsels.forEach(leadCounsel => {
        this.leadCounsels.push(new ContactDAO().toObject(leadCounsel));
      });
    } else {
      this.leadCounsels = [new ContactDAO()];
    }
    return this;
  }
}

export default DivisionDAO;
