class ContactDAO {

  setEmail(email) {
    this.email = email;
  }

  getEmail() {
    return this.email;
  }

  setFullName(fullName) {
    this.fullName = fullName;
  }

  getFullName() {
    return this.fullName;
  }

  setPhone(phone) {
    this.phone = phone;
  }

  getPhone() {
    return this.phone;
  }

  setKey(key) {
    this.key = key;
  }

  getKey() {
    return this.key;
  }

  setTitle(title) {
    this.title = title;
  }

  getTitle() {
    return this.title;
  }

  toObject(input) {
    this.setEmail(input.email);
    this.setFullName(input.fullName);
    this.setPhone(input.phone);
    this.setKey(input.key);
    this.setTitle(input.title);
    return this;
  }
}

export default ContactDAO;
