// @flow
import ContentDAO from "../models/content/ContentDAO";
import apiService from "./apiService";
import * as WTRWEndpoints from "./wtrw.api.js";

class SpotlightService {
  static SpotlightDTO(contents: Object[]) {
    return contents.map<ContentDAO>(content => new ContentDAO().toObject(content));
  }

  static async getSpotlightItem(languageCode) {
    const response = await apiService.get(`${WTRWEndpoints.SPOTLIGHT_ENDPOINT}?languageCode=${languageCode}`);
    const { data } = response;
    return this.SpotlightDTO(data);
  }

  static async updateSpotlightItem(content: Object) {
    const response = await apiService.post(WTRWEndpoints.SPOTLIGHT_ENDPOINT, content);
    return response;
  }
}

export default SpotlightService;
