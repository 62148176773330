export default class OAuthService {
  constructor(options) {
    this.options = options;
  }

  exchangeAuthCode = authCode => {
    const request = {
      grant_type: "authorization_code",
      code: authCode,
      redirect_uri: this.options.redirect_uri,
      client_id: this.options.client_id
    };

    return fetch(this.options.url + this.options.tokenEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: this.buildQueryString(request)
    }).then(response => response.json());
  };

  getURL = (state, scope) => {
    const settings = {
      client_id: this.options.client_id,
      response_type: "code",
      redirect_uri: this.options.redirect_uri,
      pfidpadapterid: this.options.adapter,
      state,
      scope
    };
    const url = `${this.options.url}${this.options.authEndpoint}?${this.buildQueryString(
      settings
    )}`;
    return url;
  };

  refreshToken = refreshToken => {
    const data = {
      grant_type: "refresh_token",
      refresh_token: refreshToken
    };

    return fetch(this.options.url + this.options.tokenEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: this.buildQueryString(data)
    }).then(response => {
      if (response.status === 401) {
        throw new Error("unauthorized");
      } else {
        return response.json();
      }
    });
  };

  getOAuthSettings = () => this.options;

  // Move to Utility class
  buildQueryString = obj =>
    Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join("&");
}
