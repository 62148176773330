class CommonQADAO {

  setQuestion(question) {
    this.question = question;
  }

  getQuestion() {
    return this.question;
  }

  setAnswer(answer) {
    this.answer = answer;
  }

  getAnswer() {
    return this.answer;
  }

  setCategory(category) {
    this.category = category;
  }

  getCategory() {
    return this.category;
  }

  toObject(input) {
    this.setQuestion(input.question);
    this.setAnswer(input.answer);
    this.setCategory(input.category);
    return this;
  }
};

export default CommonQADAO;
