import QuestionDAO from "../models/question/QuestionDAO";
import apiService from "./apiService";

class QuestionService {
  static QuestionDTO(content) {
    return content.map(item => new QuestionDAO().toObject(item));
  }

  static getQuestions(permissionLevel) {
    const adminTrue= "?admin=true";
    return new Promise((resolve, reject) => {
      let endpoint = "question";
      switch (permissionLevel) {
        case "FullAdmin":
          endpoint = endpoint + adminTrue;
          break;
        case "QuestionAdmin":
          endpoint = endpoint + adminTrue;
          break;
        case "Advisor":
          endpoint = endpoint + adminTrue;
          break;
        default:
      }
      apiService.get(endpoint).then(response => {
        resolve(this.QuestionDTO(response.data));
      });
    });
  }

  static updateQuestion(question) {
    return new Promise((resolve, reject) => {
      apiService
        .post("question", question)
        .then(response => {
          if (response.status === 201) {
            resolve(response.data);
          }
          reject(response);
        })
        .catch(err => reject(err));
    });
  }
}

export default QuestionService;
