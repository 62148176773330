function define(name, value) {
    Object.defineProperty(exports, name, {
      value: value,
      enumerable: true
    });
  }
  
  define("ANTIBRIBERY", "Anti-bribery");
  define("NOFACILITATIONPAYMENTS", "No Facilitation Payments")
  define("THIRDPARTYINTERMEDIARIES","Third Party Intermediaries")
  