class KeywordActionDAO {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  setAction(action) {
    this.action = action;
  }

  getAction() {
    return this.action;
  }

  setKeyword(keyword) {
    this.keyword = keyword;
  }

  getKeyword() {
    return this.keyword;
  }

  setDescription(description) {
    this.description = description;
  }

  getDescription() {
    return this.description;
  }

  setOptions(options) {
    this.options = options;
  }

  getOptions() {
    return this.options;
  }

  toObject(input) {
    this.setAction(input.action);
    this.setKeyword(input.keyword);
    this.setDescription(input.description);
    this.setOptions(input.options);
    return this;
  }
};

export default KeywordActionDAO;