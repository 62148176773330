class CEOMessage {
  constructor(obj: Object) {
    obj && Object.assign(this, obj);
  }
  setCEOMessage(ceoMessage) {
    this.ceoMessage = ceoMessage;
  }

  getCEOMessage() {
    return this.ceoMessage;
  }

  toObject(input) {
    this.setCEOMessage(input.ceoMessage);
    return this;
  }
}

export default CEOMessage;
