import React from "react";
import View from "../customModules/cardView";

// This file handles all the logic for the module cards
// The return calls the Card View /customMudules/cardView

const CardLogic = props => {
  const { value } = props;

  // props is all the card data as an object
  const [cardState] = React.useState(value);

  // Opens the edit cards dialog
  const handleEditOpen = event => {
    // alert("Edit open was chosen");
  };

  return (
    <div>
      <View onEditClick={handleEditOpen} onDeleteClick={handleEditOpen} card={cardState}></View>
    </div>
  );
};

export default CardLogic;
