import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { Fragment, useCallback, useEffect, useState } from "react";

import DoDont from "../components/dodont";
import Table from "../components/Table";
import WorkService from "../data/api/workService";
import ImageResource from "../data/imageResource";
import ContentDAO from "../data/models/content/ContentDAO";
import KeywordActionDAO from "../data/models/content/KeywordActionDAO";
import errorNotify from "./responseNotifyHelper";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 20,
    display: "flex",
    flexWrap: "wrap",
    wordWrap: true
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  menu: {
    width: 400,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  fab: {
    margin: theme.spacing(2),
    color: "#FFCC00",
    background: "black"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: 200
  },
  root: {
    maxWidth: 345
  },
  keywordContainer: {
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  },
  content: {
    flex: "1 0 auto"
  },
  langaugeCode: {
    width: "25%"
  },
  addContactFab: {
    margin: 5,
    paddingRight: 15,
    color: "#FFCC00",
    background: "#373A3D"
  }
}));

const materialTableConfig = {
  columns: [
    { title: "Business Area", field: "businessArea" },
    { title: "Content Type", field: "contentType", editable: "never" },
    {
      title: "Language Code",
      field: "languageCode",
      lookup: { zh: "Chinese (Simplified)", nl: "Dutch", en: "English", fr: "French", de: "German", it: "Italian", ja: "Japanese", pt: "Portuguese", ru: "Russian", es: "Spanish" }
    },
    {
      title: "Icon",
      field: "businessAreaIconKey",
      lookup: {
        dealersIcon: ImageResource.get("dealersIcon"),
        socialMediaIcon: ImageResource.get("socialMediaIcon"),
        entertainingIcon: ImageResource.get("entertainingIcon"),
        giftsIcon: ImageResource.get("giftsIcon"),
        competitorsIcon: ImageResource.get("competitorsIcon"),
        customersIcon: ImageResource.get("customersIcon"),
        shippingIcon: ImageResource.get("shippingIcon"),
        tradeIcon: ImageResource.get("tradeIcon"),
        travelIcon: ImageResource.get("travelIcon"),
        suppliersIcon: ImageResource.get("suppliersIcon"),
        employeeDecisionIcon: ImageResource.get("employeeDecisionIcon"),
        insiderTradingWorkIcon: ImageResource.get("insiderTradingWorkIcon"),//bug fix:33805
        conflictOfInterestWorkIcon: ImageResource.get("conflictOfInterestWorkIcon")//bug fix:33805
      },
      render: rowData => ImageResource.get(rowData.businessAreaIconKey)
    }
  ],
  options: {
    grouping: true
  }
};

const languageLookup = materialTableConfig.columns.find(column => column.field === "languageCode").lookup;

const menuItems = Object.entries(languageLookup).map(([key, value]) => (
  <MenuItem key={value} value={key}>
    {value}
  </MenuItem>
));

const pageDescriptionList = [
  { value: "Learn" },
  { value: "LearnDetailPage" },
  { value: "CommonQuestions" },
  { value: "FilterQuestionsPage" },
  { value: "TPIScreen" },
  { value: "PreApproval" },
  { value: "CTOT" },
  { value: "AskAQuestion" },
  { value: "SubmittedScreen" },
  { value: "MyContacts" }
];

const PageDescriptionArray = [
  "Learn",
  "LearnDetailPage",
  "CommonQuestions",
  "FilterQuestionsPage",
  "TPIScreen",
  "PreApproval",
  "CTOT",
  "AskAQuestion",
  "SubmittedScreen",
  "MyContacts"
];

function Work() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [workData, setWorkData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeWorkContent, setActiveWorkContent] = useState(new ContentDAO());
  const [activeWorkContentIndex, setActiveWorkContentIndex] = useState(null);
  const [keywordModalVisible, setKeywordModalVisible] = useState(false);
  const [currentKeywordContent, setCurrentKeywordContent] = useState({
    index: -1,
    data: new KeywordActionDAO()
  });
  const updateLanguage = useStoreActions(actions => actions.language.changeLanguage);
  const [languageCode, setCurrentLanguageCode] = useState(
    useStoreState(state => state.language.language)
  );
  const [linkType, setLinkType] = useState(null);
  const moduleDescriptionList = useStoreState(state => state.modules.moduleDescriptionList);
  const moduleDescriptionArray = useStoreState(state => state.modules.moduleDescriptionArray);
  const fetchModulesThunk = useStoreActions(actions => actions.modules.fetchData);
  const fetchModules = useCallback(async (lang) => {
    await fetchModulesThunk(lang || 'en');
  }, [fetchModulesThunk]);
  const resetCurrentWorkContent = useCallback(() => {
    const initObject = {
      riskArea: "RANatural",
      languageCode: "en",
      contentType: "DoDoNot",
      contentData: {
        dos: [],
        doNots: []
      },
      keywordActions: []
    };
    const initWorkContent = new ContentDAO().toObject(initObject);
    setActiveWorkContent(initWorkContent);
    setActiveWorkContentIndex(null);
    setLinkType(null);
  }, []);

  const fetchWorkItems = useCallback(async (language) => {
    try {
      setIsLoading(true);
      const data = await WorkService.getWorkItems(language);
      setIsLoading(false);
      setWorkData(data);
    } catch (error) {
      errorNotify(error);
    }
  }, []);

  const handleSubmitWorkItem = useCallback(async () => {
    try {
      const workClone = [...workData];
      const updatedWorkItem = new ContentDAO().toObject(activeWorkContent);
      setIsLoading(true);
      const result = await WorkService.updateWorkItems(updatedWorkItem);
      setIsLoading(false);
      const responseDataDAO = new ContentDAO().toObject(result.data);
      workClone[activeWorkContentIndex] = responseDataDAO;
      setWorkData(workClone);
      return true;
    } catch (error) {
      errorNotify(error);
      setIsLoading(false);
      return false;
    }
  }, [activeWorkContent, activeWorkContentIndex, workData]);

  useEffect(() => {
    fetchModules(languageCode);
    resetCurrentWorkContent();
    fetchWorkItems(languageCode);
  }, [fetchWorkItems, languageCode, resetCurrentWorkContent, fetchModules]);

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleSubmit = async () => {
    try {
      const workContentClone = [...workData];
      const updateIndex = activeWorkContentIndex !== null ? activeWorkContentIndex : -1;
      const updateWorkContent = updateIndex !== -1;
      const workContentObject = new ContentDAO().toObject(activeWorkContent);
      if (updateWorkContent) {
        const success = await handleSubmitWorkItem();
        if (success) {
          workContentClone[updateIndex] = workContentObject;
        }
      } else {
        setIsLoading(true);
        const result = await WorkService.updateWorkItems(workContentObject);
        setIsLoading(false);
        const responseDataDAO = new ContentDAO().toObject(result.data);
        workContentClone.push(responseDataDAO);
      }
      setWorkData(workContentClone);
      resetCurrentWorkContent();
      setModalVisible(false);
    } catch (error) {
      errorNotify(error);
      setIsLoading(false);
    }
  };

  const onRowClickHandler = rowData => {
    const workContent = new ContentDAO().toObject(rowData);
    const index = rowData.tableData.id;
    setActiveWorkContentIndex(index);
    setActiveWorkContent(workContent);
    setModalVisible(true);
  };

  const onRowUpdateHandler = async (newData, oldData) => {
    try {
      const workClone = [...workData];
      const newWork = { ...newData };
      delete newWork.tableData;
      const result = await WorkService.updateWorkItems(newWork);
      const responseDataDAO = new ContentDAO().toObject(result.data);
      workClone[workData.indexOf(oldData)] = responseDataDAO;
      setWorkData(workClone);
    } catch (error) {
      errorNotify(error);
    }
  };

  const onRowDeleteHandler = async oldData => {
    try {
      const workClone = [...workData];
      const deleteIndex = workData.indexOf(oldData);
      workClone.splice(deleteIndex, 1);
      setWorkData(workClone);
      await WorkService.deleteWorkItem(oldData);
    } catch (error) {
      errorNotify(error);
    }
  };

  const handleAddWorkContent = useCallback(() => {
    resetCurrentWorkContent();
    setModalVisible(true);
  }, [resetCurrentWorkContent]);

  const updateCurrentWorkContent = () => {
    const updatedCurrentWorkContent = new ContentDAO().toObject(activeWorkContent);
    setActiveWorkContent(updatedCurrentWorkContent);
  };

  const updateCurrentKeywordContent = () => {
    const { index, data } = currentKeywordContent;
    const updatedKeywordContent = new KeywordActionDAO(data);
    setCurrentKeywordContent({ index, data: updatedKeywordContent });
  };

  const handleRemoveKeyword = index => {
    const keywordActionsClone = [...activeWorkContent.getKeywordActions()];
    keywordActionsClone.splice(index, 1);
    activeWorkContent.setKeywordActions(keywordActionsClone);
    updateCurrentWorkContent();
  };

  const handleKeywordContentSubmit = () => {
    const { index, data } = currentKeywordContent;
    const _keywordActions = [...activeWorkContent.getKeywordActions()];
    const keywordContentObject = new KeywordActionDAO().toObject(data);
    if (index === -1 && data.getAction() && data.getKeyword()) {
      _keywordActions.push(keywordContentObject);
      activeWorkContent.setKeywordActions(_keywordActions);
    } else {
      _keywordActions[index] = keywordContentObject;
      activeWorkContent.setKeywordActions(_keywordActions);
    }
    updateCurrentWorkContent();
    setKeywordModalVisible(false);
    setCurrentKeywordContent({
      index: -1,
      data: new KeywordActionDAO()
    });
    setLinkType(null);
  };

  const updateLanguageCode = useCallback(async (lang) => {
    updateLanguage(lang);
    resetCurrentWorkContent();
    await fetchWorkItems(lang);
  }, [fetchWorkItems, resetCurrentWorkContent, updateLanguage]);

  const renderKeywords = keywordList => {
    return (
      <Fragment>
        <Typography variant="h6" noWrap>
          Keyword Actions
        </Typography>
        <div className={classes.keywordContainer}>
          {keywordList.map((keywordAction, index) => {
            const { keyword } = keywordAction;
            return (
              <Paper elevation={3} key={activeWorkContent.getIdentifier() + "keywordAction"}>
                <Card className={classes.root}>
                  <CardHeader
                    style={{ maxHeight: 60 }}
                    subheader={keyword}
                    subheaderTypographyProps={{ variant: "subtitle2" }}
                  />
                  <CardContent className={classes.content}>
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setCurrentKeywordContent({ index, data: keywordAction });
                        const action = keywordAction.getAction();
                        const description = keywordAction.getDescription();
                        const pageDescriptionSet = new Set(PageDescriptionArray);
                        const moduleDescriptionSet = new Set(moduleDescriptionArray);
                        if (action === "Link") {
                          if (pageDescriptionSet.has(description)) setLinkType("page");
                          if (moduleDescriptionSet.has(description)) setLinkType("module");
                        }
                        setKeywordModalVisible(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton aria-label="remove" onClick={() => handleRemoveKeyword(index)}>
                      <DeleteOutline></DeleteOutline>
                    </IconButton>
                  </CardContent>
                </Card>
              </Paper>
            );
          })}
          <Paper elevation={3} key={activeWorkContent.getIdentifier() + "keywordAction"}>
            <Card className={classes.root}>
              <CardHeader style={{ maxHeight: 60 }} subheader="Add Keyword"></CardHeader>
              <CardContent
                className={classes.content}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    setCurrentKeywordContent({
                      index: -1,
                      data: new KeywordActionDAO()
                    });
                    setLinkType(null);
                    setKeywordModalVisible(true);
                  }}
                >
                  <AddBox />
                </IconButton>
              </CardContent>
            </Card>
          </Paper>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <FormGroup className={classes.container} noValidate autoComplete="off">
        <TextField
          label="Language Code"
          className={classes.langaugeCode}
          defaultValue={languageCode}
          select
          size="normal"
          onChange={({ target }) => {
            setCurrentLanguageCode(target.value);
            updateLanguageCode(target.value);
          }}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          InputProps={{ className: classes.customInput }}
        >
          {menuItems}
        </TextField>
      </FormGroup>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>
        <Fab
          variant="extended"
          aria-label="publish"
          className={classes.addContactFab}
          onClick={handleAddWorkContent}
        >
          Add Work Content
        </Fab>
      </div>
      <Table
        title="Manage Work Content"
        data={workData}
        config={materialTableConfig}
        onRowClickHandler={onRowClickHandler}
        onRowUpdateHandler={onRowUpdateHandler}
        onRowDeleteHandler={onRowDeleteHandler}
        isLoading={isLoading}
      />
      <Dialog
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{activeWorkContent.getBusinessArea()}</DialogTitle>
        <DialogContent>
          <FormGroup className={classes.container} noValidate autoComplete="off">
            {activeWorkContent.getKeywordActions() &&
              renderKeywords(activeWorkContent.getKeywordActions())}
          </FormGroup>
          <Collapse in={true}>
            <FormGroup>
              <DoDont
                getDos={() => activeWorkContent.getContentData().getDos()}
                getDonts={() => activeWorkContent.getContentData().getDoNots()}
                setDos={dos => {
                  activeWorkContent.getContentData().setDos(dos);
                  updateCurrentWorkContent();
                }}
                setDonts={donts => {
                  activeWorkContent.getContentData().setDoNots(donts);
                  updateCurrentWorkContent();
                }}
              ></DoDont>
            </FormGroup>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={keywordModalVisible}
        onClose={() => {
          setLinkType(null);
          setKeywordModalVisible(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Edit Keyword Content</DialogTitle>
        <FormGroup
          style={{ paddingTop: 0, marginLeft: 20 }}
          className={classes.container}
          noValidate
          autoComplete="off"
        >
          <TextField
            key="activeKeywordContent"
            id="keywordActionContent-workContent"
            label="Keyword"
            className={classes.textField}
            value={currentKeywordContent.data.getKeyword()}
            onChange={event => {
              const { value } = event.target;
              currentKeywordContent.data.setKeyword(value);
              updateCurrentKeywordContent();
            }}
            margin="normal"
          ></TextField>
          <TextField
            key="activeKeywordAction"
            id="keywordActionAction-workContent"
            label="Action"
            select
            className={classes.textField}
            value={currentKeywordContent.data.getAction()}
            onChange={event => {
              const { value } = event.target;
              currentKeywordContent.data.setAction(value);
              updateCurrentKeywordContent();
            }}
            margin="normal"
          >
            {[{ value: "Modal" }, { value: "Link" }].map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
          {currentKeywordContent.data.getAction() === "Link" ? (
            <Fragment>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="linkType"
                  name="linkType1"
                  value={linkType}
                  onChange={event => {
                    setLinkType(event.target.value);
                    currentKeywordContent.data.setDescription(null);
                    updateCurrentKeywordContent();
                  }}
                >
                  <FormControlLabel
                    value="page"
                    control={<Radio checked={linkType === "page"} style={{ color: "#FFCC00" }} />}
                    label="Page"
                  />
                  <FormControlLabel
                    value="module"
                    control={<Radio checked={linkType === "module"} style={{ color: "#FFCC00" }} />}
                    label="Module"
                  />
                </RadioGroup>
              </FormControl>
              {linkType === "page" ? (
                <TextField
                  key="activeKeywordPageDescription"
                  id="keywordActionPageDescription-workContent"
                  label="Description"
                  select
                  className={classes.textField}
                  value={currentKeywordContent.data.getDescription()}
                  onChange={event => {
                    const { value } = event.target;
                    currentKeywordContent.data.setDescription(value);
                    currentKeywordContent.data.setOptions("page");
                    updateCurrentKeywordContent();
                  }}
                  margin="normal"
                >
                  {pageDescriptionList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  key="activeKeywordModuleDescription"
                  id="keywordActionModuleDescription-workContent"
                  label="Description"
                  select
                  className={classes.textField}
                  value={currentKeywordContent.data.getDescription()}
                  onChange={event => {
                    const { value } = event.target;
                    currentKeywordContent.data.setDescription(value);
                    currentKeywordContent.data.setOptions("module");
                    updateCurrentKeywordContent();
                  }}
                  margin="normal"
                >
                  {moduleDescriptionList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Fragment>
          ) : (
            <TextField
              key="activeKeywordDescription"
              id="keywordActionDescription-workContent"
              label="Description"
              multiline
              className={classes.textField}
              value={currentKeywordContent.data.getDescription()}
              onChange={event => {
                const { value } = event.target;
                currentKeywordContent.data.setDescription(value);
                updateCurrentKeywordContent();
              }}
              margin="normal"
            ></TextField>
          )}
        </FormGroup>
        <DialogContent>
          <DialogActions>
            <Button onClick={() => setKeywordModalVisible(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleKeywordContentSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default Work;
