import DivisionDAO from "../models/division/DivisionDAO";
import apiService from "./apiService";
import { DIVISION_CODES_ENDPOINT } from "./wtrw.api";

class ContactService {
  static DivisionDTO(contacts) {
    return contacts.map(contact => new DivisionDAO().toObject(contact));
  }

  static async getContactDetails() {
    const response = await apiService.get(DIVISION_CODES_ENDPOINT + "?admin=true");
    const { data } = response;
    return this.DivisionDTO(data);
  }

  static async updateContactDetails(body) {
    const response = await apiService.post(DIVISION_CODES_ENDPOINT, body);
    return response;
  }

  static deleteDivision(divisionCode) {
    return apiService.delete(`${DIVISION_CODES_ENDPOINT}/${divisionCode}`);
  }
}

export default ContactService;
