import { action, actionOn, thunk } from "easy-peasy";

import LearningModuleService from "../data/api/learningModuleService";
import DecisionMatrixModuleService from "../data/api/decisionMatrixModuleService";

const learningModulesStore = {
  data: [],
  riskAreas: [],
  learningModules: [],
  scenarioModules: [],
  decisionMatrixModules: [],
  status: "pending",
  setData: action((state, payload) => {
    state.data = payload;
  }),
  setRiskAreas: action((state, payload) => {
    state.riskAreas = payload;
  }),
  setLearningModules: action((state, payload) => {
    state.learningModules = payload;
  }),
  setdecisionMatrixModules: action((state, payload) => {
    state.decisionMatrixModules = payload;
  }),
  setScenarioModules: action((state, payload) => {
    state.scenarioModules = payload;
  }),
  setStatus: action((state, payload) => {
    state.status = payload;
  }),
  fetchDecisionMatrix: thunk(async (actions, payload) => {
    const lang = payload ? payload : 'en'
    const { setData, setStatus, setdecisionMatrixModules } = actions;
    setStatus("pending");
    const response = await DecisionMatrixModuleService.getDMModules(lang);
    const tempDecisionMatrixModule = [];
    response.forEach(module => {
      const level = module.getContentData().getLevel();
      if(level === 'Decision Matrix'){
        tempDecisionMatrixModule.push(module);
      }
    });
    setdecisionMatrixModules(tempDecisionMatrixModule);
    setData(response);
    setStatus("done");
  }),
  fetchData: thunk(async (actions, payload) => {
    const lang = payload ? payload : 'en'
    const { setData, setStatus, setLearningModules, setScenarioModules} = actions;
    setStatus("pending");
    const response = await LearningModuleService.getLearningModules(lang);
    const tempLearningModule = [];
    const tempScenarioModules = [];
    response.forEach(module => {
      const level = module.getContentData().getLevel();
      if(level === 'Learning Module'){
        tempLearningModule.push(module);
      } else {
        tempScenarioModules.push(module);
      }
    });
    setLearningModules(tempLearningModule);
    setScenarioModules(tempScenarioModules);
    setData(response);
    setStatus("done");
  }),
  fetchRiskAreas: thunk(async (actions, payload) => {
    const lang = payload ? payload : 'en'
    const { setRiskAreas, setStatus } = actions;
    setStatus("pending");
    const response = await LearningModuleService.getRiskAreas(lang);
    setRiskAreas(response);
    setStatus("done");
  }),
  updateLearningModule: thunk(async (actions, payload) => {
    const { setStatus } = actions;
    setStatus("pending");
    await LearningModuleService.updateLearningModule(payload);
    setStatus("done");
  }),

  updateDMModule: thunk(async (actions, payload) => {
    const { setStatus } = actions;
    setStatus("pending");
    await DecisionMatrixModuleService.updateDMModule(payload);
    setStatus("done");
  }),
  moduleDescriptionList: [],
  moduleDescriptionArray: [],
  onSetDataResolver: actionOn(
    actions => actions.setData,
    (state, target) => {
      const { payload } = target;
      state.moduleDescriptionList = payload.map(module => {
        const moduleRiskArea = module.getRiskArea() || "";
        const moduleId = module.getIdentifier() || "";
        const submoduleTitle = module.getContentData().getTitle() || "";
        return {
          value: `{'riskArea':'${moduleRiskArea}', 'moduleId':'${moduleId}'}`,
          label: submoduleTitle
        };
      });
      state.moduleDescriptionArray = payload.map(module => {
        const moduleRiskArea = module.getRiskArea() || "";
        const moduleId = module.getIdentifier() || "";
        return `{'riskArea':'${moduleRiskArea}', 'moduleId':'${moduleId}'}`;
      });
    }
  )
};

export default learningModulesStore;
