class UserFeedbackDAO{
    setFeedbackKey(feedbackKey){
        this.feedbackKey = feedbackKey;
    }

    getFeedbackKey(){
        return this.feedbackKey;
    }

    setLike(like){
        this.like = like;
    }

    getLike(){
        return this.like;
    }

    setComment(comment){
        this.comment = comment;
    }

    getComment(){
        return this.comment;
    }

    toObject(input){
        this.setFeedbackKey(input.feedbackKey);
        this.setLike(input.like);
        this.setComment(input.comment);
        return this;
    }
}

export default UserFeedbackDAO;