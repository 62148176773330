import {
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";

// Goal of this class is to return the reusable CARD VIEW
// This class does not do the logic or functions

const useStyles = makeStyles(theme => ({
  cardPaper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: 250,
    height: 400,
    overflow: "scroll"
  }
}));

const CardView = props => {
  const classes = useStyles();
  const { card, onEditClick, onDeleteClick } = props;

  return (
    <Grid key={card.id} item>
      <Paper className={classes.cardPaper}>
        <Typography variant="body1">
          {card.title}
        </Typography>
        <br />
        <Typography variant="body1">
          {card.icon}
        </Typography>
        <br />
        <Typography variant="body2">
          {card.body}
        </Typography>
        <br />
        <IconButton onClick={onEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Paper>
    </Grid>
  );
};

export default CardView;
