import axios from "axios";

import LocalStorageWrapper from "../../modules/localStorage";
import OAuthService from "../../modules/oauth";

let isRefreshing = false;
let failedQueue = [];

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_PROXY_URL + process.env.REACT_APP_WTRW_BASE_URI,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 60000
});

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem("refreshToken");
      return new Promise(function(resolve, reject) {
        const oauthClient = new OAuthService({
          grantType: "refresh_token",
          url: process.env.REACT_APP_OAUTH,
          client_id: process.env.REACT_APP_CLIENT_ID,
          redirect_uri: process.env.REACT_APP_REDIRECT_URI,
          scope: "openid profile",
          authEndpoint: "/as/authorization.oauth2",
          tokenEndpoint: "/as/token.oauth2",
          revocationEndpoint: "/as/revoke_token.oauth2",
          userInfoEndpoint: "/idp/userinfo.openid",
          adapter: "OAuthAdapterCCDS"
        });
        oauthClient
          .refreshToken(refreshToken)
          .then(response => {
            const { access_token, refresh_token } = response;
            localStorage.setItem("accessToken", access_token);
            localStorage.setItem("refreshToken", refresh_token);
            apiClient.defaults.headers.common["Authorization"] = "Bearer " + access_token;
            originalRequest.headers["Authorization"] = "Bearer " + access_token;
            processQueue(null, access_token);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            LocalStorageWrapper.clearTokens();
            window.location = oauthClient.getURL("State", oauthClient.getOAuthSettings().scope);
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default apiClient;
