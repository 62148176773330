import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import errorNotify from "./responseNotifyHelper";
import Table from "../components/Table";
import CommonQAService from "../data/api/commonQAService";
import ContentDAO from "../data/models/content/ContentDAO";
import KeywordActionDAO from "../data/models/content/KeywordActionDAO";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 20,
    display: "flex",
    flexWrap: "wrap",
    wordWrap: true
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  menu: {
    width: 400,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  fab: {
    margin: theme.spacing(2),
    color: "#FFCC00",
    background: "black"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: 200
  },
  root: {
    maxWidth: 345
  },
  keywordContainer: {
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  },
  content: {
    flex: "1 0 auto"
  },
  langaugeCode: {
    width: "25%"
  },
  addContactFab: {
    margin: 5,
    paddingRight: 15,
    color: "#FFCC00",
    background: "#373A3D"
  }
}));

const pageDescriptionList = [
  { value: "Learn" },
  { value: "LearnDetailPage" },
  { value: "CommonQuestions" },
  { value: "FilterQuestionsPage" },
  { value: "TPIScreen" },
  { value: "PreApproval" },
  { value: "CTOT" },
  { value: "AskAQuestion" },
  { value: "SubmittedScreen" },
  { value: "MyContacts" }
];

const PageDescriptionArray = [
  "Learn",
  "LearnDetailPage",
  "CommonQuestions",
  "FilterQuestionsPage",
  "TPIScreen",
  "PreApproval",
  "CTOT",
  "AskAQuestion",
  "SubmittedScreen",
  "MyContacts"
];

function CommonQA() {
  const classes = useStyles();
  const [riskAreaDropDown, setRiskAreaDropDown] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [commonQAs, setCommonQAs] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentCommonQAContent, setCurrentCommonQAContent] = useState(new ContentDAO());
  const [currentCommonQAIndex, setCurrentCommonQAIndex] = useState(null);
  const [keywordModalVisible, setKeywordModalVisible] = useState(false);
  const [currentKeywordContent, setCurrentKeywordContent] = useState({
    index: -1,
    data: new KeywordActionDAO()
  });
  const updateLanguage = useStoreActions(actions => actions.language.changeLanguage);
  const [languageCode, setCurrentLanguageCode] = useState(
    useStoreState(state => state.language.language)
  );
  const [linkType, setLinkType] = useState(null);
  const moduleDescriptionList = useStoreState(state => state.modules.moduleDescriptionList);
  const moduleDescriptionArray = useStoreState(state => state.modules.moduleDescriptionArray);
  const riskAreas = useStoreState(state => state.modules.riskAreas);
  const fetchModulesThunk = useStoreActions(actions => actions.modules.fetchData);
  const fetchRiskAreasThunk = useStoreActions(actions => actions.modules.fetchRiskAreas);
  const fetchModules = useCallback(async (lang) => {
    await fetchModulesThunk(lang || 'en');
  }, [fetchModulesThunk]);
  const fetchRiskAreas = useCallback(async (lang) => {
    await fetchRiskAreasThunk(lang || 'en');
  }, [fetchRiskAreasThunk])
  const resetCurrentCommonQAContent = useCallback(() => {
    const initObject = {
      riskArea: "",
      languageCode: "en",
      contentType: "CommonQA",
      contentData: {
        question: "",
        answer: ""
      },
      keywordActions: []
    };
    const initCommonQAContent = new ContentDAO().toObject(initObject);
    setCurrentCommonQAContent(initCommonQAContent);
    setCurrentCommonQAIndex(null);
  }, []);

  const fetchCommonQAs = useCallback(async (language) => {
    try {
      setIsLoading(true);
      const data = await CommonQAService.getCommonQAs(language);
      setIsLoading(false);
      return setCommonQAs(data);
    } catch (error) {
      errorNotify(error);
    }
  }, []);

  useEffect(() => {
    fetchRiskAreas();
  }, []);

  useEffect(() => {
    fetchModules(languageCode);
    resetCurrentCommonQAContent();
    fetchCommonQAs(languageCode);
    const obj = {};
    riskAreas.forEach((riskArea) => {
      obj[riskArea.getRiskArea()] = riskArea.getRiskArea()
    });
    setRiskAreaDropDown(obj);
  }, [fetchCommonQAs, languageCode, resetCurrentCommonQAContent, riskAreas, fetchModules]);

  const tableConfig = {
    columns: [
      {
        title: "Risk Area",
        field: "riskArea",
        lookup: riskAreaDropDown
      },
      { title: "Question", field: "contentData.question" },
      {
        title: "Answer",
        field: "contentData.answer",
        editComponent: function multilineTableEdit(props) {
          return (
            <TextField
              key="currentQuestionAnswer"
              id="currentQuestionAnswerID"
              multiline
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              margin="normal"
            ></TextField>
          );
        }
      },
      {
        title: "Language Code",
        field: "languageCode",
        lookup: { zh: "Chinese (Simplified)", nl: "Dutch", en: "English", fr: "French", de: "German", it: "Italian", ja: "Japanese", pt: "Portuguese", ru: "Russian", es: "Spanish" }
      }
    ],
    options: {
      grouping: true
    }
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleSubmit = () => {
    const commonQAsClone = [...commonQAs];
    const updateIndex = currentCommonQAIndex !== null ? currentCommonQAIndex : -1;
    const updateCommonQAContent = updateIndex !== -1;
    const commonQAContentObject = new ContentDAO().toObject(currentCommonQAContent);
    if (updateCommonQAContent) {
      commonQAsClone[updateIndex] = commonQAContentObject;
    } else {
      commonQAsClone.push(commonQAContentObject);
    }
    setCommonQAs(commonQAsClone);
    resetCurrentCommonQAContent();
    setModalVisible(false);
  };

  const onRowClickHandler = rowData => {
    const commonQAContent = new ContentDAO().toObject(rowData);
    const index = rowData.tableData.id;
    setCurrentCommonQAIndex(index);
    setCurrentCommonQAContent(commonQAContent);
    setModalVisible(true);
  };

  const onRowUpdateHandler = async (newData, oldData) => {
    try {
      const commonQAsClone = [...commonQAs];
      const newDataDAO = new ContentDAO().toObject(newData);
      const result = await CommonQAService.updateCommonQA(newDataDAO);
      const responseDataDAO = new ContentDAO().toObject(result.data);
      commonQAsClone[commonQAs.indexOf(oldData)] = responseDataDAO;
      setCommonQAs(commonQAsClone);
    } catch (error) {
      errorNotify(error);
    }
  };

  const onRowDeleteHandler = async oldData => {
    try {
      const commonQAsClone = [...commonQAs];
      const deleteIndex = commonQAs.indexOf(oldData);
      commonQAsClone.splice(deleteIndex, 1);
      setCommonQAs(commonQAsClone);
      await CommonQAService.deleteCommonQA(oldData);
    } catch (error) {
      errorNotify(error);
    }
  };

  const handleAddCommonQAContent = useCallback(() => {
    resetCurrentCommonQAContent();
    setModalVisible(true);
  }, [resetCurrentCommonQAContent]);

  const updateCurrentCommonQA = () => {
    const updatedCurrentCommonQA = new ContentDAO(currentCommonQAContent);
    setCurrentCommonQAContent(updatedCurrentCommonQA);
  };

  const updateCurrentKeywordContent = () => {
    const { index, data } = currentKeywordContent;
    const updatedKeywordContent = new KeywordActionDAO(data);
    setCurrentKeywordContent({ index, data: updatedKeywordContent });
  };

  const handleRemoveKeyword = index => {
    const keywordActionsClone = [...currentCommonQAContent.getKeywordActions()];
    keywordActionsClone.splice(index, 1);
    currentCommonQAContent.setKeywordActions(keywordActionsClone);
    updateCurrentCommonQA();
  };

  const handleKeywordContentSubmit = () => {
    const { index, data } = currentKeywordContent;
    const _keywordActions = [...currentCommonQAContent.getKeywordActions()];
    const keywordContentObject = new KeywordActionDAO().toObject(data);
    if (index === -1 && data.getAction() && data.getKeyword()) {
      _keywordActions.push(keywordContentObject);
      currentCommonQAContent.setKeywordActions(_keywordActions);
    } else {
      _keywordActions[index] = keywordContentObject;
      currentCommonQAContent.setKeywordActions(_keywordActions);
    }
    updateCurrentCommonQA();
    setKeywordModalVisible(false);
    setCurrentKeywordContent({
      index: -1,
      data: new KeywordActionDAO()
    });
    setLinkType(null);
  };

  const updateLanguageCode = useCallback(async (lang) => {
    updateLanguage(lang);
    resetCurrentCommonQAContent();
    await fetchCommonQAs(lang);
  }, [fetchCommonQAs, resetCurrentCommonQAContent, updateLanguage]);

  const renderKeywords = keywordList => {
    return (
      <Fragment>
        <Typography variant="h6" noWrap>
          Keyword Actions
        </Typography>
        <div className={classes.keywordContainer}>
          {keywordList.map((keywordAction, index) => {
            const { keyword } = keywordAction;
            return (
              <Paper elevation={3} key={currentCommonQAContent.getIdentifier() + "keywordAction"}>
                <Card className={classes.root}>
                  <CardHeader
                    style={{ maxHeight: 60 }}
                    subheader={keyword}
                    subheaderTypographyProps={{ variant: "subtitle2" }}
                  />
                  <CardContent className={classes.content}>
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setCurrentKeywordContent({ index, data: keywordAction });
                        const action = keywordAction.getAction();
                        const description = keywordAction.getDescription();
                        const pageDescriptionSet = new Set(PageDescriptionArray);
                        const moduleDescriptionSet = new Set(moduleDescriptionArray);
                        if (action === "Link") {
                          if (pageDescriptionSet.has(description)) setLinkType("page");
                          if (moduleDescriptionSet.has(description)) setLinkType("module");
                        }
                        setKeywordModalVisible(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton aria-label="remove" onClick={() => handleRemoveKeyword(index)}>
                      <DeleteOutline></DeleteOutline>
                    </IconButton>
                  </CardContent>
                </Card>
              </Paper>
            );
          })}
          <Paper elevation={3} key={currentCommonQAContent.getIdentifier() + "keywordAction"}>
            <Card className={classes.root}>
              <CardHeader style={{ maxHeight: 60 }} subheader="Add Keyword"></CardHeader>
              <CardContent
                className={classes.content}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    setCurrentKeywordContent({
                      index: -1,
                      data: new KeywordActionDAO()
                    });
                    setLinkType(null);
                    setKeywordModalVisible(true);
                  }}
                >
                  <AddBox />
                </IconButton>
              </CardContent>
            </Card>
          </Paper>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <FormGroup className={classes.container} noValidate autoComplete="off">
        <TextField
          label="Language Code"
          className={classes.langaugeCode}
          defaultValue={languageCode}
          select
          size="normal"
          onChange={({ target }) => {
            setCurrentLanguageCode(target.value);
            updateLanguageCode(target.value);
          }}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          InputProps={{ className: classes.customInput }}
        >
          <MenuItem key="Chinese (Simplified)" value="zh">
            Chinese (Simplified)
          </MenuItem>
          <MenuItem key="Dutch" value="nl">
            Dutch
          </MenuItem>
          <MenuItem key="English" value="en">
            English
          </MenuItem>
          <MenuItem key="French" value="fr">
            French
          </MenuItem>
          <MenuItem key="German" value="de">
            German
          </MenuItem>
          <MenuItem key="Italian" value="it">
            Italian
          </MenuItem>
          <MenuItem key="Japanese" value="ja">
            Japanese
          </MenuItem>
          <MenuItem key="Portuguese" value="pt">
            Portuguese
          </MenuItem>
          <MenuItem key="Russian" value="ru">
            Russian
          </MenuItem>
          <MenuItem key="Spanish" value="es">
            Spanish
          </MenuItem>
        </TextField>
      </FormGroup>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>
        <Fab
          variant="extended"
          aria-label="publish"
          className={classes.addContactFab}
          onClick={handleAddCommonQAContent}
        >
          Add FAQ Content
        </Fab>
      </div>
      <Table
        title="Manage FAQ Content"
        config={tableConfig}
        data={commonQAs}
        onRowClickHandler={onRowClickHandler}
        onRowUpdateHandler={onRowUpdateHandler}
        onRowDeleteHandler={onRowDeleteHandler}
        isLoading={isLoading}
      />
      <Dialog
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          {currentCommonQAContent.getBusinessArea()}
        </DialogTitle>
        <DialogContent>
          <FormGroup className={classes.container} noValidate autoComplete="off">
            {currentCommonQAContent.getKeywordActions() &&
              renderKeywords(currentCommonQAContent.getKeywordActions())}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={keywordModalVisible}
        onClose={() => {
          setLinkType(null);
          setKeywordModalVisible(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Edit Keyword Content</DialogTitle>
        <FormGroup className={classes.container} noValidate autoComplete="off">
          <TextField
            key="activeKeywordContent"
            id="keywordActionContent-commonQAContent"
            label="Keyword"
            className={classes.textField}
            value={currentKeywordContent.data.getKeyword()}
            onChange={event => {
              const { value } = event.target;
              currentKeywordContent.data.setKeyword(value);
              updateCurrentKeywordContent();
            }}
            margin="normal"
          ></TextField>
          <TextField
            key="activeKeywordAction"
            id="keywordActionAction-commonQAContent"
            label="Action"
            select
            className={classes.textField}
            value={currentKeywordContent.data.getAction()}
            onChange={event => {
              const { value } = event.target;
              currentKeywordContent.data.setAction(value);
              updateCurrentKeywordContent();
            }}
            margin="normal"
          >
            {[{ value: "Modal" }, { value: "Link" }].map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
          {currentKeywordContent.data.getAction() === "Link" ? (
            <Fragment>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="linkType"
                  name="linkType1"
                  value={linkType}
                  onChange={event => {
                    setLinkType(event.target.value);
                    currentKeywordContent.data.setDescription(null);
                    updateCurrentKeywordContent();
                  }}
                >
                  <FormControlLabel
                    value="page"
                    control={<Radio checked={linkType === "page"} style={{ color: "#FFCC00" }} />}
                    label="Page"
                  />
                  <FormControlLabel
                    value="module"
                    control={<Radio checked={linkType === "module"} style={{ color: "#FFCC00" }} />}
                    label="Module"
                  />
                </RadioGroup>
              </FormControl>
              {linkType === "page" ? (
                <TextField
                  key="activeKeywordPageDescription"
                  id="keywordActionPageDescription-commonQAContent"
                  label="Description"
                  select
                  className={classes.textField}
                  value={currentKeywordContent.data.getDescription()}
                  onChange={event => {
                    const { value } = event.target;
                    currentKeywordContent.data.setDescription(value);
                    currentKeywordContent.data.setOptions("page");
                    updateCurrentKeywordContent();
                  }}
                  margin="normal"
                >
                  {pageDescriptionList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  key="activeKeywordModuleDescription"
                  id="keywordActionModuleDescription-commonQAContent"
                  label="Description"
                  select
                  className={classes.textField}
                  value={currentKeywordContent.data.getDescription()}
                  onChange={event => {
                    const { value } = event.target;
                    currentKeywordContent.data.setDescription(value);
                    currentKeywordContent.data.setOptions("module");
                    updateCurrentKeywordContent();
                  }}
                  margin="normal"
                >
                  {moduleDescriptionList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Fragment>
          ) : (
            <TextField
              key="activeKeywordDescription"
              id="keywordActionDescription-commonQAContent"
              label="Description"
              multiline
              className={classes.textField}
              value={currentKeywordContent.data.getDescription()}
              onChange={event => {
                const { value } = event.target;
                currentKeywordContent.data.setDescription(value);
                updateCurrentKeywordContent();
              }}
              margin="normal"
            ></TextField>
          )}
        </FormGroup>
        <DialogContent>
          <DialogActions>
            <Button onClick={() => setKeywordModalVisible(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleKeywordContentSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default CommonQA;
