// This file is for the Manage Content - Dos and Don'ts page
// Allows the web portal user to add dos and dont's based on
// risk area and business area

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import arrayMove from "array-move";
import React from "react";
//Sortable Imports
import { SortableContainer, SortableElement } from "react-sortable-hoc";

//
// This file creates shows two lists. One for DOs
// and one for DO NOTs. It allows the user to edit,
// add, and delete
//
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 20,
    width: "80%",
    flexGrow: 1
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    listStyle: "none"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    wordWrap: true
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  button: {
    position: "absolute",
    right: theme.spacing(6),
    top: theme.spacing(0)
  },
  close: {
    padding: theme.spacing(0.5)
  },
  indexer: {
    zIndex: "9999 !important"
  }
}));

function DoDont({ getDos, setDos, getDonts, setDonts }) {
  const classes = useStyles();
  const [count, setCount] = React.useState(20);
  const [addOpenDo, setAddOpenDo] = React.useState(false);
  const [addOpenDont, setAddOpenDont] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [currentID, setCurrentId] = React.useState("");
  const [deleteOpen, setDelOpen] = React.useState(false);
  const [delValue, setDelVal] = React.useState(0);
  const [newVal, setNewVal] = React.useState("");
  const [type, setType] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);

  const dos = getDos().map((value, index) => {
    return {
      id: index,
      value
    };
  });
  const donts = getDonts().map((value, index) => {
    return {
      id: index,
      value
    };
  });

  const handleClickOpen = type => event => {
    type === "do" ? setAddOpenDo(true) : setAddOpenDont(true);
  };

  const handleClose = () => {
    setNewVal("");
    setAddOpenDo(false);
    setAddOpenDont(false);
    setDelOpen(false);
    setEditOpen(false);
  };

  const addNewDo = () => {
    setCount(count + 1);
    // dos = [...dos, {id: count, value: newVal}];
    const regroupedDos = [...dos, { id: count, value: newVal }].map(({ value }) => value);
    setDos(regroupedDos);
    // setDos([...dos, { id: count, value: newVal }]);
    setAddOpenDo(false);
  };

  const addNewDont = () => {
    setCount(count + 1);
    // donts = [...donts, {id: count, value: newVal}];
    const regroupedDonts = [...donts, { id: count, value: newVal }].map(({ value }) => value);
    setDonts(regroupedDonts);
    setAddOpenDont(false);
  };

  const handleChange = event => {
    setNewVal(event.target.value);
  };

  const handleOpenEdit = (type, current) => event => {
    setType(type);
    setCurrentId(current.id);
    setNewVal(current.value);
    setEditOpen(true);
  };

  const handleEditChange = event => {
    if (type === "dos") {
      const index = dos.findIndex(i => i.id === currentID);
      dos[index].value = newVal;
      setEditOpen(false);
    } else if (type === "donts") {
      const index = donts.findIndex(i => i.id === currentID);
      donts[index].value = newVal;
      setEditOpen(false);
    }
  };

  const handleDelete = () => {
    if (type === "dos") {
      // dos = dos.filter(current => current.value !== delValue);
      const regroupedDos = dos
        .filter(current => current.value !== delValue)
        .map(({ value }) => value);
      setDos(regroupedDos);
    } else if (type === "donts") {
      // donts = donts.filter(current => current.value !== delValue);
      const _donts = donts.filter(current => current.value !== delValue).map(({ value }) => value);
      setDonts(_donts);
    }
    setSnackOpen(true);
    handleClose();
  };

  const handleOpenDelete = (valueDelete, type) => event => {
    setDelVal(valueDelete);
    setType(type);
    setDelOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const SortableDos = SortableElement(({ value }) => {
    return (
      <div style={{ zIndex: 9999 }}>
        <ListItem button onClick={handleOpenEdit("dos", value)}>
          <ListItemText primary={value.value} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={handleOpenDelete(value.value, "dos")}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  });

  const SortableListWork = SortableContainer(({ children }) => {
    return <List>{children}</List>;
  });

  const SortableDonts = SortableElement(({ value }) => {
    return (
      <div style={{ zIndex: 9999 }}>
        <ListItem button onClick={handleOpenEdit("donts", value)}>
          <ListItemText primary={value.value} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={handleOpenDelete(value.value, "donts")}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex, collection }) => {
    switch (collection) {
      case "dos":
        setDos(arrayMove(dos, oldIndex, newIndex).map(({ value }) => value));
        break;
      case "donts":
        setDonts(arrayMove(donts, oldIndex, newIndex).map(({ value }) => value));
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" noWrap>
        Work References
      </Typography>
      <Grid container spacing={2}>
        {/* List of DOs */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            DO
          </Typography>
          <div className={classes.demo}>
            <SortableListWork distance={1} axis="y" onSortEnd={onSortEnd} className={classes.demo}>
              {dos.map((current, i) => {
                return (
                  <SortableDos
                    key={`item-${current}`}
                    collection="dos"
                    index={i}
                    value={current}
                    className={classes.indexer}
                  />
                );
              })}
            </SortableListWork>
            <ListItem>
              <ListItemText primary="" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="addDo">
                  <AddIcon onClick={handleClickOpen("do")} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        </Grid>
        {/* List of DO NOTs */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            DO NOT
          </Typography>
          <div className={classes.demo}>
            <SortableListWork distance={1} axis="y" onSortEnd={onSortEnd} className={classes.demo}>
              {donts.map((current, i) => (
                <SortableDonts
                  key={`item-${current.id}`}
                  collection="donts"
                  index={i}
                  value={current}
                />
              ))}
            </SortableListWork>
            <ListItem>
              <ListItemText primary="" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="addDo">
                  <AddIcon onClick={handleClickOpen("dont")} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        </Grid>
      </Grid>
      {/* Add DO DIALOG */}
      <Dialog fullWidth="true" open={addOpenDo} aria-labelledby="add-dialog-title">
        <DialogTitle id="add-dialog-title">DO</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Enter the new "do" statement below.`}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="do"
            label="Do"
            fullWidth
            onChange={handleChange}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addNewDo}>Submit</Button>
        </DialogActions>
      </Dialog>
      {/* Add Don't DIALOG */}
      <Dialog fullWidth="true" open={addOpenDont} aria-labelledby="add-dialog-title">
        <DialogTitle id="add-dialog-title">DO NOT</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Enter the new "do not" statement below.`}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="dont"
            label="Do Not"
            fullWidth
            onChange={handleChange}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addNewDont}>Submit</Button>
        </DialogActions>
      </Dialog>
      {/* Edit item */}
      <Dialog fullWidth="true" open={editOpen} aria-labelledby="add-dialog-title">
        <DialogTitle id="add-dialog-title">Edit</DialogTitle>
        <DialogContent>
          <DialogContentText>Edit the statement below.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="edit"
            label="Do/Don't"
            fullWidth
            onChange={handleChange}
            required
            value={newVal}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleEditChange}>Submit</Button>
        </DialogActions>
      </Dialog>
      {/* DELETE DIALOG */}
      <Dialog open={deleteOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="del-dialog-title">Delete this item?</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete}>Submit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={snackOpen}
        autoHideDuration={3500}
        onClose={handleSnackClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Item Deleted</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="primary"
            className={classes.close}
            onClick={handleSnackClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}

export default DoDont;
