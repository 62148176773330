export default class OAuthStorage {
  static saveToken(tokens) {
    if (tokens !== undefined) {
      localStorage.setItem("accessToken", tokens.access_token);
      localStorage.setItem("refreshToken", tokens.refresh_token);
    }
  }

  static refreshToken() {
    localStorage.setItem("accessToken", localStorage.getItem("refreshToken"));
  }

  static clearTokens() {
    localStorage.setItem("accessToken", null);
    localStorage.setItem("refreshToken", null);
  }
}
