class QuestionDAO{
    constructor(obj){
        obj && Object.assign(this, obj);
    }

    setUserId(userId){
        this.userId = userId;
    }

    getUserId(){
        return this.userId;
    }

    setUserEmail(userEmail){
        this.userEmail = userEmail;
    }

    getUserEmail(){
        return this.userEmail;
    }

    setUserFirstName(fname){
        this.userFirstName = fname;
    }

    getUserFirstName(){
        return this.userFirstName;
    }

    setUserLastName(lName){
        this.userLastName = lName;
    }

    getUserLastName(){
        return this.userLastName;
    }

    setQuestionId(questionId){
        this.questionId = questionId;
    }

    getQuestionId(){
        return this.questionId;
    }

    setAssigneeId(assigneeId){
        this.assigneeId = assigneeId;
    }

    getAssigneeId(){
        return this.assigneeId;
    }

    setAssigneeEmail(assigneeEmail){
        this.assigneeEmail = assigneeEmail;
    }

    getAssigneeEmail(){
        return this.assigneeEmail;
    }

    setStatus(status){
        this.status = status;
    }

    getStatus(){
        return this.status;
    }

    setQuestionText(questionText){
        this.questionText = questionText;
    }

    getQuestionText(){
        return this.questionText;
    }

    setClosingComment(closingComment){
        this.closingComment = closingComment;
    }

    getClosingComment(){
        return this.closingComment;
    }

    setTransferComment(transferComment){
        this.transferComment = transferComment;
    }

    getTransferComment(){
        return this.transferComment;
    }

    setDivision(division){
        this.division = division;
    }

    getDivision(){
        return this.division;
    }

    setRiskArea(riskArea){
        this.riskArea = riskArea;
    }

    getRiskArea(){
        return this.riskArea;
    }

    setAskDate(askDate){
        if(askDate instanceof Date){
            this.askDate = askDate.toISOString();
        }else{
            this.askDate = askDate;
        }
    }

    getAskDate(){
        return this.askDate;
    }

    setAssignedDate(assignedDate){
        if(assignedDate instanceof Date){
            this.assignedDate = assignedDate.toISOString();
        }else{
            this.assignedDate = assignedDate;
        }
    }

    getAssignedDate(){
        return this.assignedDate;
    }

    setCloseDate(closeDate){
        if(closeDate instanceof Date){
            this.closeDate = closeDate.toISOString();
        }else{
            this.closeDate = closeDate;
        }
    }

    getCloseDate(){
        return this.closeDate;
    }

    setAcknowledgeDate(acknowledgeDate){
        if(acknowledgeDate instanceof Date){
            this.acknowledgeDate = acknowledgeDate.toISOString();
        }else{
            this.acknowledgeDate = acknowledgeDate;
        }
    }

    getAcknowledgeDate(){
        return this.acknowledgeDate;
    }

    toObject(input){
        this.setUserId(input.userId);
        this.setUserEmail(input.userEmail);
        this.setUserFirstName(input.userFirstName);
        this.setUserLastName(input.userLastName);
        this.setQuestionId(input.questionId);
        this.setAssigneeId(input.assigneeId);
        this.setAssigneeEmail(input.assigneeEmail);
        this.setStatus(input.status);
        this.setQuestionText(input.questionText);
        this.setClosingComment(input.closingComment);
        this.setTransferComment(input.transferComment);
        this.setDivision(input.division);
        this.setRiskArea(input.riskArea);
        this.setAskDate(input.askDate);
        this.setAssignedDate(input.assignedDate);
        this.setCloseDate(input.closeDate);
        this.setAcknowledgeDate(input.acknowledgeDate)
        return this;
    }


}

export default QuestionDAO;