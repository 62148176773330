import ContentDAO from "../models/content/ContentDAO";
import apiService from "./apiService";
import * as WTRWEndpoints from "./wtrw.api";

class LearningModuleService {
  static LearningModuleDTO(learningModules) {
    return learningModules.map(module => new ContentDAO().toObject(module));
  }

  static async getLearningModules(languageCode) {
    const response = await apiService.get(`${WTRWEndpoints.LEARNING_MODULES_ENDPOINT}&languageCode=${languageCode}`);
    const { data } = response;
    return this.LearningModuleDTO(data);
  }

  static async getRiskAreas(languageCode) {
    const response = await apiService.get(`${WTRWEndpoints.RISK_AREAS_ENDPOINT}&languageCode=${languageCode}`);
    const { data } = response;
    return this.LearningModuleDTO(data);
  }

  static async updateLearningModule(module) {
    const response = await apiService.post(WTRWEndpoints.LEARNING_MODULES_ENDPOINT, module);
    return response;
  }

  static async deleteLearningModule({ riskArea, contentType, identifier, languageCode }) {
    const endpointSuffix = `/${riskArea}/${contentType}/${identifier}/${languageCode}`;
    const response = await apiService.delete(WTRWEndpoints.RISK_AREA_ENDPOINT + endpointSuffix);
    return response;
  }

  static async deleteRiskArea({ riskArea, contentType, identifier, languageCode }) {
    const endpointSuffix = `/${riskArea}/${contentType}/${identifier}/${languageCode}`;
    const response = await apiService.delete(WTRWEndpoints.RISK_AREA_ENDPOINT + endpointSuffix);
    return response;
  } 
}

export default LearningModuleService;
