class DescriptionDAO{

    getDescription(){
        return this.description;
    }

    setDescription(val){
        this.description = val;
    }

    toObject(input){
        this.setDescription(input.description);
        return this;
    }
}

export default DescriptionDAO;