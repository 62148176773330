// This file is the Asked Questions portion of the page.
// this is the table that holds the questions submitted by the app user
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  makeStyles,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField
} from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStoreState } from "easy-peasy";
import MaterialTable from "material-table-jspdf-fix";
import Moment from "moment";
import React, { forwardRef, Fragment, useCallback, useEffect, useState } from "react";
import errorNotify from "./responseNotifyHelper";
import QuestionService from "../data/api/questionService";
import UserService from "../data/api/userService";
import QuestionDAO from "../data/models/question/QuestionDAO";
import UserProfileDAO from "../data/models/user/UserProfileDAO";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  tableRow: {
    "&$hover:hover": {
      backgroundColor: "blue"
    }
  },
  hover: {},
  menu: {
    width: 400
  }
}));

const topics = [
  {
    value: "Trade Compliance"
  },
  {
    value: "Anti-bribery"
  },
  {
    value: "Data Privacy"
  },
  {
    value: "Fair Competition"
  },
  {
    value: "Product Regulatory"
  },
  {
    value: "Governmental Contracts"
  },
  {
    value: "Conflicts of Interest"
  },
  {
    value: "General Compliance"
  },
  {
    value: "Other"
  }
];

const topicOptionsSet = new Set(topics.map(({ value }) => value));

const status = [
  {
    key: "New",
    value: "New"
  },
  {
    key: "Assigned",
    value: "Assigned"
  },
  {
    key: "In Progress",
    value: "InProgress"
  },
  {
    key: "Closed",
    value: "Closed"
  }
];

function getSteps() {
  return ["Question Received", "Question Assigned", "Question In Progress", "Question Closed"];
}

function QuestionsTable() {
  const [userAccessLevel] = useState(useStoreState(state => state.accessLevel.accessLevel));
  const [modalVisible, setModalVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(new QuestionDAO());
  const [assignees, setAssignees] = useState(new UserProfileDAO());
  const [validationStatus, setValidationStatus] = useState({
    topic: false,
    topicOther: false,
    status: false,
    division: false,
    errorsExist: false
  });
  const classes = useStyles();
  const isCommonTopic = topicOptionsSet.has(currentQuestion.getRiskArea());
  const dateFormat = "MMMM Do, YYYY h:mma";
  const requirdField = "This field is required.";

  useEffect(() => {
    setValidationStatus({
      topic: false,
      topicOther: false,
      status: false,
      division: false,
      errorsExist: false
    });
  }, [currentQuestion.questionId]);

  const checkFormValidation = useCallback(() => {
    const validationStatusClone = { ...validationStatus };
    let formHasErrors = false;
    if (!currentQuestion.getRiskArea()) {
      validationStatusClone.topic = true;
      formHasErrors = true;
    } else {
      validationStatusClone.topic = false;
    }
    if (
      !currentQuestion.getRiskArea() ||
      currentQuestion.getRiskArea().length === 0 ||
      currentQuestion.getRiskArea() === "Other"
    ) {
      validationStatusClone.topicOther = true;
      formHasErrors = true;
    } else {
      validationStatusClone.topicOther = false;
    }
    if (!currentQuestion.getStatus()) {
      validationStatusClone.status = true;
      formHasErrors = true;
    } else {
      validationStatusClone.status = false;
    }
    if (!currentQuestion.getDivision() || currentQuestion.getDivision().length === 0) {
      validationStatusClone.division = true;
      formHasErrors = true;
    } else {
      validationStatusClone.division = false;
    }
    validationStatusClone.errorsExist = !formHasErrors
      ? false
      : validationStatusClone.errorsExist || formHasErrors;
    setValidationStatus(validationStatusClone);
    if (validationStatusClone.errorsExist) return false;
    else return true;
  }, [currentQuestion, validationStatus]);

  useEffect(() => {
    Moment.locale("en");
    getQuestions(userAccessLevel);
    getAssignees();
  }, [userAccessLevel]);

  const getAssignees = () => {
    try {
      UserService.getAssignees().then(users => {
        setAssignees(users);
      });
    } catch (error) {
      errorNotify(error);
    }
  };

  const getQuestions = userLevel => {
    try {
      QuestionService.getQuestions(userLevel).then(questions => {
        setQuestions(questions);
      });
    } catch (error) {
      errorNotify(error);
    }
  };

  const getAssigneeUser = assigneId => {
    const assignee = assignees.find(o => o.getCatRecId() === assigneId);
    return assignee;
  };

  const updateCurrentQuestion = () => {
    const update = new QuestionDAO(currentQuestion);
    setCurrentQuestion(update);
  };

  const updateAssignee = (event, values) => {
    if (values) {
      currentQuestion.setAssigneeEmail(values.getEmail());
      currentQuestion.setAssigneeId(values.getCatRecId());
      updateCurrentQuestion();
    }
  };

  const handleRowClick = rowData => {
    const question = new QuestionDAO(rowData);
    setCurrentQuestion(question);
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleSubmit = () => {
    const copy = Object.assign({}, currentQuestion);
    delete copy.tableData;
    if (checkFormValidation()) {
      QuestionService.updateQuestion(copy).then(() => getQuestions(userAccessLevel))
        .catch(error => {
          errorNotify(error);
        });
      setModalVisible(false);
    }
  };

  const validateForm = () => {
    switch (currentQuestion.getStatus()) {
      case "Closed":
        return true;
      case "Assigned":
        if (currentQuestion.getRiskArea() && currentQuestion.getAssigneeEmail()) {
          return true;
        }
        return false;
      default:
        return true;
    }
  };

  const getActiveStep = () => {
    switch (currentQuestion.getStatus()) {
      case "New":
        return 0;
      case "Assigned":
        return 1;
      case "InProgress":
        return 2;
      case "Closed":
        return 3;
      default:
        return 0;
    }
  };

  const steps = getSteps();

  const setDefaultValue = (isCommonTopic, currentQuestion) => {
    !isCommonTopic && currentQuestion.getRiskArea() === "Other"
      ? ""
      : currentQuestion.getRiskArea() === "Other"
        ? ""
        : currentQuestion.getRiskArea()
  }

  return (
    <Fragment>
      <MaterialTable
        title="Questions"
        icons={tableIcons}
        columns={[
          { title: "ID", field: "questionId", editable: "never" },
          {
            title: "Submitted By",
            field: "userFirstName",
            editable: "never",
            render: rowData => (
              <p>
                {rowData.userFirstName} {rowData.userLastName}
                <br />
                <span style={{ color: "#8C8C8C" }}>{rowData.userEmail}</span>
              </p>
            )
          },
          {
            title: "Date Submitted",
            field: "askDate",
            editable: "never",
            render: rowData => <p>{Moment(rowData.askDate).format(dateFormat)}</p>
          },
          {
            title: "Topic",
            field: "riskArea"
          },
          { title: "Question", field: "questionText", editable: "never" },
          { title: "Status", field: "status" },
          { title: "Division", field: "division" },
          { title: "Assignee", field: "assigneeEmail" }
        ]}
        data={questions}
        options={{
          grouping: true,
          exportButton: true
        }}
        onRowClick={(event, selectedRow) => handleRowClick(selectedRow)}
      />
      <Dialog
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{currentQuestion.getQuestionText()}</DialogTitle>
        <DialogContent>
          <Stepper activeStep={getActiveStep()} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  {label} <br />
                  {index === 0 && currentQuestion.getAskDate()
                    ? Moment(currentQuestion.getAskDate()).format(dateFormat)
                    : ""}
                  {index === 1 && currentQuestion.getAssignedDate()
                    ? Moment(currentQuestion.getAssignedDate()).format(dateFormat)
                    : ""}
                  {index === 2 && currentQuestion.getAcknowledgeDate()
                    ? Moment(currentQuestion.getAcknowledgeDate()).format(dateFormat)
                    : ""}
                  {index === 3 && currentQuestion.getCloseDate()
                    ? Moment(currentQuestion.getCloseDate()).format(dateFormat)
                    : ""}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <FormGroup>
            <TextField
              margin="dense"
              id="submitterTB"
              label="Submitted By"
              fullwidth
              disabled
              value={currentQuestion.getUserFirstName()}
            />
            <TextField
              margin="dense"
              id="dateTB"
              label="Date Submitted"
              fullwidth
              disabled
              value={Moment(currentQuestion.getAskDate()).format(dateFormat)}
            />
            <TextField
              margin="dense"
              id="topicTB"
              label="Topic"
              fullwidth
              select
              required
              helperText={validationStatus.topic && requirdField}
              error={validationStatus.topic}
              value={
                currentQuestion.getRiskArea()
                  ? isCommonTopic
                    ? currentQuestion.getRiskArea()
                    : "Other"
                  : ""
              }
              onChange={event => {
                const { value } = event.target;
                currentQuestion.setRiskArea(value);
                updateCurrentQuestion();
                validationStatus.topic && checkFormValidation();
              }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
            >
              {topics.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            {((currentQuestion.getRiskArea() && !isCommonTopic) ||
              currentQuestion.getRiskArea() === "Other") && (
                <TextField
                  margin="dense"
                  id="otherRiskAreaT"
                  label="Topic - Other"
                  fullwidth
                  multiline
                  required
                  helperText={validationStatus.topicOther && requirdField}
                  error={validationStatus.topicOther}
                  defaultValue={setDefaultValue(isCommonTopic,currentQuestion)}
                  onChange={event => {
                    const { value } = event.target;
                    currentQuestion.setRiskArea(value);
                    updateCurrentQuestion();
                  }}
                  onBlur={() => {
                    validationStatus.topicOther && checkFormValidation();
                  }}
                />
              )}
            <TextField
              margin="dense"
              id="statusTB"
              label="Status"
              fullwidth
              select
              required
              value={currentQuestion.getStatus()}
              helperText={validationStatus.status && requirdField}
              error={validationStatus.status}
              onChange={event => {
                const { value } = event.target;
                currentQuestion.setStatus(value);
                updateCurrentQuestion();
                validationStatus.status && checkFormValidation();
              }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
            >
              {status.map(option => (
                <MenuItem key={option.key} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              id="divTB"
              label="Division"
              fullwidth
              required
              helperText={validationStatus.division && requirdField}
              error={validationStatus.division}
              value={currentQuestion.getDivision()}
              onChange={event => {
                const { value } = event.target;
                currentQuestion.setDivision(value);
                updateCurrentQuestion();
              }}
              onBlur={() => {
                validationStatus.division && checkFormValidation();
              }}
            />
            {currentQuestion.getStatus() === "Assigned" && (
              <Autocomplete
                id="combo-box-demo"
                options={assignees}
                getOptionLabel={option => option.getFullName()}
                renderInput={params => (
                  <TextField {...params} label="Assignee" margin="dense" fullWidth />
                )}
                onChange={updateAssignee}
                value={getAssigneeUser(currentQuestion.getAssigneeId())}
              />
            )}
            {currentQuestion.getStatus() === "Assigned" && (
              <TextField
                margin="dense"
                id="commentsTB"
                label="Comments"
                fullwidth
                multiline
                onChange={event => {
                  const { value } = event.target;
                  currentQuestion.setTransferComment(value);
                  updateCurrentQuestion();
                }}
                value={currentQuestion.getTransferComment()}
              />
            )}
            {currentQuestion.getStatus() === "Closed" && (
              <TextField
                margin="dense"
                id="closingCommentsTB"
                label="Closing Comments"
                fullwidth
                multiline
                onChange={event => {
                  const { value } = event.target;
                  currentQuestion.setClosingComment(value);
                  updateCurrentQuestion();
                }}
                value={currentQuestion.getClosingComment()}
                helperText="Closing Comments are optional when status changes to Closed"
              />
            )}
          </FormGroup>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={!validateForm()}>
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default QuestionsTable;
