class DoDoNotDAO {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  setDos(dos) {
    this.dos = dos;
  }

  getDos() {
    return this.dos;
  }

  setDoNots(doNots) {
    this.doNots = doNots;
  }

  getDoNots() {
    return this.doNots;
  }

  toObject(input) {
    const dos = [];
    if (input.dos) {
      input.dos.forEach(ado => {
        dos.push(ado);
      });
    }
    this.setDos(dos);

    const doNots = [];
    if (input.doNots) {
      input.doNots.forEach(doNot => {
        doNots.push(doNot);
      });
    }
    this.setDoNots(doNots);

    return this;
  }
};

export default DoDoNotDAO;
