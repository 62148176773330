class ResourceDAO{

    setTitle(title){
        this.title = title;
    }

    getTitle(){
        return this.title;
    }

    setType(type){
        this.type = type;
    }

    getType(){
        return this.type;
    }

    setLocation(location){
        this.location = location;
    }

    getLocation(){
        return this.location;
    }

    toObject(input){
        this.setTitle(input.title);
        this.setType(input.type);
        this.setLocation(input.location);
        return this;
    }
}

export default ResourceDAO;