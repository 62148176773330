import UserFeedbackDAO from '../user/UserFeedbackDAO';
import UserModuleProgressDAO from '../user/UserModuleProgressDAO';
import UserProfileDAO from '../user/UserProfileDAO';

const UserMetadataTypes = require('../../constants/UserMetadataTypes');

class UserDAO{
    constructor(obj){
        obj && Object.assign(this, obj);
    }

    setUserId(userId){
        this.userId = userId;
    }

    getUserId(){
        return this.userId
    }

    setMetadataType(metadataType){
        this.metadataType = metadataType;
    }

    getMetadataType(){
        return this.metadataType;
    }

    setUserData(userData){
        this.userData = userData;
    }

    getUserData(){
        return this.userData;
    }

    toObject(input){
        this.setUserId(input.userId);
        this.setMetadataType(input.metadataType);
        switch(this.metadataType){
            case UserMetadataTypes.PROFILE :{
                this.setUserData(new UserProfileDAO().toObject(input.userData));
                break;
            }
            case UserMetadataTypes.FEEDBACK :{
                this.setUserData(new UserFeedbackDAO().toObject(input.userData));
                break;
            }
            case UserMetadataTypes.MODULEPROGRESS :{
                this.setUserData(new UserModuleProgressDAO().toObject(input.userData));
                break;
            }
            default:{
                throw new Error("User type not supported");
            }
        }
        return this;
    }
}

export default UserDAO;