function define(name, value) {
  Object.defineProperty(exports, name, {
    value: value,
    enumerable: true
  });
}

define("PROFILE", "Profile");
define("MODULEPROGRESS", "ModuleProgress");
define("FEEDBACK", "Feedback");
