import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Fab,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LocalPizzaIcon from "@material-ui/icons/LocalPizza";
import arrayMove from "array-move";
import React from "react";
// drag and drop
import { SortableContainer,SortableElement } from "react-sortable-hoc";

import CardLogic from "../components/customModules/cardLogic";
import CommonConstants from "../data/constants/CommonConstants";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexWrap: "wrap",
    marginTop: 1,
    dislay: "flex"
  },
  gridContainer: {
    flexWrap: "nowrap",
    direction: "row",
    color: theme.palette.text.secondary,
    overflow: "scroll",
    width: "75%"
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: 15,
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: 175,
    height: 175,
    overflow: "scroll"
  },
  cardPaper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: 250,
    height: 400,
    overflow: "scroll"
  },
  button: {
    position: "relative",
    bottom: 0
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
}));

const drawerWidth = 240;

const languages = [
  { value: "zh", label: "Chinese (Simplified)" },
  { value: "nl", label: "Dutch" },
  { value: "en", label: "English" },
  { value: "fr", label: "French" },
  { value: "de", label: "German" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "pt", label: "Portuguese" },
  { value: "ru", label: "Russian" },
  { value: "es", label: "Spanish" }
];

const riskAreas = [
  {
    value: "tradeCompliance",
    label: "Trade Compliance"
  },
  {
    value: "antiBribery",
    label: "Anti-bribery"
  },
  {
    value: "dataPrivacy",
    label: "Data Privacy"
  },
  {
    value: "environmentHealthSafety",
    label: "Environment, Healthy, and Safety"
  },
  {
    value: "productReg",
    label: "Product Regulatory"
  },
  {
    value: "govContracts",
    label: "Governmental Contracts"
  },
  {
    value: "conflictsOfInterests",
    label: "Conflicts of Interest"
  },
  {
    value: "generalCompliance",
    label: "General Compliance"
  }
];

function CustomModules() {
  const classes = useStyles();
    // eslint-disable-next-line
  const [data, setData] = React.useState([
    {
      riskArea: CommonConstants.ANTIBRIBERY,
      title: "Anti-bribery Pack #1",
      level: "Learning Modules",
      levelOrder: 0,
      cards: [
        {
          title: "Anti-bribery Compliance",
          iconKey: "key1",
          text:
            "Pack #1: We the people of the united states of america, in order to form a more perfect union",
          order: 0
        },
        {
          title: "Business Courtesies",
          iconKey: "key2",
          text:
            "Pack #1: establish justice promote domestic traquility. Provide for the common defense.",
          order: 1
        },
        {
          title: CommonConstants.FACILITATIONPAYMENTS,
          iconKey: "key3",
          text:
            "Pack #1: Promote the general welfare and insure the justice of liberty to ourselves and our prosperity",
          order: 2
        }
      ]
    },
    {
      riskArea: CommonConstants.ANTIBRIBERY,
      title: "Bribery (Third Parties) Pack #2",
      level: "Learning Modules",
      levelOrder: 1,
      cards: [
        {
          title: CommonConstants.THIRDPARTYINTERMEDIARIES,
          iconKey: "key1",
          text:
            "Pack #2: We the people of the united states of america, in order to form a more perfect union",
          order: 0
        },
        {
          title: "",
          iconKey: "key2",
          text:
            "Pack #2 establish justice promote domestic traquility. Provide for the common defense.",
          order: 1
        },
        {
          title: CommonConstants.NOFACILITATIONPAYMENTS,
          iconKey: "key3",
          text:
            "Pack #2 Promote the general welfare and insure the justice of liberty to ourselves and our prosperity",
          order: 2
        }
      ]
    },
    {
      riskArea: CommonConstants.ANTIBRIBERY,
      title: "Bribery (Third Parties) Pack #3",
      level: "Scenarios",
      levelOrder: 0,
      cards: [
        {
          title: CommonConstants.THIRDPARTYINTERMEDIARIES,
          iconKey: "key1",
          text:
            "Pack #3: We the people of the united states of america, in order to form a more perfect union",
          order: 0
        },
        {
          title: "",
          iconKey: "key2",
          text:
            "Pack #3 establish justice promote domestic traquility. Provide for the common defense.",
          order: 1
        },
        {
          title: CommonConstants.NOFACILITATIONPAYMENTS,
          iconKey: "key3",
          text:
            "Pack #3 Promote the general welfare and insure the justice of liberty to ourselves and our prosperity",
          order: 2
        }
      ]
    }
  ]);

  const [items, setItems] = React.useState([
    { id: 1, value: "Visa Invitation Letters" },
    { id: 2, value: "Third Party Intermediaries" },
    { id: 3, value: "Intro to Anti-Bribery" }
  ]);
  // eslint-disable-next-line
  const [cards, setCards] = React.useState({
    1: [
      {
        id: 70,
        title:
          "How do I provide a compliant visa invitation letter to a non-US goverment official?",
        icon: "<Icon3/>",
        body:
          "If Caterpillar issues a visa invitation letter for a vacation, side trip, or other activities unrelated to Caterpillar business, the invitation letter could be considered a bribe. This pack will help you prepare and issue a compliant visa invitation letter."
      },
      {
        id: 71,
        title:
          "How do I provide a compliant visa invitation letter to a non-US government official",
        icon: "<Icon1/>",
        body:
          "First you should collect information needed to prepare an accurate visa invitation letter, such as the visitors name, duration of stay, and business purpose for the trip."
      },
      {
        id: 76,
        title:
          "How do I provide a compliant visa invitation letter to a non-US government official",
        icon: "<Icon4/>",
        body:
          "Next you should obtain a detailed, written itinerary for the visit. Remember to confirm that the visa invitation letter is being issued for a specific number of days during which business will actually be conducted. Caterpillar-sponsored side trips and vacations are not permitted."
      },
      {
        id: 78,
        title:
          "How do I provide a compliant visa invitation letter to a non-US government official?",
        body:
          "Win the right way! Don't let visa invitation letters lead to improper payments or inappropriate business courtesies. Have questions? Just Ask or review the additional resources here."
      }
    ],
    2: [
      { id: 72, title: "This is item2's title", icon: "<Icon1/>", body: "How about more for item2" }
    ],
    3: [
      {
        id: 73,
        title: "This is the item3's Title! ",
        icon: "<Icon5/>",
        body: "How about this text for 3"
      },
      {
        id: 74,
        title: "Another title for 3!",
        icon: "<Icon2/>",
        body: "Here is the text for p3c2"
      },
      { id: 75, title: "What about this header for 3 too?", icon: "<Icon1/>", body: "Sure thing! " }
    ]
  });
  const [currentCards, setCurrentCards] = React.useState([]);

  // eslint-disable-next-line
  const [showList, setShow] = React.useState(false);
  // Dialog fields
  const [count, setCount] = React.useState(85);

  // packs
  const [currentVals, setCurrentVals] = React.useState({
    newTitle: "",
    newIcon: "",
    newBody: ""
  });
  const [currentPackName, setCurrentPackName] = React.useState("");
  const [selPack, setSelPack] = React.useState(0);
  const [editPackOpen, setEditPackOpen] = React.useState(false);

  //cards
  // eslint-disable-next-line
  const [selCard, setSelCard] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDelOpen] = React.useState(false);
  const [delItem, setDelItem] = React.useState();
  const [editOpen, setEditOpen] = React.useState(false);

  // This is the drag and drop sort stuff
  const SortablePack = SortableElement(({ value }) => {
    return (
      <Grid key={value.id} item>
        <Paper className={classes.paper}>
          <Typography>{value.value}</Typography>
          <IconButton onClick={handlePackEditOpen(value)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleExpandClick(value.id);
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Paper>
      </Grid>
    );
  });

  const SortableList = SortableContainer(({ children }) => {
    return (
      <Grid className={classes.gridContainer} cols={2.5} spacing={2} container>
        {children}
      </Grid>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex, collection }) => {
    switch (collection) {
      case "packs":
        setItems(arrayMove(items, oldIndex, newIndex));
        break;
      case "cards":
        var temp = arrayMove(currentCards, oldIndex, newIndex);
        setCurrentCards(temp);
        cards[currentPackName] = currentCards;
        console.log(cards[currentPackName]);
        console.log("Current Card value " + currentCards);
        break;
      default:
        break;
    }
  };

  // Handles button to show the cards
  const handleExpandClick = value => {
    setCurrentPackName(value);
    if (cards[value]) {
      setCurrentCards(cards[value]);
      setShow(true);
    }
  };

  const SortableCard = SortableElement(({ value }) => {
    return (
      <CardLogic value={value}></CardLogic>
    );
  });

  const SortableCardList = SortableContainer(({ children }) => {
    return (
      <Grid className={classes.gridContainer} cols={2.5} spacing={2} container>
        {children}
      </Grid>
    );
  });

  // returns the current cards for that pack
  const getCards = () => {
    return (
      <SortableCardList distance={1} axis="x" onSortEnd={onSortEnd}>
        {currentCards.map((current, i) => (
          <SortableCard key={`item-${current.id}`} collection="cards" index={i} value={current} />
        ))}
        <Fab aria-label="add" className={classes.fab} onClick={openAdd}>
          <AddIcon />
        </Fab>
      </SortableCardList>
    );
  };

  // use to close the add or edit dialogs
  const handleClose = () => {
    setEditOpen(false);
    setOpen(false);
    setEditPackOpen(false);
  };

  // Opens dialog to add new card
  const openAdd = event => {
    setOpen(true);
  };

  // Handles submitting new card
  const handleAddNew = event => {
    setCount(count + 1);
    setCurrentCards([
      ...currentCards,
      {
        id: count,
        title: currentVals.newTitle,
        icon: currentVals.newIcon,
        body: currentVals.newBody
      }
    ]);
    setOpen(false);
  };

  // Handles opening the edit pack dialog
  const handlePackEditOpen = pack => event => {
    setSelPack(pack);
    setCurrentVals({ newTitle: pack.value });
    setEditPackOpen(true);
  };

  // Handles the submit on the edit dialog for packs
  const handleEditPackChange = event => {
    const index = items.findIndex(i => i.id === selPack.id);
    items[index].value = currentVals.newTitle;
    setEditPackOpen(false);
  };

  const [values, setValues] = React.useState({
    topic: [],
    riskArea: "",
    contentType: ""
  });

  const handleTopicChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const copyToClipboard = text => event => {
    navigator.clipboard.writeText(text);
  };
  
  // to move to card logic
  // Opens the edit cards dialog
  // eslint-disable-next-line
  const handleEditOpen = event => {
    // alert("Edit open was chosen");
  };

  // Handles Dialog text box edits
  const handleChange = name => event => {
    setCurrentVals({ ...currentVals, [name]: event.target.value });
  };

  // Handles submit on edit dialog for cards
  const handleEditChange = event => {
    const index = currentCards.findIndex(i => i.id === selCard.id);
    currentCards[index].title = currentVals.newTitle;
    currentCards[index].icon = currentVals.newIcon;
    currentCards[index].body = currentVals.newBody;
    setEditOpen(false);
  };

  // opens the delete dialog
  // eslint-disable-next-line
  const handleOpenDelete = currentID => event => {
    setDelItem(currentID);
    setDelOpen(true);
  };

  // delete is cancelled
  const handleDelClose = () => {
    setDelOpen(false);
  };

  // handles delete
  const handleDelete = type => event => {
    switch (type) {
      case "cards":
        var temp = currentCards.filter(current => current.id !== delItem);
        setCurrentCards(temp);
        cards[currentPackName] = currentCards;
        break;
      case "packs":
        break;
      default:
        break;
    }
    setDelOpen(false);
  };

  return (
    <div>
      <CssBaseline />
      <div>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          <List>
            {["<Icon1/>", "<Icon2/>", "<Icon3/>", "<Icon4/>", "<Icon5/>"].map((text, index) => (
              <ListItem button onClick={copyToClipboard(text)} key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <LocalPizzaIcon /> : <FastfoodIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Typography variant="h6" noWrap>
          Module Questions
        </Typography>
        <FormGroup className={classes.container} noValidate autoComplete="off">
          <TextField
            id="select-language"
            select
            label="Language"
            className={classes.textField}
            value={values.languages}
            onChange={handleChange("language")}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            margin="normal"
          >
            {languages.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="standard-select-riskArea"
            select
            label="Risk Area"
            className={classes.textField}
            value={values.riskArea}
            onChange={handleTopicChange("riskArea")}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            helperText="Please choose Risk Area"
            margin="normal"
          >
            {riskAreas.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
      </div>
      <br />
      <div>
        <SortableList distance={1} axis="x" onSortEnd={onSortEnd}>
          {items.map((current, i) => (
            <SortablePack key={`item-${current.id}`} collection="packs" index={i} value={current} />
          ))}
        </SortableList>
      </div>
      <br />
      <div>{getCards()}</div>

      {/* Dialog code starts  */}
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Card Text</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the card text below.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="titleEdit"
            label="Above Icon"
            fullWidth
            multiline
            onChange={handleChange("newTitle")}
          />
          <TextField
            margin="dense"
            id="iconEdit"
            label="Icon"
            fullWidth
            onChange={handleChange("newIcon")}
          />
          <TextField
            margin="dense"
            id="bodyEdit"
            label="Below Icon"
            fullWidth
            multiline
            onChange={handleChange("newBody")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancel </Button>
          <Button onClick={handleAddNew}> Submit </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} aria-labelledby="edit-dialog-title">
        <DialogTitle id="edit-dialog-title">Edit Card Text</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="titleEdit"
            label="Above Icon"
            fullWidth
            multiline
            value={currentVals.newTitle}
            onChange={handleChange("newTitle")}
          />
          <TextField
            autoFocus
            margin="dense"
            id="iconEdit"
            label="Icon"
            fullWidth
            multiline
            value={currentVals.newIcon}
            onChange={handleChange("newIcon")}
          />
          <TextField
            margin="dense"
            id="bodyEdit"
            label="Below Icon"
            fullWidth
            multiline
            value={currentVals.newBody}
            onChange={handleChange("newBody")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancel </Button>
          <Button onClick={handleEditChange}> Save </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} aria-labelledby="form-delete">
        <DialogTitle id="del-dialog-title">Delete this card?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelClose}>Cancel</Button>
          <Button onClick={handleDelete("cards")}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editPackOpen} aria-labelledby="form-pack-edit">
        <DialogTitle id="del-pack-title">Edit Pack Name </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="titleEdit"
            label="Pack Name"
            fullWidth
            multiline
            value={currentVals.newTitle}
            onChange={handleChange("newTitle")}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancel </Button>
          <Button onClick={handleEditPackChange}> Save </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomModules;

