class MatrixDAO {
  setYes(yes) {
    this.yes = yes;
  }

  getYes() {
    return this.yes;
  }

  setNo(no) {
    this.no = no;
  }

  getNo() {
    return this.no;
  }

  setSection(section) {
    this.section = section;
  }

  getSection() {
    return this.section;
  }

  setFinal(final) {
    this.final = final;
  }

  getFinal() {
    return this.final;
  }

  toObject(input) {
    this.setNo(input.no);
    this.setYes(input.yes);
    this.setSection(input.section);
    this.setFinal(input.final);
    return this;
  }
}

export default MatrixDAO;
