import SectionDAO from "./SectionDAO";

class ModuleDAO {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  setIconKey(iconKey) {
    this.iconKey = iconKey;
  }

  getIconKey() {
    return this.iconKey;
  }

  setTitle(title) {
    this.title = title;
  }

  getTitle() {
    return this.title;
  }

  setSections(sections) {
    this.sections = sections;
  }

  getSections() {
    return this.sections;
  }

  setLevel(level) {
    this.level = level;
  }

  getLevel() {
    return this.level;
  }

  setLevelOrder(levelOrder) {
    this.levelOrder = levelOrder;
  }

  getLevelOrder() {
    return this.levelOrder;
  }

  toObject(input) {
    this.setIconKey(input.iconKey);
    this.setTitle(input.title);
    const sections = [];
    if (input.sections) {
      input.sections.forEach(section => {
        sections.push(new SectionDAO().toObject(section));
      });
    }
    this.setSections(sections);
    this.setLevel(input.level);
    this.setLevelOrder(input.levelOrder);
    return this;
  }
}

export default ModuleDAO;
