class UserProfileDAO{
    constructor(obj){
        obj && Object.assign(this, obj);
    }

    setCWS(catloginid){
        this.catloginid = catloginid;
    }
    
    getCWS(){
        return this.catloginid;
    }

    setPermissionLevel(permissionLevel){
        this.permissionLevel = permissionLevel;
    }

    getPermissionLevel(){
        return this.permissionLevel;
    }

    getFirstJoinedDate(){
        return this.firstJoinedDate;
    }

    setFirstJoinedDate(firstJoinedDate){
        if(firstJoinedDate instanceof Date){
            this.firstJoinedDate = firstJoinedDate.toISOString();
        }else{
            this.firstJoinedDate = firstJoinedDate;
        }
    }

    setLastUpdatedDate(lastUpdatedDate){
        if(lastUpdatedDate instanceof Date){
            this.lastUpdatedDate = lastUpdatedDate.toISOString();
        }else{
            this.lastUpdatedDate = lastUpdatedDate;
        }
    }

    getLastUpdatedDate(){
        return this.lastUpdatedDate;
    }

    setAffiliationOrgCode(catafltnorgcode){
        this.catafltnorgcode = catafltnorgcode;
    }

    getAffiliationOrgCode(){
        return this.catafltnorgcode;
    }

    setEmail(email){
        this.catbusinessmail = email;
    }

    getEmail(){
        return this.catbusinessmail;
    }

    setGivenName(givenName){
        this.givenname = givenName;
    }

    getGivenName(){
        return this.givenname;
    }

    setSurname(surname){
        this.sn = surname;
    }

    getSurname(){
        return this.sn;
    }

    setFullName(givenname, surname){
        this.fullname = givenname + " " + surname
    }

    getFullName(){
        return this.fullname;
    }

    setCatRecId(catrecid){
        this.catrecid = catrecid;
    }

    getCatRecId(){
        return this.catrecid;
    }

    setCatAffiliationCode(catafltncode){
        this.catafltncode = catafltncode
    }

    getCatAffiliationCode(){
        return this.catafltncode;
    }

    setDivision(division){
        this.division = division;
    }

    getDivision(){
        return this.division;
    }

    setDivisionCode(divisionCode){
        this.divisionCode = divisionCode;
    }

    getDivisionCode(){
        return this.divisionCode;
    }

    toObject(input){
        this.setCWS(input.catloginid);
        this.setPermissionLevel(input.permissionLevel);
        this.setFirstJoinedDate(input.firstJoinedDate);
        this.setLastUpdatedDate(input.lastUpdatedDate);
        this.setAffiliationOrgCode(input.catafltnorgcode);
        this.setEmail(input.catbusinessmail);
        this.setGivenName(input.givenname);
        this.setSurname(input.sn);
        this.setFullName(input.givenname, input.sn);
        this.setCatRecId(input.catrecid);
        this.setCatAffiliationCode(input.catafltncode);
        this.setDivision(input.division);
        this.setDivisionCode(input.divisionCode);
        return this;
    }

}

export default UserProfileDAO;