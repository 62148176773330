import React, { useState } from "react";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles} from "@material-ui/core";
import { DebounceInput } from "react-debounce-input";

const useStyles = makeStyles(theme => ({
        csearch :{
        position: 'relative',
        zIndex: '1',
        float: 'right !important',
        display: 'flex',
        marginTop: '1%',
        marginRight: '10px'
    },
    csearchinput:{
        padding: '9px 12px',
        paddingLeft:'25px',
        border: 'none',
        fontSize: '15px',
        width:'280px',
        borderBottom: '1px solid black',
        "&:focus": {
            outline: 'none'
        }
    },
    csearchIcon:
    {
        fontSize:'22px',
        position: 'absolute !important',
        top: '0 !important',
        left: '0 !important',
        marginTop: '5px !important'

    },
    ccloseIcon:
    {
        position: 'absolute',
        top: '0px',
        marginTop: '5px',
        marginLeft: '-24px',
        cursor:"pointer"
    }
  }));


export default function CustomSearchBar(props)
{
    const classes= useStyles();
    const [opac,setOpac]=useState(0.4);

    const visibilityHandler=(val)=>{
        props.changehandler(val);
        if(val.length!==0){
            setOpac(1);
        }
        else{
            setOpac(0.4);
        }
    }

    const visibilityHandlerforCancel=()=>{
        props.cancelHandler();
        setOpac(0.4);
    }
    return(
        <>
        <div className={classes.csearch} container xs="auto">
            <div className="=searchInput">
                <DebounceInput className={classes.csearchinput} type="text" onChange={(e)=>visibilityHandler(e.target.value)}  placeholder="Search" value={props.value} minLength={3} debounceTimeout={500}/>

                <span >
                    <SearchIcon className={classes.csearchIcon}/>
                </span>
                <span>
                    <CloseIcon className={classes.ccloseIcon} style={{ opacity: opac}} onClick={visibilityHandlerforCancel}/>
                </span>
            </div>
        </div>   
        </>
    );
}