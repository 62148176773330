class MaxtrixSectionDAO {
  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this.title = title;
  }

  setIconKey(iconKey) {
    this.iconKey = iconKey;
  }

  getIconKey() {
    return this.iconKey;
  }

  setText(text) {
    this.text = text;
  }

  getText() {
    return this.text;
  }

  setOrder(order) {
    this.order = order;
  }

  getOrder() {
    return this.order;
  }

  setFinal(final) {
    this.final = final;
  }

  getFinal() {
    return this.final;
  }

  toObject(input) {
    this.setTitle(input.title);
    this.setIconKey(input.iconKey);
    this.setText(input.text);
    this.setOrder(input.order);
    this.setFinal(input.final);
    if (input.order === 2) {
      this.setFinal(true);
    }
    return this;
  }
}

export default MaxtrixSectionDAO;
