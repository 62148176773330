import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import arrayMove from "array-move";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import ImageResource from "../data/imageResource";
import SectionDAO from "../data/models/content/SectionDAO";

function encodeHTMLText(str) {
  let a = str || "";
  let result = "";
  const wsRegex = /&nbsp;/gi;
  a = a.replace(wsRegex, " ");
  const arr = [...a];
  let alreadyNewline = false;

  arr.forEach((elem, index) => {
    if (index === 0) {
      result += "<p>";
    } else if (a.charCodeAt(index) === 10 && !alreadyNewline) {
      alreadyNewline = true;
      result += "</p><p><br></p>";
    } else if (a.charCodeAt(index) === 10 && alreadyNewline) {
      alreadyNewline = true;
      result += "<p><br></p>";
    }
    if (a.charCodeAt(index) !== 10) {
      if (alreadyNewline) result += "<p>";
      alreadyNewline = false;
      result += elem;
    }
    if (index === arr.length - 1) {
      result += "</p>";
    }
  });

  return result;
}

function decodeHTMLText(str) {
  const a = str || "";
  const pRegex = /<p>/gi;
  const lpRegex = /<\/p>/gi;
  const pbrRegex = /<p><br><\/p>/gi;
  const brRegex = /<br>/gi;
  const spaceRegex = /&nbsp;/gi;

  const result = a
    .replace(pRegex, "")
    .replace(lpRegex, "")
    .replace(pbrRegex, "\n")
    .replace(brRegex, "\n")
    .replace(spaceRegex, "");
  return result;
}

const IconList = [
  {
    value: "trophyIcon",
    Component: ImageResource.getIconComponent("trophyIcon")
  },
  {
    value: "reminderIcon",
    Component: ImageResource.getIconComponent("reminderIcon")
  },
  {
    value: "tipIcon",
    Component: ImageResource.getIconComponent("tipIcon")
  },
  {
    value: "warningIcon",
    Component: ImageResource.getIconComponent("warningIcon")
  },
  {
    value: "bestPracticesIcon",
    Component: ImageResource.getIconComponent("bestPracticesIcon")
  },
  {
    value: "seekIcon",
    Component: ImageResource.getIconComponent("seekIcon")
  },
  {
    value: "antiIcon",
    Component: ImageResource.getIconComponent("antiIcon")
  },
  {
    value: "conlfictIcon",
    Component: ImageResource.getIconComponent("conlfictIcon")
  },
  {
    value: "redFlagsIcon",
    Component: ImageResource.getIconComponent("redFlagsIcon")
  },
  {
    value: "dueIcon",
    Component: ImageResource.getIconComponent("dueIcon")
  },
  {
    value: "expenseIcon",
    Component: ImageResource.getIconComponent("expenseIcon")
  },
  {
    value: "businessCourtesiesIcon",
    Component: ImageResource.getIconComponent("businessCourtesiesIcon")
  },
  {
    value: "paymentsIcon",
    Component: ImageResource.getIconComponent("paymentsIcon")
  },
  {
    value: "contactDivisionTPIIcon",
    Component: ImageResource.getIconComponent("contactDivisionTPIIcon")
  },
  {
    value: "contactDivisionTPIQuestionnaireIcon",
    Component: ImageResource.getIconComponent("contactDivisionTPIQuestionnaireIcon")
  },
  {
    value: "documentBusinessJustificationIcon",
    Component: ImageResource.getIconComponent("documentBusinessJustificationIcon")
  },
  {
    value: "legalServicesReviewIcon",
    Component: ImageResource.getIconComponent("legalServicesReviewIcon")
  },
  {
    value: "screeningThirdPartyIntermediariesIcon",
    Component: ImageResource.getIconComponent("screeningThirdPartyIntermediariesIcon")
  },
  {
    value: "thirdPartyIntermediariesIcon",
    Component: ImageResource.getIconComponent("thirdPartyIntermediariesIcon")
  },
  {
    value: "briberyBusinessCourtesiesIcon",
    Component: ImageResource.getIconComponent("briberyBusinessCourtesiesIcon")
  },
  {
    value: "confirmOfferLegalIcon",
    Component: ImageResource.getIconComponent("confirmOfferLegalIcon")
  },
  {
    value: "exerciseGoodJudgementIcon",
    Component: ImageResource.getIconComponent("exerciseGoodJudgementIcon")
  },
  {
    value: "preApprovalLegalServicesIcon",
    Component: ImageResource.getIconComponent("preApprovalLegalServicesIcon")
  },
  {
    value: "visaInvitationLetterIcon",
    Component: ImageResource.getIconComponent("visaInvitationLetterIcon")
  },
  {
    value: "createTheLetterIcon",
    Component: ImageResource.getIconComponent("createTheLetterIcon")
  },
  {
    value: "gatherInformationIcon",
    Component: ImageResource.getIconComponent("gatherInformationIcon")
  },
  {
    value: "getPreApprovalIcon",
    Component: ImageResource.getIconComponent("getPreApprovalIcon")
  },
  {
    value: "notifyDivisionComplianceManagerIcon",
    Component: ImageResource.getIconComponent("notifyDivisionComplianceManagerIcon")
  },
  {
    value: "prepareCoverLetterIcon",
    Component: ImageResource.getIconComponent("prepareCoverLetterIcon")
  },
  {
    value: "prepareIteneraryIcon",
    Component: ImageResource.getIconComponent("prepareIteneraryIcon")
  },
  {
    value: "localCharitiesIcon",
    Component: ImageResource.getIconComponent("localCharitiesIcon")
  },
  {
    value: "legalServicePreApprovalIcon",
    Component: ImageResource.getIconComponent("legalServicePreApprovalIcon")
  },
  {
    value: "salesAgentIcon",
    Component: ImageResource.getIconComponent("salesAgentIcon")
  },
  {
    value: "salesAgentRedFlagIcon",
    Component: ImageResource.getIconComponent("salesAgentRedFlagIcon")
  },
  {
    value: "stayAlertWarningSignsIcon",
    Component: ImageResource.getIconComponent("stayAlertWarningSignsIcon")
  },
  {
    value: "subDealersIcon",
    Component: ImageResource.getIconComponent("subDealersIcon")
  },
  {
    value: "subDealerSalesPriceIcon",
    Component: ImageResource.getIconComponent("subDealerSalesPriceIcon")
  },
  {
    value: "subDealerRedFlagIcon",
    Component: ImageResource.getIconComponent("subDealerRedFlagIcon")
  },
  {
    value: "subDealerHighMarginsIcon",
    Component: ImageResource.getIconComponent("subDealerHighMarginsIcon")
  },
  {
    value: "subDealerVagueRoleIcon",
    Component: ImageResource.getIconComponent("subDealerVagueRoleIcon")
  },
  {
    value: "breachResponseIcon",
    Component: ImageResource.getIconComponent("breachResponseIcon")
  },
  {
    value: "reportSecurityBreachIcon",
    Component: ImageResource.getIconComponent("reportSecurityBreachIcon")
  },
  {
    value: "informationSecurityReportIcon",
    Component: ImageResource.getIconComponent("informationSecurityReportIcon")
  },
  {
    value: "dataPrivacyModuleIcon",
    Component: ImageResource.getIconComponent("dataPrivacyModuleIcon")
  },
  {
    value: "personalInformationIcon",
    Component: ImageResource.getIconComponent("personalInformationIcon")
  },
  {
    value: "transparencyAndFairnessIcon",
    Component: ImageResource.getIconComponent("transparencyAndFairnessIcon")
  },
  {
    value: "limitedPurposeAndUseIcon",
    Component: ImageResource.getIconComponent("limitedPurposeAndUseIcon")
  },
  {
    value: "dataQualityIcon",
    Component: ImageResource.getIconComponent("dataQualityIcon")
  },
  {
    value: "securityAndConfidentialityIcon",
    Component: ImageResource.getIconComponent("securityAndConfidentialityIcon")
  },
  {
    value: "accessAndIndividualsRightsIcon",
    Component: ImageResource.getIconComponent("accessAndIndividualsRightsIcon")
  },
  {
    value: "transfersIcon",
    Component: ImageResource.getIconComponent("transfersIcon")
  },
  {
    value: "threeStepInformationIcon",
    Component: ImageResource.getIconComponent("threeStepInformationIcon")
  },
  {
    value: "confirmationPersonalInformationIcon",
    Component: ImageResource.getIconComponent("confirmationPersonalInformationIcon")
  },
  {
    value: "dataPrivacyPersonalInformationIcon",
    Component: ImageResource.getIconComponent("dataPrivacyPersonalInformationIcon")
  },
  {
    value: "websiteIcon",
    Component: ImageResource.getIconComponent("websiteIcon")
  },
  {
    value: "websitePrivacyIcon",
    Component: ImageResource.getIconComponent("websitePrivacyIcon")
  },
  {
    value: "providePrivacyStatementIcon",
    Component: ImageResource.getIconComponent("providePrivacyStatementIcon")
  },
  {
    value: "websiteInformationIcon",
    Component: ImageResource.getIconComponent("websiteInformationIcon")
  },
  {
    value: "dataTransfewProcessedFairlyIcon",
    Component: ImageResource.getIconComponent("dataTransfewProcessedFairlyIcon")
  },
  {
    value: "dataTransferPrivacyIcon",
    Component: ImageResource.getIconComponent("dataTransferPrivacyIcon")
  },
  {
    value: "thirdPartiesIcon",
    Component: ImageResource.getIconComponent("thirdPartiesIcon")
  },
  {
    value: "tradeShowsIcon",
    Component: ImageResource.getIconComponent("tradeShowsIcon")
  },
  {
    value: "competitorsRaisePricesIcon",
    Component: ImageResource.getIconComponent("competitorsRaisePricesIcon")
  },
  {
    value: "illegalPriceFixingIcon",
    Component: ImageResource.getIconComponent("illegalPriceFixingIcon")
  },
  {
    value: "accidentalBreachIcon",
    Component: ImageResource.getIconComponent("accidentalBreachIcon")
  },
  {
    value: "reportAnyBreachesIcon",
    Component: ImageResource.getIconComponent("reportAnyBreachesIcon")
  },
  {
    value: "fairCompIcon",
    Component: ImageResource.getIconComponent("fairCompIcon")
  },
  {
    value: "dealerPriceProductIcon",
    Component: ImageResource.getIconComponent("dealerPriceProductIcon")
  },
  {
    value: "doNotMakeAgreementsIcon",
    Component: ImageResource.getIconComponent("doNotMakeAgreementsIcon")
  },
  {
    value: "followWrittenAgendaIcon",
    Component: ImageResource.getIconComponent("followWrittenAgendaIcon")
  },
  {
    value: "getApprovalIcon",
    Component: ImageResource.getIconComponent("getApprovalIcon")
  },
  {
    value: "neverExchangeInformationIcon",
    Component: ImageResource.getIconComponent("neverExchangeInformationIcon")
  },
  {
    value: "stayAlertIcon",
    Component: ImageResource.getIconComponent("stayAlertIcon")
  },
  {
    value: "utilizeEffectiveCommunicationIcon",
    Component: ImageResource.getIconComponent("utilizeEffectiveCommunicationIcon")
  },
  {
    value: "competitorSupplierIcon",
    Component: ImageResource.getIconComponent("competitorSupplierIcon")
  },
  {
    value: "purchasingInformationIcon",
    Component: ImageResource.getIconComponent("purchasingInformationIcon")
  },
  {
    value: "competitiveInformationIcon",
    Component: ImageResource.getIconComponent("competitiveInformationIcon")
  },
  {
    value: "publicWebsiteInformationAvailableIcon",
    Component: ImageResource.getIconComponent("publicWebsiteInformationAvailableIcon")
  },
  {
    value: "dealersConsideredCompetitorsIcon",
    Component: ImageResource.getIconComponent("dealersConsideredCompetitorsIcon")
  },
  {
    value: "doNotExchangeSensitiveDataIcon",
    Component: ImageResource.getIconComponent("doNotExchangeSensitiveDataIcon")
  },
  {
    value: "engineersDiscussCostIcon",
    Component: ImageResource.getIconComponent("engineersDiscussCostIcon")
  },
  {
    value: "trainingWithCompetitorsIcon",
    Component: ImageResource.getIconComponent("trainingWithCompetitorsIcon")
  },
  {
    value: "tradeAssociationsIcon",
    Component: ImageResource.getIconComponent("tradeAssociationsIcon")
  },
  {
    value: "gatherInformationv2Icon",
    Component: ImageResource.getIconComponent("gatherInformationv2Icon")
  },
  {
    value: "statisticalExchangeProgramPermissionIcon",
    Component: ImageResource.getIconComponent("statisticalExchangeProgramPermissionIcon")
  },
  {
    value: "statisticalInformationExchangeIcon",
    Component: ImageResource.getIconComponent("statisticalInformationExchangeIcon")
  },
  {
    value: "exportControlsIcon",
    Component: ImageResource.getIconComponent("exportControlsIcon")
  },
  {
    value: "customsIcon",
    Component: ImageResource.getIconComponent("customsIcon")
  },
  {
    value: "declareItemsicon",
    Component: ImageResource.getIconComponent("declareItemsicon")
  },
  {
    value: "antiBoycottComplianceIcon",
    Component: ImageResource.getIconComponent("antiBoycottComplianceIcon")
  },
  {
    value: "generic2Icon",
    Component: ImageResource.getIconComponent("generic2Icon")
  },
  {
    value: "generic7Icon",
    Component: ImageResource.getIconComponent("generic7Icon")
  },
  {
    value: "tradeComplianceRedFlagsIcon",
    Component: ImageResource.getIconComponent("tradeComplianceRedFlagsIcon")
  },
  {
    value: "consultManagerBeforeSaleIcon",
    Component: ImageResource.getIconComponent("consultManagerBeforeSaleIcon")
  },
  {
    value: "diversionIcon",
    Component: ImageResource.getIconComponent("diversionIcon")
  },
  {
    value: "salesRepApprovalofDiscountIcon",
    Component: ImageResource.getIconComponent("salesRepApprovalofDiscountIcon")
  },
  {
    value: "licensingIcon",
    Component: ImageResource.getIconComponent("licensingIcon")
  },
  {
    value: "handCarryIcon",
    Component: ImageResource.getIconComponent("handCarryIcon")
  },
  {
    value: "restrictedScreeningIcon",
    Component: ImageResource.getIconComponent("restrictedScreeningIcon")
  },
  {
    value: "toursIcon",
    Component: ImageResource.getIconComponent("toursIcon")
  },
  {
    value: "scen5Scr6_ConsultIcon",
    Component: ImageResource.getIconComponent("scen5Scr6_ConsultIcon")
  },
  {
    value: "scen5Scr5_FinancialIcon",
    Component: ImageResource.getIconComponent("scen5Scr5_FinancialIcon")
  },
  {
    value: "scen5Scr4_DescisionsIcon",
    Component: ImageResource.getIconComponent("scen5Scr4_DescisionsIcon")
  },
  {
    value: "scen5Scr3_FriendIcon",
    Component: ImageResource.getIconComponent("scen5Scr3_FriendIcon")
  },
  {
    value: "scen5Scr2_MeritIcon",
    Component: ImageResource.getIconComponent("scen5Scr2_MeritIcon")
  },
  {
    value: "scen4Scr5Icon",
    Component: ImageResource.getIconComponent("scen4Scr5Icon")
  },
  {
    value: "scen5Scr1_AdviceIcon",
    Component: ImageResource.getIconComponent("scen5Scr1_AdviceIcon")
  },
  {
    value: "scen4Scr4Icon",
    Component: ImageResource.getIconComponent("scen4Scr4Icon")
  },
  {
    value: "scen4Scr3Icon",
    Component: ImageResource.getIconComponent("scen4Scr3Icon")
  },
  {
    value: "scen4Scr2Icon",
    Component: ImageResource.getIconComponent("scen4Scr2Icon")
  },
  {
    value: "scen4Scr1_SupplierIcon",
    Component: ImageResource.getIconComponent("scen4Scr1_SupplierIcon")
  },
  {
    value: "scen3Scr3_InfluenceIcon",
    Component: ImageResource.getIconComponent("scen3Scr3_InfluenceIcon")
  },
  {
    value: "scen3Scr2_OkayIcon",
    Component: ImageResource.getIconComponent("scen3Scr2_OkayIcon")
  },
  {
    value: "scen3Scr1_AdviceIcon",
    Component: ImageResource.getIconComponent("scen3Scr1_AdviceIcon")
  },
  {
    value: "scen2Scr5_FancyIcon",
    Component: ImageResource.getIconComponent("scen2Scr5_FancyIcon")
  },
  {
    value: "scen2Scr4_AppearanceIcon",
    Component: ImageResource.getIconComponent("scen2Scr4_AppearanceIcon")
  },
  {
    value: "scen2Scr3_ValuesIcon",
    Component: ImageResource.getIconComponent("scen2Scr3_ValuesIcon")
  },
  {
    value: "scen2Scr2_ReasonableIcon",
    Component: ImageResource.getIconComponent("scen2Scr2_ReasonableIcon")
  },
  {
    value: "scen2Scr1_LunchIcon",
    Component: ImageResource.getIconComponent("scen2Scr1_LunchIcon")
  },
  {
    value: "scen1Scr5_SeeSayIcon",
    Component: ImageResource.getIconComponent("scen1Scr5_SeeSayIcon")
  },
  {
    value: "scen1Scr4_ExcuseIcon",
    Component: ImageResource.getIconComponent("scen1Scr4_ExcuseIcon")
  },
  {
    value: "scen1Scr3_RefuseIcon",
    Component: ImageResource.getIconComponent("scen1Scr3_RefuseIcon")
  },
  {
    value: "scen1Scr2_BusinessIcon",
    Component: ImageResource.getIconComponent("scen1Scr2_BusinessIcon")
  },
  {
    value: "scen1Scr1_FriendIcon",
    Component: ImageResource.getIconComponent("scen1Scr1_FriendIcon")
  },
  {
    value: "mod5Scr6_SeeSayIcon",
    Component: ImageResource.getIconComponent("mod5Scr6_SeeSayIcon")
  },
  {
    value: "mod5Scr5_NoAcceptIcon",
    Component: ImageResource.getIconComponent("mod5Scr5_NoAcceptIcon")
  },
  {
    value: "mod5Scr4_NegativeIcon",
    Component: ImageResource.getIconComponent("mod5Scr4_NegativeIcon")
  },
  {
    value: "mod5Scr2_QuestionIcon",
    Component: ImageResource.getIconComponent("mod5Scr2_QuestionIcon")
  },
  {
    value: "mod5Scr3_CommonIcon",
    Component: ImageResource.getIconComponent("mod5Scr3_CommonIcon")
  },
  {
    value: "mod5Scr1_GiftIcon",
    Component: ImageResource.getIconComponent("mod5Scr1_GiftIcon")
  },
  {
    value: "mod4Scr8_SeeSayIcon",
    Component: ImageResource.getIconComponent("mod4Scr8_SeeSayIcon")
  },
  {
    value: "mod4Scr7_ContinueIcon",
    Component: ImageResource.getIconComponent("mod4Scr7_ContinueIcon")
  },
  {
    value: "mod4Scr6_ConsultIcon",
    Component: ImageResource.getIconComponent("mod4Scr6_ConsultIcon")
  },
  {
    value: "mod4Scr5_SuppliersIcon",
    Component: ImageResource.getIconComponent("mod4Scr5_SuppliersIcon")
  },
  {
    value: "mod2Scr8_InterestIcon",
    Component: ImageResource.getIconComponent("mod2Scr8_InterestIcon")
  },
  {
    value: "mod2Scr9_Interest2Icon",
    Component: ImageResource.getIconComponent("mod2Scr9_Interest2Icon")
  },
  {
    value: "mod2Scr10_GainIcon",
    Component: ImageResource.getIconComponent("mod2Scr10_GainIcon")
  },
  {
    value: "mod2Scr11_RomanceIcon",
    Component: ImageResource.getIconComponent("mod2Scr11_RomanceIcon")
  },
  {
    value: "mod2Scr12_SeeSayIcon",
    Component: ImageResource.getIconComponent("mod2Scr12_SeeSayIcon")
  },
  {
    value: "mod3Scr1_NepotismIcon",
    Component: ImageResource.getIconComponent("mod3Scr1_NepotismIcon")
  },
  {
    value: "mod3Scr2_AllLevelsIcon",
    Component: ImageResource.getIconComponent("mod3Scr2_AllLevelsIcon")
  },
  {
    value: "mod3Scr3_MeritIcon",
    Component: ImageResource.getIconComponent("mod3Scr3_MeritIcon")
  },
  {
    value: "mod3Scr4_LeaderIcon",
    Component: ImageResource.getIconComponent("mod3Scr4_LeaderIcon")
  },
  {
    value: "mod3Scr5_RequestIcon",
    Component: ImageResource.getIconComponent("mod3Scr5_RequestIcon")
  },
  {
    value: "mod4Scr4_CompeteIcon",
    Component: ImageResource.getIconComponent("mod4Scr4_CompeteIcon")
  },
  {
    value: "mod4Scr3_NonProfitIcon",
    Component: ImageResource.getIconComponent("mod4Scr3_NonProfitIcon")
  },
  {
    value: "mod4Scr2_IndustryIcon",
    Component: ImageResource.getIconComponent("mod4Scr2_IndustryIcon")
  },
  {
    value: "mod4Scr1_TwoJobsIcon",
    Component: ImageResource.getIconComponent("mod4Scr1_TwoJobsIcon")
  },
  {
    value: "mod3Scr6_SeeSayIcon",
    Component: ImageResource.getIconComponent("mod3Scr6_SeeSayIcon")
  },
  {
    value: "mod2Scr7_HireIcon",
    Component: ImageResource.getIconComponent("mod2Scr7_HireIcon")
  },
  {
    value: "mod2Scr6_TwoJobsIcon",
    Component: ImageResource.getIconComponent("mod2Scr6_TwoJobsIcon")
  },
  {
    value: "mod2Scr5_GiftsIcon",
    Component: ImageResource.getIconComponent("mod2Scr5_GiftsIcon")
  },
  {
    value: "mod2Scr4_ApprovalsIcon",
    Component: ImageResource.getIconComponent("mod2Scr4_ApprovalsIcon")
  },
  {
    value: "mod2Scr3_PurchaseIcon",
    Component: ImageResource.getIconComponent("mod2Scr3_PurchaseIcon")
  },
  {
    value: "mod2Scr2_MoneyIcon",
    Component: ImageResource.getIconComponent("mod2Scr2_MoneyIcon")
  },
  {
    value: "mod2Scr1_ConflictIcon",
    Component: ImageResource.getIconComponent("mod2Scr1_ConflictIcon")
  },
  {
    value: "mod1Scr4_SeeSayIcon",
    Component: ImageResource.getIconComponent("mod1Scr4_SeeSayIcon")
  },
  {
    value: "mod1Scr3_AvoidConflictIcon",
    Component: ImageResource.getIconComponent("mod1Scr3_AvoidConflictIcon")
  },
  {
    value: "mod1Scr2_LoyaltyIcon",
    Component: ImageResource.getIconComponent("mod1Scr2_LoyaltyIcon")
  },
  {
    value: "mod1Scr1_ConflictofInterestIcon",
    Component: ImageResource.getIconComponent("mod1Scr1_ConflictofInterestIcon")
  },
  {
    value: "scen4Scr3_TippingIcon",
    Component: ImageResource.getIconComponent("scen4Scr3_TippingIcon")
  },
  {
    value: "scen4Scr2_SharingIcon",
    Component: ImageResource.getIconComponent("scen4Scr2_SharingIcon")
  },
  {
    value: "scen4Scr1_FSIIcon",
    Component: ImageResource.getIconComponent("scen4Scr1_FSIIcon")
  },
  {
    value: "scen3Scr4_MNPIIcon",
    Component: ImageResource.getIconComponent("scen3Scr4_MNPIIcon")
  },
  {
    value: "scen3Scr3_TipIcon",
    Component: ImageResource.getIconComponent("scen3Scr3_TipIcon")
  },
  {
    value: "scen3Scr2_CallIcon",
    Component: ImageResource.getIconComponent("scen3Scr2_CallIcon")
  },
  {
    value: "scen3Scr1_FriendIcon",
    Component: ImageResource.getIconComponent("scen3Scr1_FriendIcon")
  },
  {
    value: "scen2Scr4_MNPIIcon",
    Component: ImageResource.getIconComponent("scen2Scr4_MNPIIcon")
  },
  {
    value: "scen2Scr3_AllowedIcon",
    Component: ImageResource.getIconComponent("scen2Scr3_AllowedIcon")
  },
  {
    value: "scen2Scr2_DiscussIcon",
    Component: ImageResource.getIconComponent("scen2Scr2_DiscussIcon")
  },
  {
    value: "scen2Scr1_SupplierIcon",
    Component: ImageResource.getIconComponent("scen2Scr1_SupplierIcon")
  },
  {
    value: "scen1Scr4_MNPIIcon",
    Component: ImageResource.getIconComponent("scen1Scr4_MNPIIcon")
  },
  {
    value: "scen1Scr3_BuyIcon",
    Component: ImageResource.getIconComponent("scen1Scr3_BuyIcon")
  }, 
  {
    value: "scen1Scr2_ResearchIcon",
    Component: ImageResource.getIconComponent("scen1Scr2_ResearchIcon")
  },
  {
    value: "scen1Scr1_OverhearIcon",
    Component: ImageResource.getIconComponent("scen1Scr1_OverhearIcon")
  },
  {
    value: "mod4Scr5_ContactIcon",
    Component: ImageResource.getIconComponent("mod4Scr5_ContactIcon")
  },
  {
    value: "mod4Scr4_ExceptionsIcon",
    Component: ImageResource.getIconComponent("mod4Scr4_ExceptionsIcon")
  },
  {
    value: "mod4Scr3_NoTradeIcon",
    Component: ImageResource.getIconComponent("mod4Scr3_NoTradeIcon")
  },
  {
    value: "mod4Scr2_OpenIcon",
    Component: ImageResource.getIconComponent("mod4Scr2_OpenIcon")
  },
  {
    value: "mod4Scr1_InsidersIcon",
    Component: ImageResource.getIconComponent("mod4Scr1_InsidersIcon")
  },
  {
    value: "mod3Scr10_ContactIcon",
    Component: ImageResource.getIconComponent("mod3Scr10_ContactIcon")
  },
  {
    value: "mod3Scr9_PolicyIcon",
    Component: ImageResource.getIconComponent("mod3Scr9_PolicyIcon")
  },
  {
    value: "mod3Scr8_ProhibitsIcon",
    Component: ImageResource.getIconComponent("mod3Scr8_ProhibitsIcon")
  },
  {
    value: "mod3Scr7_PolicyIcon",
    Component: ImageResource.getIconComponent("mod3Scr7_PolicyIcon")
  },
  {
    value: "mod3Scr6_SecuritiesIcon",
    Component: ImageResource.getIconComponent("mod3Scr6_SecuritiesIcon")
  },
  {
    value: "mod2Scr5_PublicIcon",
    Component: ImageResource.getIconComponent("mod2Scr5_PublicIcon")
  },
  {
    value: "mod3Scr5_TrustIcon",
    Component: ImageResource.getIconComponent("mod3Scr5_TrustIcon")
  },
  {
    value: "mod3Scr4_ExternalIcon",
    Component: ImageResource.getIconComponent("mod3Scr4_ExternalIcon")
  },
  {
    value: "mod3Scr3_FamilyIcon",
    Component: ImageResource.getIconComponent("mod3Scr3_FamilyIcon")
  },
  {
    value: "mod3Scr2_PeopleIcon",
    Component: ImageResource.getIconComponent("mod3Scr2_PeopleIcon")
  },
  {
    value: "mod3Scr1_PolicyIcon",
    Component: ImageResource.getIconComponent("mod3Scr1_PolicyIcon")
  },
  {
    value: "mod2Scr7_MNPIIcon",
    Component: ImageResource.getIconComponent("mod2Scr7_MNPIIcon")
  },
  {
    value: "mod2Scr6C_FilingsIcon",
    Component: ImageResource.getIconComponent("mod2Scr6C_FilingsIcon")
  },
  {
    value: "mod2Scr6B_AssesIcon",
    Component: ImageResource.getIconComponent("mod2Scr6B_AssesIcon")
  },
  {
    value: "mod2Scr6A_PressReleaseIcon",
    Component: ImageResource.getIconComponent("mod2Scr6A_PressReleaseIcon")
  },
  {
    value: "mod2Scr4_IncreaseIcon",
    Component: ImageResource.getIconComponent("mod2Scr4_IncreaseIcon")
  },
  {
    value: "mod2Scr3_MaterialIcon",
    Component: ImageResource.getIconComponent("mod2Scr3_MaterialIcon")
  },
  {
    value: "mod2Scr2_CircumstanceIcon",
    Component: ImageResource.getIconComponent("mod2Scr2_CircumstanceIcon")
  },
  {
    value: "mod2Scr1_MNPIIcon",
    Component: ImageResource.getIconComponent("mod2Scr1_MNPIIcon")
  },
  {
    value: "mod1Scr5_ContactIcon",
    Component: ImageResource.getIconComponent("mod1Scr5_ContactIcon")
  },
  {
    value: "mod1Scr4_PolicyIcon",
    Component: ImageResource.getIconComponent("mod1Scr4_PolicyIcon")
  },
  {
    value: "mod1Scr3_LegalIcon",
    Component: ImageResource.getIconComponent("mod1Scr3_LegalIcon")
  },
  {
    value: "mod1Scr2_ProhibitedIcon",
    Component: ImageResource.getIconComponent("mod1Scr2_ProhibitedIcon")
  },
  {
    value: "mod1Scr1_InsiderIcon",
    Component: ImageResource.getIconComponent("mod1Scr1_InsiderIcon")
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 20,
    width: "80%",
    flexGrow: 1
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    listStyle: "none"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    wordWrap: true
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  button: {
    position: "absolute",
    right: theme.spacing(6),
    top: theme.spacing(0)
  },
  close: {
    padding: theme.spacing(0.5)
  },
  indexer: {
    zIndex: "9999 !important"
  }
}));
const { red } = colors;

const toolbarOptions = {
  options: ["inline"],
  inline: {
    options: ["bold", "italic"]
  }
};

const stateToHTMLOptions = {
  inlineStyles: {
    ITALIC: {
      element: "i"
    }
  }
};

const boxStyle= "2px solid rgba(34,36,38,.15)";

const SortableList = ({ getListItems, setListItems }) => {
  const classes = useStyles();

  const [count, setCount] = useState(20);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeSectionID, setActiveSectionID] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSectionIndex, setDeleteSectionIndex] = useState(null);
  const [activeSection, setActiveSection] = useState(
    new SectionDAO().toObject({ title: "", text: "" })
  );
  const [activeSectionTitleState, setActiveSectionTitleState] = useState(EditorState.createEmpty());
  const [activeSectionTextState, setActiveSectionTextState] = useState(EditorState.createEmpty());

  const [showSnack, setShowSnack] = useState(false);

  const listItems = getListItems().map((value, index) => {
    return {
      id: index,
      value
    };
  });

  const handleClickOpen = () => {
    setShowAddModal(true);
  };

  const handleClose = () => {
    setActiveSection(new SectionDAO().toObject({ title: "", text: "" }));
    if (showAddModal) setShowAddModal(false);
    if (showEditModal) setShowEditModal(false);
    if (showDeleteModal) setShowDeleteModal(false);
  };

  const handleAddItem = () => {
    const activeSectionClone = new SectionDAO().toObject(activeSection);
    activeSectionClone.setTitle(decodeHTMLText(activeSectionClone.getTitle()));
    activeSectionClone.setText(decodeHTMLText(activeSectionClone.getText()));
    setCount(count + 1);
    const groupedListItems = [
      ...listItems,
      { id: count, value: activeSectionClone }
    ].map(({ value }, index) => ({ ...value, order: index }));
    setListItems(groupedListItems);
    setShowAddModal(false);
  };
  const handleOpenEdit = current => {
    setActiveSectionID(current.id);
    setActiveSection(current.value);
    const activeTitle = current.value.getTitle();
    const activeText = current.value.getText();
    if (activeTitle) {
      const titleContentBlock = convertFromHTML(encodeHTMLText(activeTitle));
      const contentState = ContentState.createFromBlockArray(
        titleContentBlock.contentBlocks,
        titleContentBlock.entityMap
      );
      const titleEditorState = EditorState.createWithContent(contentState);
      setActiveSectionTitleState(titleEditorState);
    }
    if (activeText) {
      const textContentBlock = convertFromHTML(encodeHTMLText(activeText));
      const contentState = ContentState.createFromBlockArray(
        textContentBlock.contentBlocks,
        textContentBlock.entityMap
      );
      const textEditorState = EditorState.createWithContent(contentState);
      setActiveSectionTextState(textEditorState);
    }
    setShowEditModal(true);
  };

  const handleEditChange = () => {
    const activeSectionClone = new SectionDAO().toObject(activeSection);
    activeSectionClone.setTitle(decodeHTMLText(activeSectionClone.getTitle()));
    activeSectionClone.setText(decodeHTMLText(activeSectionClone.getText()));
    const index = listItems.findIndex(i => i.id === activeSectionID);
    listItems[index].value = activeSectionClone;
    const groupedListItems = [...listItems].map(({ value }) => value);
    setListItems(groupedListItems);
    setShowEditModal(false);
  };

  const handleDelete = () => {
    const groupedListItems = listItems
      .filter(current => current.id !== deleteSectionIndex)
      .map(({ value }, index) => ({ ...value, order: index }));
    setListItems(groupedListItems);
    setShowSnack(true);
    setDeleteSectionIndex(null);
    handleClose();
  };

  const handleOpenDelete = index => {
    setDeleteSectionIndex(index);
    setShowDeleteModal(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnack(false);
  };

  const updateActiveSection = () => {
    const activeSectionClone = new SectionDAO().toObject(activeSection);
    setActiveSection(activeSectionClone);
  };

  const SortableList = SortableElement(({ value }) => {
    const section = value.value;
    return (
      <ListItem button onClick={() => handleOpenEdit(value)}>
        <ListItemText
          primaryTypographyProps={{ variant: "caption" }}
          primary={section.getTitle()}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={() => handleOpenDelete(value.id)}>
            <DeleteIcon style={{ color: red[800] }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

  const SortableListContainer = SortableContainer(({ children }) => {
    return <List>{children}</List>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setListItems(
      arrayMove(listItems, oldIndex, newIndex).map(({ value }, index) => {
        value.setOrder(index);
        return value;
      })
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <div className={classes.demo}>
            <SortableListContainer
              distance={1}
              axis="y"
              onSortEnd={onSortEnd}
              className={classes.demo}
            >
              {listItems.map((current, i) => {
                return (
                  <SortableList
                    key={`item-${current.value.title}`}
                    collection="sections"
                    index={i}
                    value={current}
                    className={classes.indexer}
                  />
                );
              })}
            </SortableListContainer>
            <ListItem>
              <ListItemText primary="" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="addModuleSection"
                  onClick={() => handleClickOpen()}
                >
                  <AddIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        </Grid>
      </Grid>
      <Dialog fullWidth={true} open={showAddModal} aria-labelledby="add-dialog-title">
        <DialogTitle id="add-dialog-title">Add Section</DialogTitle>
        <DialogContent>
          <TextField
            key="activeSectionIconKey"
            id="activeSectionIconKeyID"
            label="IconKey"
            className={classes.textField}
            value={activeSection.getIconKey()}
            select
            onChange={({ target }) => {
              activeSection.setIconKey(target.value);
              updateActiveSection();
            }}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          >
            {IconList.map(icon => {
              const { value, Component } = icon;
              return (
                <MenuItem key={value} value={value}>
                  <Component
                    width={50}
                    height={50}
                    style={{ backgroundColor: "#373A3D" }}
                  ></Component>
                </MenuItem>
              );
            })}
          </TextField>
          <Typography style={{ marginTop: 10 }} color="default">
            Title
          </Typography>
          {/*<TextField
            key="activeSectionTitle"
            id="activeSectionTitleID"
            label="Title"
            className={classes.textField}
            value={decodeHTMLText(activeSection.getTitle())}
            InputProps={{ readOnly: true, disableUnderline: true }}
            InputLabelProps={{ disableAnimation: true, focused: false }}
            margin="normal"
          ></TextField>*/}
          <div style={{ display: "flex", border: boxStyle }}>
            <Editor
              initialEditorState={(() => {
                return EditorState.createEmpty();
              })()}
              editorStyle={{ display: "flex", width: "100%", height: "inherit", maxHeight: 200 }}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={editorState => {
                const htmlData = stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions);
                activeSection.setTitle(htmlData);
                updateActiveSection();
              }}
              toolbar={toolbarOptions}
              toolbarStyle={{ justifyContent: "center", width: 100 }}
            />
          </div>

          <Typography style={{ marginTop: 10 }} color="default">
            Text
          </Typography>
          {/*<TextField
            key="activeSectionText"
            id="activeSectionTextID"
            label="Text"
            multiline
            className={classes.textField}
            value={decodeHTMLText(activeSection.getText())}
            InputProps={{ readOnly: true, disableUnderline: true }}
            InputLabelProps={{ disableAnimation: true, focused: false }}
            margin="normal"
          ></TextField>*/}
          <div style={{ display: "flex", border: boxStyle }}>
            <Editor
              initialEditorState={(() => {
                return EditorState.createEmpty();
              })()}
              editorStyle={{ display: "flex", width: "100%", height: "inherit", maxHeight: 200 }}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={editorState => {
                const htmlData = stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions);
                activeSection.setText(htmlData);
                updateActiveSection();
              }}
              toolbar={toolbarOptions}
              toolbarStyle={{ justifyContent: "center", width: 100 }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={handleAddItem}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} open={showEditModal} aria-labelledby="add-dialog-title">
        <DialogTitle id="add-dialog-title">Edit Section</DialogTitle>
        <DialogContent>
          {" "}
          <TextField
            key="activeSectionIconKey"
            id="activeSectionIconKeyID"
            label="IconKey"
            select
            className={classes.textField}
            defaultValue={activeSection.getIconKey()}
            onChange={({ target }) => {
              activeSection.setIconKey(target.value);
              updateActiveSection();
            }}
            margin="normal"
          >
            {IconList.map(icon => {
              const { value, Component } = icon;
              return (
                <MenuItem key={value} value={value}>
                  <Component
                    width={50}
                    height={50}
                    style={{ backgroundColor: "#373A3D" }}
                  ></Component>
                </MenuItem>
              );
            })}
          </TextField>
          <Typography style={{ marginTop: 10 }} color="default">
            Title
          </Typography>
          {/*<TextField
            key="activeSectionTitle"
            id="activeSectionTitleID"
            label="Title"
            className={classes.textField}
            value={decodeHTMLText(activeSection.getTitle())}
            InputProps={{ readOnly: true, disableUnderline: true }}
            InputLabelProps={{ disableAnimation: true, focused: false }}
            margin="normal"
          ></TextField>*/}
          <div style={{ display: "flex", border: boxStyle }}>
            <Editor
              editorState={activeSectionTitleState}
              editorStyle={{ display: "flex", width: "100%", height: "inherit", maxHeight: 200 }}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={editorState => {
                setActiveSectionTitleState(editorState);
                const htmlData = stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions);
                activeSection.setTitle(decodeHTMLText(htmlData));
                updateActiveSection();
              }}
              toolbar={toolbarOptions}
              toolbarStyle={{ justifyContent: "center", width: 100 }}
            />
          </div>
          <Typography style={{ marginTop: 10 }} color="default">
            Text
          </Typography>
          {/*<TextField
            key="activeSectionText"
            id="activeSectionTextID"
            label="Text"
            multiline
            className={classes.textField}
            //value={decodeHTMLText(activeSection.getText())}
            InputProps={{ readOnly: true, disableUnderline: true }}
            InputLabelProps={{ disableAnimation: true, focused: false }}
            margin="normal"
          ></TextField>*/}
          <div style={{ display: "flex", border: boxStyle }}>
            <Editor
              editorState={activeSectionTextState}
              editorStyle={{ display: "flex", width: "100%", height: "inherit", maxHeight: 200 }}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={editorState => {
                setActiveSectionTextState(editorState);
                const htmlData = stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions);
                activeSection.setText(decodeHTMLText(htmlData));
                updateActiveSection();
              }}
              toolbar={toolbarOptions}
              toolbarStyle={{ justifyContent: "center", width: 100 }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={handleEditChange}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showDeleteModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="del-dialog-title">Delete this item?</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete}>Submit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={showSnack}
        autoHideDuration={3500}
        onClose={handleSnackClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Item Deleted</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="primary"
            className={classes.close}
            onClick={handleSnackClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
};

export default SortableList;
