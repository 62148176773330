import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const errorNotify = error => {
  error = error?.response?.data?.MSG || "Something went wrong. Please try later";
  // User have to close it
  toast.error(error, {
    // Set to 15sec
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 6000
  });
};

export default errorNotify;
