// @flow
import ContentDAO from "../models/content/ContentDAO";
import apiService from "./apiService";
import * as WTRWEndpoints from "./wtrw.api.js";

class WorkService {
  static WorkDTO(contents: Object[]) {
    return contents.map<ContentDAO>(content => new ContentDAO().toObject(content));
  }

  static async getWorkItems(languageCode) {
    const response = await apiService.get(`${WTRWEndpoints.DO_DONOT_ENDPOINT}&languageCode=${languageCode}`);
    const { data } = response;
    return this.WorkDTO(data);
  }

  static async updateWorkItems(content: Object[]) {
    const response = await apiService.post(WTRWEndpoints.DO_DONOT_ENDPOINT, content);
    return response;
  }

  static async deleteWorkItem({
    riskArea,
    contentType,
    identifier,
    languageCode
  }: EndpointSuffixInput) {
    const endpointSuffix = `/${riskArea}/${contentType}/${identifier}/${languageCode}`;
    const response = apiService.delete(WTRWEndpoints.RISK_AREA_ENDPOINT + endpointSuffix);
    return response;
  }

  static getLearningModules() {
    return apiService.get(WTRWEndpoints.LEARNING_MODULES_ENDPOINT).then(response => response);
  }
}

export default WorkService;
